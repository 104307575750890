import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IAddressesRequest, IAddressesResponse } from 'types/addresses.interface';
import { IApartmentAccount, IApartmentAccountUpdate } from 'types/apartment-account.interface';
import { API_URL } from 'utils/enums';

class AddressesService {
	static getAllAddresses = async (reqBody: IAddressesRequest): Promise<AxiosResponse<IAddressesResponse>> =>
		API.post(API_URL.APARTMENT_LIST_GET, reqBody);

	static getAddressById = async (id: string): Promise<AxiosResponse<IApartmentAccount>> =>
		API.post(API_URL.ADDRESS_GET_BY_ID, { id: +id });

	static updateAddressById = async (reqBody: IApartmentAccountUpdate): Promise<AxiosResponse<IApartmentAccount>> =>
		API.patch(API_URL.ADDRESS_UPDATE_BY_ID, reqBody);

	static getHouses = async ({ street }: { street: string }): Promise<AxiosResponse<string[]>> =>
		API.post(API_URL.APARTMENT_ACCOUNT_HOUSES_GET, { street });

	static getFlats = async ({ street, house }: { street: string; house: string }): Promise<AxiosResponse<string[]>> =>
		API.post(API_URL.APARTMENT_ACCOUNT_FLATS_GET, { street, house });

	static getStreetsWithType = async ({ street }: { street: string }): Promise<AxiosResponse<string[]>> =>
		API.post(API_URL.APARTMENT_ACCOUNT_STREETS_GET, { street });
}

export { AddressesService };
