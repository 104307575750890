import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ISingleReceiptsDetails, ISingleReceiptsRequest, ISingleReceiptsResponse } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { SingleReceiptsService } from 'utils/services';

export const getSingleReceiptsList = createAsyncThunk(
	'singleReceiptsSlice/getSingleReceiptsList',
	async (reqBody: ISingleReceiptsRequest, { rejectWithValue }) => {
		try {
			const singleReceiptsResponse: AxiosResponse<ISingleReceiptsResponse> =
				await SingleReceiptsService.getSingleReceiptsList(reqBody);
			return singleReceiptsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getSingleReceiptById = createAsyncThunk(
	'singleReceiptsSlice/getSingleReceiptById',
	async (id: number, { rejectWithValue }) => {
		try {
			const singleReceiptResponse: AxiosResponse<ISingleReceiptsDetails> =
				await SingleReceiptsService.getSingleReceiptById(id);
			return singleReceiptResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
