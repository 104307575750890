import { FC, memo, useMemo } from 'react';
import { useModal } from 'utils/hooks';
import { ManageStreetModal } from '../ManageStreetModal';
import { EditIconButton } from 'components/elements';
import { IStreetsDetails } from 'types';
import toastService from 'components/elements/Toastify';
import { useAppDispatch } from 'store/hooks';
import { updateStreets } from 'store/streets';

interface UpdateStreetAnchorProps {
	streetRow: IStreetsDetails;
}

export const UpdateStreetAnchor: FC<UpdateStreetAnchorProps> = memo(({ streetRow }) => {
	const [isOpen, handleOpen, handleClose] = useModal();

	const dispatch = useAppDispatch();

	const initialValues = useMemo(
		() => ({
			name: streetRow.name,
			streetTypeId: streetRow.streetTypeId,
			cityId: streetRow.cityId,
		}),
		[streetRow]
	);

	const handleUpdateModal = async (values: any) => {
		try {
			await dispatch(updateStreets({ id: streetRow.id, ...values, deviceFP: '6037e65eefbdasdfadsfasdf3' })).unwrap();
			handleClose();
		} catch (error) {
			toastService.error('При зміні даних вулиці щось пішло не так');
		}
	};

	return (
		<>
			<EditIconButton onClick={handleOpen} />
			<ManageStreetModal
				title="Редагувати вулицю"
				isOpen={isOpen}
				onClose={handleClose}
				initialValues={initialValues}
				onSubmit={handleUpdateModal}
				buttonText="Змінити"
			/>
		</>
	);
});
