import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { getRouteTitle } from 'utils/helpers/get-page-title';
import { usePageTitle } from 'utils/hooks';

const PageTitleLayout: FC<PropsWithChildren> = ({ children }) => {
	const { pathname } = useLocation();

	usePageTitle(getRouteTitle(pathname));

	return <>{children}</>;
};

export default PageTitleLayout;
