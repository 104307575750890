import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import s from './styles.module.scss';

interface ConfirmationModalProps {
	open: boolean;
	modalIcon?: ReactNode;
	title: string;
	message?: string;
	onClose?: () => void;
}

const ConfirmationModal: FC<PropsWithChildren<ConfirmationModalProps>> = ({
	open,
	onClose,
	children,
	modalIcon,
	title,
	message,
}) => {
	return (
		<Modal open={open} disableAutoFocus onClose={onClose}>
			<Box className={s.content}>
				{modalIcon}
				<Typography variant="h5" color="text.primary">
					{title}
				</Typography>
				{message && (
					<Typography variant="body1" color="common.textLightGray" maxWidth="328px">
						{message}
					</Typography>
				)}
				{children}
			</Box>
		</Modal>
	);
};
export default ConfirmationModal;
