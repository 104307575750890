export const STREETS_HEADER_TITLES = {
	ID: 'ID',
	STREET: 'Вулиця',
	TYPE_STREET: 'Тип вулиці',
	TYPE_STREET_SHORT: 'Тип вулиці скорочено',
	CITY: 'Місто',
	CREATED_AT: 'Створено',
	UPDATED_AT: 'Оновлено',
};

export const ALIASES_BY_PROVIDER_HEADER_TITLES = {
	ID: 'ID',
	STREET: 'Вулиця',
	STREET_ID: 'ID Вулиці',
	PROVIDER: 'Провайдер',
	TYPE_STREET: 'Тип вулиці',
	TYPE_STREET_SHORT: 'Тип вулиці скорочено',
	CITY: 'Місто',
	STREET_CE: 'Вулиця (КП)',
	TYPE_STREET_CE: 'Тип вулиці (КП)',
	CITY_CE: 'Місто (КП)',
	CREATED_AT: 'Створено',
	UPDATED_AT: 'Оновлено',
};
