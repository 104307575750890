import { createContext } from 'react';
import { REPORT_STATUS } from 'utils/enums';

export interface ISocketContextProps {
	isConnected: boolean;
	generate: REPORT_STATUS | string;
}

const SocketContext = createContext<ISocketContextProps>({
	isConnected: false,
	generate: '',
});
export default SocketContext;
