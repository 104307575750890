// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddressDetailsPage_wrapper__is7r8 {
  padding: 8px;
  background-color: var(--palette-primary-light);
  border-radius: 40px;
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/pages/AddressDetailsPage/index.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,8CAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AACD","sourcesContent":[".wrapper {\n\tpadding: 8px;\n\tbackground-color: var(--palette-primary-light);\n\tborder-radius: 40px;\n\twidth: fit-content;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AddressDetailsPage_wrapper__is7r8`
};
export default ___CSS_LOADER_EXPORT___;
