import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { SetPasswordForm } from './SetPasswordForm';
import styles from './index.module.scss';

export const SetPasswordPage: FC = () => {
	return (
		<div className={styles.layout}>
			<Box className={styles.card}>
				<Stack className={styles.content}>
					<SetPasswordForm />
				</Stack>
			</Box>
		</div>
	);
};
