import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	clearUsersActivityData,
	getUserActivityList,
	selectUserActivityRows,
	selectUserActivityRowsTotal,
} from 'store/user-activity';

import { GetUserActivityListDto, IUserActivityFilters } from 'types';

import { PAGINATION_LIMIT } from 'utils/constants';
import { SORT_DIR, USER_ACTIVITY_FIELD_NAME } from 'utils/enums';
import { formatQueryString, getErrorMessage, isDeepEqual, parseQueryString } from 'utils/helpers';

import { UserActivityFilters, UserActivityTable } from 'components/modules/UserActivity';
import toastService from 'components/elements/Toastify';
import s from './index.module.scss';
import { ROUTES } from 'routes';

const MAX_ALLOWED_DATE_RANGE_USER_ACTIVITY_IN_DAYS = 3;

export const UserActivityPage: FC = () => {
	const dispatch = useAppDispatch();

	const userActivityRowsTotal = useAppSelector(selectUserActivityRowsTotal);

	const userActivityRows = useAppSelector(selectUserActivityRows);

	const { id } = useParams();

	const { search } = useLocation();

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const query = useMemo(
		() => parseQueryString<Record<string, string | undefined>>(search, { parseBooleans: true, parseNumbers: true }),
		[search]
	);

	const filters = useMemo(() => {
		const { gte = '', lte = '', methods = [] } = query;
		const gteDate = gte ? dayjs(gte) : dayjs().subtract(MAX_ALLOWED_DATE_RANGE_USER_ACTIVITY_IN_DAYS - 1, 'days');

		return {
			gte: gteDate.startOf('day'),
			lte: dayjs(lte || undefined).endOf('day'),
			methods: typeof methods === 'string' ? [methods] : ([...methods] as string[]),
		};
	}, [query]);

	const requestBody = useMemo(() => {
		if (!id) return null;

		const {
			page = 0,
			pageSize = PAGINATION_LIMIT.SMALL,
			field = USER_ACTIVITY_FIELD_NAME.DATE,
			sort = SORT_DIR.DSC,
		} = query;

		const { gte, lte, methods } = filters;

		return {
			offset: Number(page) * Number(pageSize),
			limit: Number(pageSize),
			order: {
				field,
				direction: sort as SORT_DIR,
			},
			options: {
				userId: id,
				dateRange: {
					gte: dayjs(gte).format(),
					lte: dayjs(lte).format(),
				},
				methods: methods.length ? methods : undefined,
			},
		};
	}, [query, id, filters]);

	const handleGetActivities = async (reqBody: GetUserActivityListDto) => {
		try {
			setIsLoading(true);
			await dispatch(getUserActivityList(reqBody)).unwrap();
		} catch (error) {
			toastService.error(getErrorMessage(error));
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (requestBody) {
			handleGetActivities(requestBody);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestBody]);

	useEffect(
		() => () => {
			dispatch(clearUsersActivityData());
		},
		[dispatch]
	);

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page: page || undefined,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortTable = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
				}),
			},
			{ replace: true }
		);
	};

	const handleChangeFilters = (values: IUserActivityFilters): void => {
		const isEqual = isDeepEqual(filters, values);

		if (isEqual) {
			if (requestBody) {
				handleGetActivities(requestBody);
			}
			return;
		}

		const { gte, lte, methods } = values;

		if (dayjs(lte).diff(gte, 'day') >= MAX_ALLOWED_DATE_RANGE_USER_ACTIVITY_IN_DAYS) {
			toastService.error(`Максимальний проміжок часу ${MAX_ALLOWED_DATE_RANGE_USER_ACTIVITY_IN_DAYS} дні`);
			return;
		}

		const newSearch = formatQueryString({
			...query,
			methods,
			gte: dayjs(gte).format(),
			lte: dayjs(lte).format(),
		});

		navigate({ search: newSearch }, { replace: true });
	};

	const handleResetFilters = () => {
		if (Object.keys(query).length) {
			navigate({ search: '' }, { replace: true });
		} else if (requestBody) {
			handleGetActivities(requestBody);
		}
	};

	return (
		<div className={s.wrapper}>
			<Typography color="var(--palette-common-textLightGray)" fontWeight="700" fontSize="16px" letterSpacing="0.15px">
				Журнал по HOSTCARD
			</Typography>
			<UserActivityFilters
				initialValues={filters}
				onSubmitFilters={handleChangeFilters}
				onResetFilters={handleResetFilters}
			/>
			<UserActivityTable
				pageRoute={ROUTES.usersDetailsActivity}
				rows={userActivityRows}
				total={userActivityRowsTotal}
				isLoading={isLoading}
				page={query.page ? Number(query?.page) : undefined}
				limit={query.pageSize ? Number(query?.pageSize) : undefined}
				onPageChange={handleChangePage}
				onSortTable={handleSortTable}
				sortBy={query?.field || USER_ACTIVITY_FIELD_NAME.DATE}
				sortDirection={(query?.sort as SORT_DIR) || SORT_DIR.ASC}
			/>
		</div>
	);
};
