import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridCellParams, GridPaginationModel, GridSortDirection, GridSortModel,useGridApiRef } from "@mui/x-data-grid";
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ROUTES } from "routes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { createRight, getRights, getRightsIsLoadingSelector, getRightsSelector } from "store/rights-list";
import { IQuery } from "types";
import { ICreateRoleRequest } from "types/roles-and-rights.interface";
import * as yup from 'yup';
import { CreateModal } from "components/elements";
import { AddRight } from "components/modules/AddRight";
import { PAGINATION_LIMIT } from "utils/constants";
import { SERVICES_FIELDS_NAME } from "utils/enums";
import { formatQueryString, parseQueryString } from "utils/helpers";
import { getTableSetting } from "utils/helpers/table-setting";
import s from './index.module.scss';


export const schema = yup.object().shape({
    test: yup.string()
});

const COLUMN_CONFIG = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'description', headerName: "Description", flex: 1 },
    { field: 'status', headerName: "status", flex: 1 },
];

export const RightsPage = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rights = useAppSelector(getRightsSelector);
    const isLoading = useAppSelector(getRightsIsLoadingSelector);
    const [columnWidths, setColumnWidths] = useState({});
    const apiRef = useGridApiRef();

    const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

    const requestBody = useMemo(
        () => ({
            offset: (query.page || 0) * (query.pageSize || 0),
            limit: (query.pageSize || PAGINATION_LIMIT.SMALL),
        }), [query]);

    useEffect(() => {
        dispatch(getRights(requestBody));
    }, [requestBody, dispatch]);

    const handleColumnResize = (params: any) => {
        const newWidths = {
            ...columnWidths,
            [params.colDef.field]: params.width,
        };
        setColumnWidths(newWidths);
        localStorage.setItem(ROUTES.rights, JSON.stringify(newWidths));
    };

    useEffect(() => {
        setColumnWidths(getTableSetting(ROUTES.rights));
    }, [requestBody]);

    COLUMN_CONFIG.forEach((item: GridColDef) => {
        // @ts-ignore
        if (columnWidths && columnWidths[item.field]) {
            // @ts-ignore
            item.width = columnWidths[item.field];
            item.flex = undefined;
        }
    });

    const submitCreateRight = (data: ICreateRoleRequest) => {
        dispatch(createRight(data));
        dispatch(getRights(requestBody));
    }

    const modifiedRights = useMemo(
        () =>
            rights?.tableData.map((rights) => ({
                ...rights,
                id: rights.name,
            })),
        [rights]
    );

    const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
        navigate(
            {
                search: formatQueryString({
                    ...query,
                    page,
                    pageSize,
                }),
            },
            { replace: true }
        );
    };

    const handleSortField = (model: GridSortModel): void => {
        const { field, sort } = model[0] || {};
        navigate(
            {
                search: formatQueryString({
                    ...query,
                    field,
                    sort,
                    page: 0,
                }),
            },
            { replace: true }
        );
    };

    const handleClickEdit = (params: GridCellParams): void => {
        navigate(`/${ROUTES.rights}/${params.id}`);
    };

    return (<Box className={s.tableWrapper}>
        <DataGrid
            disableRowSelectionOnClick
            apiRef={apiRef}
            getRowId={(row: any) => row.name}
            disableColumnFilter
            rows={modifiedRights || []}
            columns={[
                ...COLUMN_CONFIG,
                {
                    field: 'edit',
                    headerName: ' ',
                    sortable: false,
                    align: 'center',
                    flex: 0.1,
                    renderCell: (params) => (
                        <IconButton onClick={() => handleClickEdit(params)} color="primary" aria-label="Edit">
                            <Edit />
                        </IconButton>
                    ),
                },
            ]}
            rowCount={rights?.listData.totalRows || 0}
            onColumnResize={handleColumnResize}
            loading={isLoading}
            initialState={{
                pagination: {
                    paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
                },
                sorting: {
                    sortModel: [{ field: query.field || SERVICES_FIELDS_NAME.ID, sort: query.sort as GridSortDirection }],
                },
            }}
            slotProps={{
                pagination: {
                    labelRowsPerPage: 'Показувати :',
                    page: query.page || 0,
                    rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
                },
            }}
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={handleSortField}
            onPaginationModelChange={handleChangePage}
            className={s.table}
            pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
        />
        <CreateModal<ICreateRoleRequest>
            title="Нове право"
            onSubmit={submitCreateRight}
            initialValues={{}}
            subTitle="Вкажіть додаткову інформацію"
            validationSchema={schema}
        ><AddRight /></CreateModal>
    </Box>)
}
