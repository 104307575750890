import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ICreateRoleRequest, ICreateRoleResponse, IRolesAndRightsRequest, IRolesResponse } from "types/roles-and-rights.interface";
import { getErrorResponse } from "utils/helpers";
import { RolesService } from "utils/services/roles.service";

export const getRoles = createAsyncThunk(
    'rolesSlice/getRoles',
    async (reqBody: IRolesAndRightsRequest, { rejectWithValue }) => {
        try {
            const response: AxiosResponse<IRolesResponse> = await RolesService.getRoles(reqBody);
            return response.data;
        } catch (error) {
            return rejectWithValue(getErrorResponse(error));
        }
    }
);

export const createRole = createAsyncThunk('rolesSlice/createRole',
    async (reqBody: ICreateRoleRequest, { rejectWithValue }) => {
        try {
            const response: AxiosResponse<ICreateRoleResponse> = await RolesService.createRole(reqBody);
            return response.data;
        } catch (error) {
            return rejectWithValue(getErrorResponse(error));
        }
    }
)
