import { PropsWithChildren, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import editIcon from 'assets/icons/edit.svg';
import { IDetailsItem, IDetailsSetItem, IUseModal } from 'types';
import { EditModal } from 'components/elements';
import { RolesAndRightsList } from 'components/elements/RolesAndRightsList';
import { DATE_FORMAT, SERVICE_DETAILS_FEE_KEYS } from 'utils/enums';
import { formatDateView } from 'utils/helpers/date';
import { useModal } from 'utils/hooks';
import styles from './index.module.scss';

interface IDetailsContentProps<T, K> {
	title: string;
	detailsTitles: IDetailsItem<T>[];
	detailsData: K;
	additionalData?: K;
	setDetailsData?: (value: K) => void;
	onSetUpdatedData?: (data: Partial<K>) => void;
	lockedFields?: T[];
}

// TODO Should be reworked as well as apps/admin/src/components/elements/RolesAndRightsList/index.tsx
export const DetailsContent = <T, K>({
	title,
	detailsTitles,
	detailsData,
	additionalData,
	setDetailsData,
	onSetUpdatedData,
	lockedFields = [],
	children,
}: PropsWithChildren<IDetailsContentProps<T, K>>) => {
	const [selectedItem, setSelectedItem] = useState<IDetailsSetItem<T> | null>(null);
	const [isOpen, handleOpen, handleClose]: IUseModal = useModal();

	const handleSave = (value: string, additionalData?: any) => {
		if (selectedItem && onSetUpdatedData && setDetailsData) {
			setDetailsData({ ...detailsData, [selectedItem.key]: value });
			onSetUpdatedData({ [selectedItem.key]: value } as K);
			if (additionalData) {
				setDetailsData({
					...detailsData,
					[`${selectedItem.key}Id`]: additionalData.id,
				});
			}
			handleModalClose();
		}
	};

	const handleSelect = (item: IDetailsSetItem<T>) => {
		if (additionalData) {
			switch (item.key) {
				case SERVICE_DETAILS_FEE_KEYS.FEE_MIN: {
					item.limitValue = detailsData[SERVICE_DETAILS_FEE_KEYS.FEE_MAX as keyof K] as any;
					item.additionalValue = additionalData[SERVICE_DETAILS_FEE_KEYS.FEE_MIN as keyof K] as any;
					break;
				}
				case SERVICE_DETAILS_FEE_KEYS.FEE_MAX: {
					item.limitValue = detailsData[SERVICE_DETAILS_FEE_KEYS.FEE_MIN as keyof K] as any;
					item.additionalValue = additionalData[SERVICE_DETAILS_FEE_KEYS.FEE_MAX as keyof K] as any;
					break;
				}
				case SERVICE_DETAILS_FEE_KEYS.SUM_FROM: {
					item.limitValue = detailsData[SERVICE_DETAILS_FEE_KEYS.SUM_TO as keyof K] as any;
					item.additionalValue = additionalData[SERVICE_DETAILS_FEE_KEYS.SUM_FROM as keyof K] as any;
					break;
				}
				case SERVICE_DETAILS_FEE_KEYS.SUM_TO: {
					item.limitValue = detailsData[SERVICE_DETAILS_FEE_KEYS.SUM_FROM as keyof K] as any;
					item.additionalValue = additionalData[SERVICE_DETAILS_FEE_KEYS.SUM_TO as keyof K] as any;
					break;
				}
				default:
					break;
			}
		}
		setSelectedItem(item);
		handleOpen();
	};

	const handleModalClose = () => {
		handleClose();
		setSelectedItem(null);
	};

	return (
		<Box className={styles.page}>
			<Box className={styles.page__details}>
				<Typography variant="h5" className={styles.page__details_header}>
					{title}
				</Typography>
				<Box className={styles.page__details_content}>
					{detailsTitles &&
						detailsTitles.map((item, index) => {
							const value = detailsData[item.key as keyof K] as any;
							if (item.isRolesOrRights) {
								return (<RolesAndRightsList<T, K>
									detailsData={detailsData}
									detailsTitles={detailsTitles}
									handleSelect={handleSelect}
									item={item}
									index={index}
									lockedFields={lockedFields}
									key={item.key}
								/>);
							}
							return (
								<Box key={item.key}>
									<Box
										className={styles.page__details_item}
										onClick={() =>
											!lockedFields.includes(item.key) &&
											handleSelect({ value: (detailsData[item.key as keyof K] as string) || '', ...item })
										}
									>
										<Typography variant="h6">{item.title}</Typography>
										<Box className={styles.page__details_editor}>
											{!item.isDate
												?
												<Typography variant="h5">{Array.isArray(value) ? value.join(', ') : value}</Typography>
												:
												<Typography variant="h5">{formatDateView(detailsData[item.key as keyof K] as string, DATE_FORMAT.DAY_MONTH_NAME_YEAR_TIME)}</Typography>
											}
											{!lockedFields.includes(item.key) && <Box component="img" src={editIcon} alt='edit' />}
										</Box>
									</Box>
									<Box className={styles.page__details_divider}>{detailsTitles.length - 1 !== index && <Divider />}</Box>
								</Box>
							)
						})}
				</Box>
				{children}
			</Box>

			{selectedItem && (
				<EditModal<T>
					isOpen={isOpen}
					onClose={handleModalClose}
					title={selectedItem?.modalTitle}
					selectedItem={selectedItem}
					defaultValue={selectedItem.value}
					handleSave={handleSave}
				/>
			)}
		</Box>
	);
};
