import { RadabankMethods } from 'utils/enums/radabank.enum';

export const USER_ACTIVITY_HEADER_TITLES = {
	DATE: 'Дата',
	TIME: 'Час',
	METHOD: 'Метод',
	REQUEST: 'Запит',
	RESPONSE: 'Відповідь',
};

export const USER_ACTIVITY_METHODS_OPTIONS = Object.entries(RadabankMethods).map(([key, value]) => ({
	title: key,
	value: key,
}));
