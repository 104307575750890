import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	IAliasesByProviderRequestCreate,
	IAliasesByProviderRequestList,
	IAliasesByProviderRequestUpdate,
} from 'types';
import { getErrorResponse } from 'utils/helpers';
import { AliasesByProviderService } from "utils/services/aliases-by-provider.service";

export const getAliasesByProvider = createAsyncThunk(
	'aliasesByProviderSlice/getAliases',
	async (reqBody: IAliasesByProviderRequestList, { rejectWithValue }) => {
		try {
			const aliasesByProviderResponse: AxiosResponse<any> = await AliasesByProviderService.getAliasesByProvider(reqBody);
			return aliasesByProviderResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const createAliasesByProvider = createAsyncThunk(
	'aliasesByProviderSlice/createAliases',
	async (reqBody: IAliasesByProviderRequestCreate, { rejectWithValue }) => {
		try {
			const aliasesByProviderResponse: AxiosResponse<any> = await AliasesByProviderService.createAliasesByProvider(reqBody);
			return aliasesByProviderResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateAliasesByProvider = createAsyncThunk(
	'aliasesByProviderSlice/updateAliases',
	async (reqBody: IAliasesByProviderRequestUpdate, { rejectWithValue }) => {
		try {
			const aliasesByProviderResponse: AxiosResponse<any> = await AliasesByProviderService.updateAliasesByProvider(reqBody);
			return aliasesByProviderResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

