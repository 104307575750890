import { IDetailsItem } from 'types';
import { USER_PROFILE_KEYS } from 'utils/enums/details-pages-keys.enum';

export const USER_PROFILE_TITLES: IDetailsItem<USER_PROFILE_KEYS>[] = [
	{
		title: 'Імʼя',
		key: USER_PROFILE_KEYS.FIRST_NAME,
		modalTitle: 'Вкажіть ваше імʼя',
	},
	{
		title: 'Прізвище',
		key: USER_PROFILE_KEYS.LAST_NAME,
		modalTitle: 'Вкажіть ваше прізвище',
	},
	{
		title: 'Роль',
		key: USER_PROFILE_KEYS.TYPE,
		modalTitle: 'Вкажіть вашу роль',
	},
	{
		title: 'Email',
		key: USER_PROFILE_KEYS.EMAIL,
		modalTitle: 'Вкажіть ваш email',
	},
	{
		title: 'Телефон',
		key: USER_PROFILE_KEYS.PHONE,
		modalTitle: 'Вкажіть ваш номер',
	},
];

export const USER_PROFILE_LOCKED_FIELDS = [USER_PROFILE_KEYS.TYPE, USER_PROFILE_KEYS.PHONE, USER_PROFILE_KEYS.EMAIL];
