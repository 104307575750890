import { AxiosError } from 'axios';
import { IErrorResponse } from 'types';

export const getErrorResponse = (error: AxiosError<IErrorResponse>): IErrorResponse | undefined =>
	(error.response?.data || error?.response || error) as IErrorResponse | undefined;

export const getErrorMessage = (error: AxiosError<IErrorResponse>): string => {
	const errorResponse: IErrorResponse | undefined = getErrorResponse(error);
	return errorResponse?.errorData?.message || errorResponse?.message || error?.message;
};
