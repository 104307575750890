import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Typography } from '@mui/material';
import {
	DataGrid,
	GridCellParams,
	GridPaginationModel,
	GridSortDirection,
	GridSortModel,
	useGridApiRef
} from '@mui/x-data-grid';
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ReactComponent as CircleCheck } from 'assets/icons/circle-check.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ROUTES } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserProfileSelector } from 'store/user';
import {
	clearUsersData,
	createUser,
	getUsersIsLoadingSelector,
	getUsersList,
	getUsersListSelector,
} from 'store/users-list';
import { ICreateUserFormik, IQuery } from 'types';
import { CreateModal } from 'components/elements';
import { AddUser } from 'components/modules/AddUser';
import ConfirmationModal from 'components/modules/ConfirmationModal';
import { CREATE_USER, PAGINATION_LIMIT, USER_ROLE_TITLE } from 'utils/constants';
import { DATE_FORMAT, SORT_DIR, STORAGE_KEY, USER_ROLE,USERS_FIELDS_NAME } from 'utils/enums';
import { Rights } from 'utils/enums/rights.enum';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { formatDateView } from 'utils/helpers/date';
import { getTableSetting } from "utils/helpers/table-setting";
import { getUserStatus } from 'utils/helpers/user-status';
import { usePermissions } from 'utils/hooks/use-permissions';
import { StorageService } from 'utils/services';
import { createUserSchema } from 'utils/validation';
import { COLUMNS_CONFIG, getCellClassName } from './config';
import s from './index.module.scss';

export const UsersPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true);
	const [columnWidths, setColumnWidths] = useState({});
	const usersList = useAppSelector(getUsersListSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const isLoading = useAppSelector(getUsersIsLoadingSelector);
	const apiRef = useGridApiRef();

	const canI = usePermissions();
	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const requestBody = useMemo(
		() => ({
			pagination: {
				offset: (query.page || 0) * (query.pageSize || 0),
				limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			},
			sorting: {
				field: query.field || USERS_FIELDS_NAME.UPDATED_AT,
				direction: query.sort || SORT_DIR.DSC,
			},
			search: `${query.searchQuery || ''}`,
		}),
		[query]
	);

	useEffect(() => {
		dispatch(getUsersList(requestBody));
	}, [requestBody, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearUsersData());
		};
	}, [dispatch]);

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.users));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		if (columnWidths)
		// @ts-ignore
		{if (columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}}
	});

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	const onSubmitCreateUser = (values: ICreateUserFormik) => {
		dispatch(createUser(values));
		dispatch(getUsersList(requestBody));
	};

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.users, JSON.stringify(newWidths));
	};

	const modifiedAddresses = useMemo(
		() =>
			usersList?.results.map((users) => ({
				...users,
				firstName: `${users.firstName || ''} ${users.lastName || ''}`,
				status: getUserStatus(users.status),
				phone: users.phone ? `+${users.phone}` : '',
				type: users.type === USER_ROLE.ADMIN ? USER_ROLE_TITLE.ADMIN : USER_ROLE_TITLE.USER,
				updatedAt: formatDateView(users.updatedAt, DATE_FORMAT.DATE_FULL),
			})),
		[usersList]
	);

	const handleClickEdit = (params: GridCellParams): void => {
		navigate(`/${ROUTES.users}/${params.id}`);
	};

	const handleWelcomeModalClose = () => {
		setIsWelcomeModalOpen(false);
		StorageService.delete(STORAGE_KEY.IS_PASSWORD_CHANGED);
	};

	return (
		<Box
			className={s.tableWrapper}
			sx={{
				'& .negative': {
					color: 'text.secondary',
				},
				'& .positive': {
					color: 'success.main',
				},
			}}
		>
			<DataGrid
				apiRef={apiRef}
				disableColumnFilter
				disableRowSelectionOnClick
				rows={modifiedAddresses || []}
				rowCount={usersList?.total || 0}
				loading={isLoading}
				onColumnResize={handleColumnResize}
				columns={[
					...COLUMNS_CONFIG,
					{
						field: 'edit',
						headerName: ' ',
						sortable: false,
						align: 'center',
						width: 60,
						renderCell: (params) => (
							canI(Rights.USERS_MANAGE) &&
							<IconButton onClick={() => handleClickEdit(params)} color="primary" aria-label="Edit">
								<Edit />
							</IconButton>
						),
					},
				]}
				getCellClassName={(params: GridCellParams) => getCellClassName(params)}
				initialState={{
					pagination: {
						paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
					},
					sorting: {
						sortModel: [{ field: query.field || USERS_FIELDS_NAME.EMAIL, sort: query.sort as GridSortDirection }],
					},
				}}
				slotProps={{
					pagination: {
						labelRowsPerPage: 'Показувати :',
						page: query.page || 0,
						rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
					},
				}}
				paginationMode="server"
				sortingMode="server"
				onSortModelChange={handleSortField}
				onPaginationModelChange={handleChangePage}
				className={s.table}
				pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
			/>
			<CreateModal<ICreateUserFormik>
				initialValues={CREATE_USER}
				title="Новий користувач"
				subTitle="Вкажіть персональні данні нижче"
				onSubmit={onSubmitCreateUser}
				validationSchema={createUserSchema}
			>
				<AddUser />
			</CreateModal>
			{!!StorageService.get(STORAGE_KEY.IS_PASSWORD_CHANGED) && (
				<ConfirmationModal
					open={isWelcomeModalOpen}
					modalIcon={<CircleCheck />}
					title={`Привіт, ${profile?.firstName}`}
				>
					<Typography variant="h5" color="common.textLightGray">
						Ваш пароль було успішно змінено
					</Typography>
					<Button variant="rounded" onClick={handleWelcomeModalClose}>
						Продовжити
					</Button>
				</ConfirmationModal>
			)}
		</Box>
	);
};
