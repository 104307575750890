import { SERVICE_DETAILS_FEE_KEYS, SERVICE_DETAILS_REG_KEYS, SERVICE_STATUS } from 'utils/enums';

export const SERVICES_HEADER_TITLES = {
	ID: 'ID',
	PARTNER_ID: 'ID партнера',
	NAME: 'Назва',
	STATUS: 'Статус',
	CREATED_AT: 'Створено',
};

export const SERVICE_STATUS_TITLE = {
	ACTIVE: 'Активний',
	DISABLED: 'Не активний',
	BLOCKED: 'Заблокований',
	TEMPORARY_BLOCKED: 'Тимчасово заблокований',
};

export const SERVICE_STATUS_OPTIONS = [
	{ title: SERVICE_STATUS_TITLE.ACTIVE, value: SERVICE_STATUS.ACTIVE },
	{ title: SERVICE_STATUS_TITLE.DISABLED, value: SERVICE_STATUS.DISABLED },
	{ title: SERVICE_STATUS_TITLE.BLOCKED, value: SERVICE_STATUS.BLOCKED },
	{ title: SERVICE_STATUS_TITLE.TEMPORARY_BLOCKED, value: SERVICE_STATUS.TEMPORARY_BLOCKED }
];

export const CREATE_SERVICE = {
	name: '',
};

export const SERVICES_LOCKED_REG_FIELDS = [ SERVICE_DETAILS_REG_KEYS.NAME, SERVICE_DETAILS_REG_KEYS.ZKPO, SERVICE_DETAILS_REG_KEYS.BANK_NAME, SERVICE_DETAILS_REG_KEYS.BANK_MFO, SERVICE_DETAILS_REG_KEYS.ACCOUNT, SERVICE_DETAILS_REG_KEYS.DESCRIPTION ];
export const SERVICES_LOCKED_FEE_FIELDS = [ SERVICE_DETAILS_FEE_KEYS.FEE_PERCENT, SERVICE_DETAILS_FEE_KEYS.FEE_MIN, SERVICE_DETAILS_FEE_KEYS.FEE_MAX, SERVICE_DETAILS_FEE_KEYS.FIXED_AMOUNT, SERVICE_DETAILS_FEE_KEYS.SUM_TO, SERVICE_DETAILS_FEE_KEYS.SUM_FROM ];
