import { getCityWithShortPrefix } from 'utils/helpers';

export const CITIES = [
	{
		id: 1,
		name: 'Харків',
		shorPrefix: 'м.',
		prefix: 'місто',
	},
	{
		id: 2,
		name: 'Пісочин',
		shorPrefix: 'смт.',
		prefix: 'селище міського типу',
	},
	{
		id: 3,
		name: 'Солоницівка',
		shorPrefix: 'смт.',
		prefix: 'селище міського типу',
	},
	{
		id: 4,
		name: 'Безлюдівка',
		shorPrefix: 'смт.',
		prefix: 'селище міського типу',
	},
	{
		id: 5,
		name: 'Подвірки',
		shorPrefix: 'сел.',
		prefix: 'село',
	},
	{
		id: 6,
		name: 'Лісне',
		shorPrefix: 'сел.',
		prefix: 'село',
	},
	{
		id: 7,
		name: 'Васищеве',
		shorPrefix: 'сел.',
		prefix: 'село',
	},
	{
		id: 8,
		name: "П'ятихатки",
		shorPrefix: 'сел.',
		prefix: 'село',
	},
	{
		id: 9,
		name: 'Котляри (Комунар)',
		shorPrefix: 'сел.',
		prefix: 'село',
	},
];

export const CITIES_OPTIONS = CITIES.map((city) => ({ value: city.id, title: getCityWithShortPrefix(city) }));
