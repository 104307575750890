import { createSlice } from '@reduxjs/toolkit';
import { IAliasesByProviderReducer } from 'types';
import { getAliasesByProvider } from './actions';

const initialState: IAliasesByProviderReducer = {
	isLoading: true,
	data: null,
};

const aliasByProviderSlice = createSlice({
	name: 'aliasByProviderSlice',
	initialState,
	reducers: {
		clearAliasesByProviderData: (state) => {
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getAliasesByProvider.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getAliasesByProvider.fulfilled, (state, { payload }) => {
			state.data = payload;
			state.isLoading = false;
		});
		builder.addCase(getAliasesByProvider.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { clearAliasesByProviderData } = aliasByProviderSlice.actions;
export const AliasesByProviderReducer = aliasByProviderSlice.reducer;
