import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { ROUTES } from 'routes';
import { getTableSetting } from 'utils/helpers/table-setting';
import { StorageService } from 'utils/services';

export const useResizeDataGridColumn = <T extends object>(route: ROUTES, columnsConfig: GridColDef<T>[]) => {
	const [columnWidths, setColumnWidths] = useState<Record<string, number>>(() => {
		const storageValue = StorageService.get(route) as string;

		return storageValue ? JSON.parse(storageValue) : {};
	});

	useEffect(() => {
		setColumnWidths(getTableSetting(route));
	}, [route]);

	const handleColumnResize = (params: { colDef: { field: string }; width: number }) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(route, JSON.stringify(newWidths));
	};

	const columns = useMemo(
		() =>
			columnsConfig.map((column) =>
				columnWidths && columnWidths[column.field]
					? { ...column, flex: undefined, width: columnWidths[column.field] }
					: column
			),
		[columnWidths, columnsConfig]
	);

	return {
		handleColumnResize,
		columnWidths,
		columns,
	};
};
