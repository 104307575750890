import { FC } from 'react';
import { CustomModal } from 'components/elements/CustomModal';
import { StreetForm } from '../StreetForm';

interface ManageStreetModalProps {
	isOpen: boolean;
	onClose: () => void;
	initialValues: any;
	onSubmit: (values: any) => void;
	buttonText: string;
	title: string;
}

export const ManageStreetModal: FC<ManageStreetModalProps> = ({
	isOpen,
	onClose,
	initialValues,
	onSubmit,
	buttonText,
	title,
}) => {
	return (
		<CustomModal open={isOpen} onClose={onClose} title={title}>
			<StreetForm initialValues={initialValues} onSubmit={onSubmit} buttonText={buttonText} />
		</CustomModal>
	);
};
