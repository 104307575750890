import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, TextField } from '@mui/material';
import {
    DataGrid,
    GridCellParams,
    GridPaginationModel,
    GridSortDirection,
    GridSortModel,
    useGridApiRef
} from '@mui/x-data-grid';
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import { ROUTES } from "routes";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    clearPaymentsData,
    getPaymentList,
    getPaymentListInCSV,
    getPaymentListIsLoadingSelector,
    getPaymentListSelector
} from "store/payment";
import { CustomDatePickerFormik, CustomSelectFormik } from "components/elements";
import toastService from "components/elements/Toastify";
import { MAX_CSV_FILE_SIZE, PAGINATION_LIMIT, PAYMENTS_PROVIDER_OPTIONS, PAYMENTS_STATUS_OPTIONS } from 'utils/constants';
import { DATE_FORMAT, SERVICES_FIELDS_NAME, SORT_DIR } from 'utils/enums';
import { convertCoinsToUAH, formatQueryString, getLocaleAmountString, parseQueryString } from 'utils/helpers';
import { formatDateView } from 'utils/helpers/date';
import { getTableSetting } from "utils/helpers/table-setting";
import { disablePastYear, shouldDisableMonth } from "../OtherReceiptsPage/config";
import { COLUMNS_CONFIG, downloadCSV } from './config';
import styles from "./index.module.scss";
import s from './index.module.scss';
import { useDebounce } from 'utils/hooks';

export const PaymentPage: FC = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const payments = useAppSelector(getPaymentListSelector);
    const isLoading = useAppSelector(getPaymentListIsLoadingSelector);
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const [amount, setAmount] = useState<string | undefined>(undefined);
    const [cardPan, setCardPan] = useState<string | undefined>('');
    const apiRef = useGridApiRef();
    const [columnWidths, setColumnWidths] = useState({});

    const debouncedAmount = useDebounce(amount);
    const debouncedUserId = useDebounce(userId);
    const debouncedCardPan = useDebounce(cardPan);

    const query = useMemo(() => parseQueryString<any>(search, { parseBooleans: true, parseNumbers: true }), [search]);

    const requestBody = useMemo(
        () => ({
            offset: (query.page || 0) * (query.pageSize || 0),
            limit: query.pageSize || PAGINATION_LIMIT.BIG,
            order: {
                field: query.field || SERVICES_FIELDS_NAME.CREATED_AT,
                direction: query.sort || SORT_DIR.ASC,
            },
            options: {
                paymentId: query.searchQuery && [query.searchQuery],
                user: debouncedUserId ? {
                    id: debouncedUserId,
                } : undefined,
                amount: debouncedAmount ? {
                    eq: Number(debouncedAmount) * 100,
                } : undefined,
                cardPan: debouncedCardPan ? [debouncedCardPan] : undefined,
                provider: query.provider ? [query.provider] : undefined,
                status: query.status ? [query.status] : undefined,
                dateRange: {
                    gte: query.gte ? dayjs(query.gte).toISOString() : dayjs().startOf("month").toISOString(),
                    lte: dayjs(query.lte).toISOString(),
                },
            }
        }),
        [query, debouncedUserId, debouncedAmount, debouncedCardPan]
    );

    const handleSearchClear = () => {
        navigate(
            {
                search: formatQueryString({
                    query: '',
                }),
            },
            { replace: true }
        );
        setUserId(undefined);
        setAmount(undefined);
        setCardPan('')
    };

    useEffect(() => {
        dispatch(getPaymentList(requestBody));
    }, [requestBody, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearPaymentsData());
        };
    }, [dispatch]);

    const handleColumnResize = (params: any) => {
        const newWidths = {
            ...columnWidths,
            [params.colDef.field]: params.width,
        };
        setColumnWidths(newWidths);
        localStorage.setItem(ROUTES.paymentStatus, JSON.stringify(newWidths));
    };

    useEffect(() => {
        setColumnWidths(getTableSetting(ROUTES.paymentStatus));
    }, [requestBody]);

    COLUMNS_CONFIG.forEach((item: GridColDef) => {
        // @ts-ignore
        if (columnWidths && columnWidths[item.field]) {
            // @ts-ignore
            item.width = columnWidths[item.field];
            item.flex = undefined;
        }
    });

    const modifiedPaymentsData = useMemo(
        () => {
            return payments?.paymentsData.map((payment) => ({
                ...payment,
                id: payment.paymentId,
                amount: getLocaleAmountString(convertCoinsToUAH(payment.amount)),
                fee: getLocaleAmountString(payment.fee),
                paidAt: formatDateView(payment.paidAt, DATE_FORMAT.DATE_FULL),
                createdAt: formatDateView(payment.createdAt, DATE_FORMAT.DATE_FULL),
            }))
        },
        [payments]
    );

    const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
        navigate(
            {
                search: formatQueryString({
                    ...query,
                    page,
                    pageSize,
                }),
            },
            { replace: true }
        );
    };

    const handleDownloadCSV = async () => {
        if (payments && payments.listData.totalRows > MAX_CSV_FILE_SIZE) {
            toastService.warning(`Даних має бути менше ніж ${MAX_CSV_FILE_SIZE}`)
            return;
        }
        const { payload } = await dispatch(getPaymentListInCSV({
            ...requestBody,
            options: { ...requestBody.options, inCSV: "YES" }
        })) as { payload: { csvFileData: string } };
        const dateGte = formatDateView(query.gte, DATE_FORMAT.DATE_VIEW)
        const dateLte = formatDateView(query.lte, DATE_FORMAT.DATE_VIEW)
        downloadCSV(payload.csvFileData, `data_${dateGte}-${dateLte}.csv`);
    }

    const handleChangeClientId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setUserId(value);
    };

    const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setAmount(value);
    };

    const handleChangeCardPan = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setCardPan(value);
    };

    const handleSortField = (model: GridSortModel): void => {
        const { field, sort } = model[0] || {};
        navigate(
            {
                search: formatQueryString({
                    ...query,
                    field,
                    sort,
                }),
            },
            { replace: true }
        );
    };

    const handleClickEdit = (params: GridCellParams) => {
        if (params.row.externalId === null) {
            toastService.error('Запис не знайдено, billId відсутнє');
            return;
        }
        navigate(`/${ROUTES.paymentStatus}/${params.row.externalId}`);
    };

    const handleSearchChange = (values: any): void => {
        const { gte, lte, provider, status } = values;
        if (dayjs(lte || undefined).add(-2, 'month') >= dayjs(gte || undefined)) {
            toastService.error('Проміжок від початкової та кінцевої дати повинен бути не більше двох місяців')
            return;
        }
        navigate({
            search: formatQueryString({
                ...query,
                ...values,
                status,
                provider,
                gte: gte ? dayjs(gte).startOf('day').format() : null,
                lte: lte ? dayjs(lte).endOf('day').format() : null,
            })
        }, { replace: true })
    }

    const initialValues = {
        gte: null,
        lte: null,
        status: '',
        provider: '',
    }

    return (
        <>
            <Box>
                <Box>
                    <Box className={s.filter}>
                        <TextField
                            className={s.filter__text}
                            value={userId ?? ''}
                            placeholder="Пошук по id користувача"
                            onChange={handleChangeClientId}
                        />
                        <TextField
                            className={s.filter__text}
                            value={amount ?? ''}
                            type="number"
                            placeholder="Пошук по сумі оплати"
                            onChange={handleChangeAmount}
                        />
                        <TextField
                            className={s.filter__text}
                            value={cardPan}
                            placeholder="Пошук по PANy"
                            onChange={handleChangeCardPan}
                        />
                        <Button variant="contained" onClick={handleDownloadCSV}>Завантажити CSV</Button>
                    </Box>
                    <Formik initialValues={initialValues} onSubmit={handleSearchChange}>
                        <Form className={s.filter}>
                            <Box className={s.date__filters}>
                                <Field
                                    name="gte"
                                    views={['year', 'month', 'day']}
                                    format={DATE_FORMAT.DAY_MONTH_NAME_YEAR}
                                    shouldDisableMonth={shouldDisableMonth}
                                    shouldDisableYear={disablePastYear}
                                    className={styles.date__input}
                                    component={CustomDatePickerFormik}
                                />
                                <span>-</span>
                                <Field
                                    name="lte"
                                    views={['year', 'month', 'day']}
                                    format={DATE_FORMAT.DAY_MONTH_NAME_YEAR}
                                    shouldDisableMonth={shouldDisableMonth}
                                    shouldDisableYear={disablePastYear}
                                    className={styles.date__input}
                                    component={CustomDatePickerFormik}
                                />
                            </Box>
                            <Field
                                name="status"
                                placeholder="Статус"
                                options={PAYMENTS_STATUS_OPTIONS}
                                component={CustomSelectFormik}
                            />
                            <Field
                                name="provider"
                                placeholder="Провайдер"
                                options={PAYMENTS_PROVIDER_OPTIONS}
                                component={CustomSelectFormik}
                            />
                            <Button variant="contained" type="submit">Застосувати</Button>
                            <Button variant="outlined" type="reset" onClick={handleSearchClear}>Очистити</Button>
                        </Form>
                    </Formik>
                </Box>
            </Box>
            <Box
                className={s.tableWrapper}
                sx={{
                    '& .negative': {
                        color: 'text.secondary',
                    },
                    '& .positive': {
                        color: 'success.main',
                    },
                }}
            >
                <DataGrid
                    disableRowSelectionOnClick
                    disableColumnFilter
                    onColumnResize={handleColumnResize}
                    apiRef={apiRef}
                    rows={modifiedPaymentsData || []}
                    columns={[
                        ...COLUMNS_CONFIG,
                        {
                            field: 'edit',
                            headerName: ' ',
                            sortable: false,
                            align: 'center',
                            width: 60,
                            renderCell: (params) => (
                                <IconButton onClick={() => handleClickEdit(params)} color="primary" aria-label="Edit">
                                    <Edit/>
                                </IconButton>
                            ),
                        },
                    ]}
                    rowCount={payments?.listData.totalRows || 0}
                    loading={isLoading}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: query.page || 0,
                                pageSize: query.pageSize || PAGINATION_LIMIT.BIG
                            },
                        },
                        sorting: {
                            sortModel: [{
                                field: query.field || SERVICES_FIELDS_NAME.ID,
                                sort: query.sort as GridSortDirection
                            }],
                        },
                    }}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: 'Показувати :',
                            page: query.page || 0,
                            rowsPerPage: query.pageSize || PAGINATION_LIMIT.BIG,
                        },
                    }}
                    paginationMode="server"
                    sortingMode="server"
                    onSortModelChange={handleSortField}
                    onPaginationModelChange={handleChangePage}
                    className={s.table}
                    pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
                />
            </Box>
        </>
    );
};
