import { ROUTES } from 'routes';

export const ROUTE_TITLES: Record<string, string> = {
	[ROUTES.main]: 'Головна',
	[ROUTES.login]: 'Вхід',
	[ROUTES.forbidden]: 'Заборонено',
	[ROUTES.resetPassword]: 'Скидання паролю',
	[ROUTES.setPassword]: 'Встановити пароль',
	[ROUTES.users]: 'Користувачі',
	[ROUTES.clients]: 'Клієнти',
	[ROUTES.services]: 'Сервіси',
	[ROUTES.providers]: 'Провайдери',
	[ROUTES.categories]: 'Категорії',
	[ROUTES.addresses]: 'Помешкання',
	[ROUTES.aliasesByProvider]: 'Аліаси',
	[ROUTES.streets]: 'Вулиці',
	[ROUTES.streetsType]: 'Типи вулиць',
	[ROUTES.paymentStatus]: 'Платежі',
	[ROUTES.rejectedData]: 'Невизначені дані',
	[ROUTES.profileSetups]: 'Налаштування профілю',
	[ROUTES.reportData]: 'Звіти',
	[ROUTES.dataSettings]: 'Керування даними',
	[ROUTES.notFound]: 'Сторінку не знайдено',
	[ROUTES.singleReceipts]: 'Єдині Квитанції',
	[ROUTES.otherReceipts]: 'Інші платежі',
	[ROUTES.roles]: 'Ролі',
	[ROUTES.rights]: 'Права',

	[ROUTES.usersDetails]: 'Деталі користувача',
	[ROUTES.usersDetailsActivity]: 'Журнал HOSTCARD',
	[ROUTES.addressDetails]: 'Деталі помешкання',
	[ROUTES.serviceDetails]: 'Деталі сервісу',
	[ROUTES.singleReceiptDetails]: 'Деталі ЄК',
	[ROUTES.rightsDetails]: 'Деталі прав',
	[ROUTES.rolesDetails]: 'Деталі ролі',
	[ROUTES.paymentStatusDetails]: 'Деталі платежу',
	[ROUTES.receiptPayDeepLink]: '',
};
