// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CellWithModal_modal__a5kDR .MuiDialog-paper {
  max-width: 80vw;
  min-width: 448px;
  width: max-content;
  padding: 24px;
}

.CellWithModal_cell__n4cvU {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/CellWithModal/index.module.scss"],"names":[],"mappings":"AAEI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;AADR;;AAKA;EACI,mBAAA;EACA,uBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".modal {\n    \n    :global(.MuiDialog-paper){ \n        max-width: 80vw;\n        min-width: 448px;\n        width: max-content;\n        padding: 24px;\n    }\n}\n\n.cell {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `CellWithModal_modal__a5kDR`,
	"cell": `CellWithModal_cell__n4cvU`
};
export default ___CSS_LOADER_EXPORT___;
