import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Box, Typography } from '@mui/material';
import { ROUTES } from 'routes';
import { useAppSelector } from 'store/hooks';
import { getUserProfileSelector } from 'store/user';
import s from './styles.module.scss';

const UserProfile: FC = () => {
	const profile = useAppSelector(getUserProfileSelector);
	return (
		<Link to={`/${  ROUTES.profileSetups}`} className={s.profile}>
			<Avatar src="" alt="userAvatar" className={s.profile__avatar} />
			<Box>
				<Typography variant="h5">
					{profile?.firstName} {profile?.lastName}
				</Typography>
			</Box>
		</Link>
	);
};

export default UserProfile;
