import { experimental_extendTheme as extendTheme } from '@mui/material';
import { ukUA } from "@mui/x-data-grid/locales";

export const theme = extendTheme(
	{
		cssVarPrefix: '',
		colorSchemes: {
			light: {
				palette: {
					primary: {
						main: '#57486d',
						light: '#EDF2F2',
					},
					secondary: {
						main: '#CD463A',
					},
					text: {
						primary: '#0F1918',
						secondary: '#CD463A',
						disabled: '#b2abbc',
					},
					background: {
						default: '#FFFFFF',
						paper: '#FAFAFA',
					},
					error: {
						main: '#EC6152',
					},
					success: {
						main: '#2A9C3E',
					},
					common: {
						green: '#34c34d',
						lightGreen: '#94b0af',
						textLightGray: '#757877',
					},
				},
			},
		},

		components: {
			MuiTablePagination: {
				styleOverrides: {
					root: {
						width: '100%',
					},
					toolbar: {
						alignItems: 'center',
					},
					actions: {
						order: '5',
						display: 'flex',
						gap: '136px',
						paddingRight: '24px',
					},

					selectLabel: {
						order: '1',
					},
					input: {
						order: '2',
					},
					menuItem: {
						width: 'min-content',
					},
					spacer: {
						order: '3',
					},

					displayedRows: {
						position: 'absolute',
						right: '72px',
						order: '4',
					},
				},
			},

			MuiDataGrid: {
				styleOverrides: {
					root: ({ theme, ownerState }) => ({
						cursor: ownerState?.loading ? 'wait' : 'auto',
						'.MuiDataGrid-columnHeaderTitle': {
							fontSize: '16px',
						},

						'.MuiDataGrid-columnHeader': {
							border: `1px solid ${theme.palette.text.disabled}`,
							backgroundColor: theme.palette.primary.light,
							pointerEvents: ownerState?.loading ? 'none' : 'auto',
						},

						'& .MuiDataGrid-columnHeader, .MuiDataGrid-cellCheckbox': {
							':focus': {
								outline: 'none',
							},
						},

						'.MuiDataGrid-cell': {
							border: `1px solid ${theme.vars.palette.text.disabled}`,
							fontSize: '14px',
							cursor: 'pointer',
							position: 'relative'
						},

						'.MuiDataGrid-row:nth-of-type(odd):not(:hover)': {
							backgroundColor: theme.vars.palette.background.default,
						},
					}),
				},
			},

			MuiTabs: {
				variants: [
					{
						props: { indicatorColor: 'transparent' },
						style: () => ({
							'& .MuiTab-root': {
								fontSize: '16px',
								fontWeight: '700',
								padding: '8px 55px',
								alignItems: 'center',
								height: '40px',
								color: theme.vars.palette.text.primary,
								textAlign: 'left',
								borderRadius: '60px',

								'&.Mui-selected ': {
									backgroundColor: theme.vars.palette.primary.main,
									color: theme.vars.palette.common.white,
									borderBottom: 'none',
								},
							},
						}),
					},
				],
				styleOverrides: {
					root: ({ theme }) => ({
						color: theme.vars.palette.text.primary,
						'&.MuiTabs-vertical .MuiTab-root': {
							fontSize: '16px',
							fontWeight: '400',
							width: '100%',
							maxWidth: '100%',
							padding: '12px 12px 12px 24px',
							alignItems: 'start',
							height: '48px',
							color: theme.vars.palette.text.primary,
							textAlign: 'left',

							'&.Mui-selected ': {
								backgroundColor: theme.vars.palette.primary.main,
								color: theme.vars.palette.common.white,
								borderBottom: 'none',
							},
						},
					}),
					indicator: {
						display: 'none',
					},
				},
			},

			MuiButton: {
				variants: [
					{
						props: { variant: 'rounded' },
						style: () => ({
							borderRadius: '40px',
							padding: '15px 22px',
							fontSize: '16px',
							fontWeight: '700',
							width: '100%',
							height: '52px',
							color: '#ffc89a',
							backgroundColor: theme.vars.palette.primary.main,

							'&:hover': {
								textDecoration: 'none',
								backgroundColor: theme.vars.palette.primary.dark,
							},

							'&:disabled': {
								color: theme.vars.palette.common.white,
								backgroundColor: theme.vars.palette.text.disabled,
							},
						}),
					},
				],

				styleOverrides: {
					root: ({ ownerState }) => ({
						borderRadius: ownerState.rounded ? '40px' : '4px',
						padding: '8px 22px',
						width: 'fit-content',
						minWidth: 'unset',
					}),
					outlined: ({ ownerState }) => ({
						fontWeight: '400',
						color: ownerState.color,
						border: '1px solid',
						borderColor: ownerState.color,
						borderRadius: '4px',
					}),
				},
			},

			MuiSelect: {
				styleOverrides: {
					select: {
						minWidth: '160px',
					},
				},
			},

			MuiAutocomplete: {
				styleOverrides: {
					popper: { zIndex: 10000 },
				},
			},

			MuiPopper: {
				styleOverrides: {
					root: { 
						'& .MuiDateCalendar-root': {
							'--palette-text-secondary': 'var(--palette-text-disabled)'
						}
					}
				}
			},

			MuiCheckbox: {
				styleOverrides: {
					root: ({ theme }) => ({
						color: theme.vars.palette.common.textLightGray,
					}),
				},
			},

			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: '4px',
						width: '100%',
					},
					input: ({ theme }) => ({
						width: '100%',
						padding: '7px 12px',
						fontSize: '16px',
						color: theme.vars.palette.text.primary,
						'&:disabled': {
							color: theme.vars.palette.text.disabled,
						},
					}),
				},
			},

			MuiFormControl: {
				styleOverrides: {
					root: {
						width: '100%',
						'& .MuiFormHelperText-root': {
							position: 'absolute',
							top: '32px',
							margin: '5px',
						},

						label: {
							bottom: '22px',
							top: 'initial',

							'&.Mui-focused, &.MuiFormLabel-filled': {
								top: 0,
								bottom: 0,
							},
						},
					},
				},
			},

			MuiTypography: {
				styleOverrides: {
					root: {
						width: 'fit-content',
					},
				},
			},

			MuiDialog: {
				styleOverrides: {
					root: {
						boxSizing: 'border-box',
						zIndex: 9999,
					},
					paper: {
						maxWidth: 'none',
						width: '448px',
						borderRadius: '8px',
						boxShadow: '0px 10px 15px -3px rgba(6, 6, 43, 0.1), 0px 4px 6px rgba(6, 6, 43, 0.05)',
						padding: '24px 60px',
						alignItems: 'center',
						margin: 0,
						'& > div.MuiDialogContent-root': { paddingTop: '20px' },
					},
				},
			},

			MuiDialogTitle: {
				styleOverrides: {
					root: {
						padding: 0,
						fontWeight: '700',
						fontSize: '22px',
					},
				},
			},

			MuiDialogContent: {
				styleOverrides: {
					root: {
						paddingTop: '24px ',
						width: '100%',
					},
				},
			},
			MuiPopover: {
				styleOverrides: {
					root: {
						zIndex: 9999,
					},
				},
			},

			MuiFormLabel: {
				styleOverrides: {
					root: ({ theme }) => ({
						color: theme.palette.primary.main,
					}),
				},
			},
			MuiCircularProgress: {
				styleOverrides: {
					root: ({ theme }) => ({
						color: theme.palette.primary.main,
						borderRadius: '8px',
					}),
					circle: {
						strokeLinecap: 'round',
					},
				},
			},
			MuiModal: {
				styleOverrides: {
					root: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						margin: 'auto',
					},
				},
			},
		},
		typography: {
			fontFamily: 'Fixel Display',
			fontSize: 14,
			h6: {
				fontSize: '14px',
				fontWeight: 700,
			},
			h5: {
				fontSize: '16px',
				fontWeight: 700,
			},
			h4: {
				fontSize: '20px',
				fontWeight: 700,
			},
			h3: {
				fontSize: '24px',
				fontWeight: 700,
			},
			h2: {
				fontSize: '34px',
				fontWeight: 700,
			},
			body2: {
				fontSize: '16px',
			},
			body1: {
				fontSize: '20px',
			},
			button: {
				fontSize: '14px',
				fontWeight: 400,
				lineHeight: '20px',
				textTransform: 'initial',
			},
		},
	},
	ukUA
);
