import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IServicesRequest, IServicesResponse, IUpdateServiceFeeRequest, IUpdateServiceRequest } from 'types';
import { API_URL, CHECK_RESPONSE } from 'utils/enums';

class ServicesService {
	static getServices = async (reqBody: IServicesRequest): Promise<AxiosResponse<IServicesResponse>> =>
		API.post(API_URL.BILL_SERVICES_GET, reqBody);

	static createService = async (reqBody: IUpdateServiceRequest) =>
		API.post(API_URL.BILL_SERVICES_CREATE, { ...reqBody, bulk: CHECK_RESPONSE.NO });

	static getServiceById = async (id: string) =>
		API.post(API_URL.BILL_SERVICE_DETAILS_GET, {
			serviceId: id,
		});

	static updateService = async (reqBody: IUpdateServiceRequest) => API.post(API_URL.BILL_SERVICE_UPDATE, reqBody);

	static updateServiceFee = async (reqBody: IUpdateServiceFeeRequest) => API.post(API_URL.BILL_SERVICE_UPDATE_FEE, reqBody);
}

export { ServicesService };
