import { IDetailsItem } from 'types';
import { ROLE_DETAILS_KEYS } from 'utils/enums/details-pages-keys.enum';

export const DETAILS_TITLES: IDetailsItem<ROLE_DETAILS_KEYS>[] = [
	{
		title: 'Назва',
		key: ROLE_DETAILS_KEYS.NAME,
		modalTitle: 'Вкажіть назву ролі',
	},
	{
		title: 'Опис',
		key: ROLE_DETAILS_KEYS.DESCRIPTION,
		modalTitle: 'Вкажіть опис',
	},
	{
		title: 'Права',
		key: ROLE_DETAILS_KEYS.RIGHTS,
		modalTitle: 'Виберіть права',
		isRolesOrRights: true
	},
	{
		title: 'Статус',
		key: ROLE_DETAILS_KEYS.STATUS,
		modalTitle: 'Оберіть статус',
	},

];
