import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';
import s from './index.module.scss';

export const CardWrapper: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
	children,
	className,
	...props
}) => {
	return (
		<div className={classNames(s.card, className)} {...props}>
			{children}
		</div>
	);
};
