import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IRejectedDataRequest, IRejectedDataResponse } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { RejectedDataService } from "utils/services/rejected.service";

export const getRejectedDataList = createAsyncThunk(
	'specifyDataSlice/getRejectedDataList',
	async (reqBody: IRejectedDataRequest, { rejectWithValue }) => {
		try {
			const rejectedDataListResponse: AxiosResponse<IRejectedDataResponse> = await RejectedDataService.getRejectedDataList(reqBody);
			return rejectedDataListResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
