import { createSlice } from '@reduxjs/toolkit';
import { IAddressesReducer } from 'types';
import { getAddressById, getAllAddresses } from './actions';

const initialState: IAddressesReducer = {
	isLoading: true,
	allAddresses: null,
	addressDetails: null,
};

const addressesSlice = createSlice({
	name: 'addressesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getAllAddresses.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getAllAddresses.fulfilled, (state, { payload }) => {
			state.allAddresses = payload;
			state.isLoading = false;
		});
		builder.addCase(getAllAddresses.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(getAddressById.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getAddressById.fulfilled, (state, { payload }) => {
			const { id, address: { street: { city, name: streetName, id: streetId, streetType: { name, id: streetTypeId } }, houseNumber }, flatNumber } = payload;
			state.isLoading = false;
			state.addressDetails = {
 id, city: city.name, flat: flatNumber, street: streetName, streetType: name, house: houseNumber, streetId, streetTypeId
};
		});
		builder.addCase(getAddressById.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const addressesReducer = addressesSlice.reducer;
