import { ROUTES } from "routes";
import { Rights } from "utils/enums/rights.enum";

type RoutePermissionsMap = {
    [key in ROUTES]?: Rights;
}

export const ROUTES_PERMISSIONS: RoutePermissionsMap = {
    [ROUTES.users]: Rights.USERS_READ,
    [ROUTES.clients]: Rights.CLIENTS_READ,
    [ROUTES.services]: Rights.SERVICES_READ,
    [ROUTES.providers]: Rights.PROVIDERS_READ,
    [ROUTES.categories]: Rights.CATEGORIES_READ,
    [ROUTES.addresses]: Rights.APARTMENTS_READ,
    [ROUTES.streets]: Rights.STREETS_READ,
    [ROUTES.roles]: Rights.ROLES_READ,
    [ROUTES.rolesDetails]: Rights.ROLES_READ,
    [ROUTES.rights]: Rights.RIGHTS_READ,
    [ROUTES.streetsType]: Rights.STREET_TYPES_READ,
    [ROUTES.rejectedData]: Rights.SPECIFY_DATA_READ,
    // <Route path={ROUTES.profileSetups} element={<UserProfileDetails />} />
    [ROUTES.addressDetails]: Rights.APARTMENTS_READ,
    [ROUTES.usersDetails]: Rights.USERS_READ,
    [ROUTES.usersDetailsActivity]: Rights.USERS_READ,
    [ROUTES.serviceDetails]: Rights.SERVICES_READ,
    [ROUTES.singleReceipts]: Rights.SINGLE_RECEIPTS_READ,
    [ROUTES.singleReceiptDetails]: Rights.SINGLE_RECEIPTS_READ,
    [ROUTES.otherReceipts]: Rights.OTHER_RECEIPTS_READ,
    [ROUTES.dataSettings]: Rights.DATA_SETTINGS_READ,
    [ROUTES.reportData]: Rights.REPORTS_READ,
};
