import * as Yup from 'yup';

export interface IResetPasswordFormValues {
	password: string;
	confirmPassword: string;
}

export const initialValues: IResetPasswordFormValues = {
	password: '',
	confirmPassword: '',
};

export const validationSchema = Yup.object({
	password: Yup.string().required('New password is required').min(6, 'Password must be at least 6 characters long'),
	confirmPassword: Yup.string()
		.required('Confirm password is required')
		.oneOf([Yup.ref('password')], 'Passwords do not match'),
});
