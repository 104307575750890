import { ROUTES } from 'routes';
import { ROUTE_TITLES } from 'utils/constants/page-titles';

export const getPageTitle = (pageTitle: string) => {
	return pageTitle ? `${pageTitle} - HOST Admin` : 'HOST Admin';
};

const prepareRoutePatterns = () => {
	return Object.values(ROUTES).map((route) => {
		const routePattern = route.startsWith('/') ? route.slice(1) : route;

		const routeRegex = new RegExp('^' + routePattern.replace(/:[^\s/]+/g, '[^/]+') + '$');
		return { regex: routeRegex, title: ROUTE_TITLES[route] ?? 'Сторінку не знайдено' };
	});
};

// once generated array with regexp of page routes
export const ROUTE_PATTERNS = prepareRoutePatterns();

export const getRouteTitle = (pathname: string) => {
	const pathnameWithoutSlash = pathname.startsWith('/') ? pathname.slice(1) : pathname;
	const matchedRoute = ROUTE_PATTERNS.find((route) => route.regex.test(pathnameWithoutSlash));
	return matchedRoute ? matchedRoute.title : 'Сторінку не знайдено';
};
