import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DataGrid, GridPaginationModel, GridSortDirection, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ROUTES } from "routes";
import {
	clearClientsData,
	createClient,
	getClients,
	getClientsIsLoadingSelector,
	getClientsSelector
} from 'store/clients';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ICreateClientRequest, IQuery } from 'types';
import { CreateModal } from "components/elements";
import { AddClient } from "components/modules/AddClient";
import { CREATE_CLIENT, PAGINATION_LIMIT } from 'utils/constants';
import { CLIENTS_FIELDS_NAME, DATE_FORMAT, SORT_DIR } from 'utils/enums';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { formatDateView } from 'utils/helpers/date';
import { getTableSetting } from "utils/helpers/table-setting";
import { createClientSchema } from "utils/validation";
import { COLUMNS_CONFIG } from './config';
import s from './index.module.scss';

export const ClientsPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const clients = useAppSelector(getClientsSelector);
	const isLoading = useAppSelector(getClientsIsLoadingSelector);
	const [columnWidths, setColumnWidths] = useState({});
	const apiRef = useGridApiRef();

	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const requestBody = useMemo(
		() => ({
			pagination: {
				offset: (query.page || 0) * (query.pageSize || 0),
				limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			},
			sorting: {
				field: query.field || CLIENTS_FIELDS_NAME.NAME,
				direction: query.sort || SORT_DIR.ASC,
			},
			search: `${query.searchQuery || ''}`,
		}),
		[query]
	);

	useEffect(() => {
		dispatch(getClients(requestBody));
	}, [requestBody, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearClientsData());
		};
	}, [dispatch]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.clients, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.clients));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const modifiedClientsData = useMemo(
		() =>
			clients?.results.map((client) => ({
				...client,
				updatedAt: formatDateView(client.updateAt, DATE_FORMAT.DAY_MONTH_NAME_YEAR_TIME),
			})),
		[clients]
	);

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const submitCreateRight = (data: ICreateClientRequest) => {
		dispatch(createClient(data));
		dispatch(getClients(requestBody));
	}

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	return (
		<>
			<Box className={s.tableWrapper}>
				<DataGrid
                    apiRef={apiRef}
                    onColumnResize={handleColumnResize}
					disableColumnFilter
					disableRowSelectionOnClick
					rows={modifiedClientsData || []}
					columns={COLUMNS_CONFIG}
					rowCount={clients?.total || 0}
					loading={isLoading}
					initialState={{
						pagination: {
							paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
						},
						sorting: {
							sortModel: [{ field: query.field || CLIENTS_FIELDS_NAME.ID, sort: query.sort as GridSortDirection }],
						},
					}}
					slotProps={{
						pagination: {
							labelRowsPerPage: 'Показувати :',
							page: query.page || 0,
							rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
						},
					}}
					paginationMode="server"
					sortingMode="server"
					onSortModelChange={handleSortField}
					onPaginationModelChange={handleChangePage}
					className={s.table}
					pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
				/>
			</Box>
			<CreateModal<ICreateClientRequest>
				title="Новий клієнт"
				onSubmit={submitCreateRight}
				initialValues={CREATE_CLIENT}
				subTitle="Вкажіть дані нижче"
				validationSchema={createClientSchema}
			><AddClient /></CreateModal>
		</>
	);
};
