import { isDeepEqual } from './is-deep-equal';
import { isObject } from './is-object';

export const getChangedValues = <T extends object>(previousValues: T, newValues: T) => {
	const newObjectEntries = Object.entries(newValues) as [keyof T, T[keyof T]][];

	return newObjectEntries.reduce((acc, [key, value]) => {
		const previous = previousValues[key];

		if (isObject(previous) && isObject(value)) {
			const isEqual = isDeepEqual(previous as object, value as object);

			return isEqual ? acc : { ...acc, [key]: value };
		}

		return previous === value ? acc : { ...acc, [key]: value };
	}, {} as Partial<T>);
};
