import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
	ICreateRightResponse,
	IUpdateRightRequest,
} from "types/roles-and-rights.interface";
import { getErrorResponse } from "utils/helpers";
import { RightsService } from "utils/services/rights.service";

//TODO CHANGE

export const getRightDetails = createAsyncThunk(
	'rightSlice/getRight',
	async (reqBody: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<any> = await RightsService.getRight(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateRightDetails = createAsyncThunk(
	'rightSlice/updateRightDetails',
	async (reqBody: IUpdateRightRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<ICreateRightResponse> = await RightsService.updateRight(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const deleteRight = createAsyncThunk(
	'rightSlice/updateRightDetails',
	async (reqBody: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<any> = await RightsService.deleteRight(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
