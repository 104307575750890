// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StreetsPage_tableWrapper__HiGAB {
  height: 100%;
  width: 100%;
}

.StreetsPage_oddRow__GAgaf {
  background-color: var(--palette-background-default);
}`, "",{"version":3,"sources":["webpack://./src/pages/StreetsPage/index.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,WAAA;AACD;;AAEA;EACC,mDAAA;AACD","sourcesContent":[".tableWrapper {\n\theight: 100%;\n\twidth: 100%;\n}\n\n.oddRow {\n\tbackground-color: var(--palette-background-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": `StreetsPage_tableWrapper__HiGAB`,
	"oddRow": `StreetsPage_oddRow__GAgaf`
};
export default ___CSS_LOADER_EXPORT___;
