import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { IUserActivityItem } from 'types';

export const selectUserActivityRowsTotal = (store: RootState): number =>
	store.userActivity.userActivity?.listData.totalRows ?? 0;

const getUserActivityRows = (store: RootState): IUserActivityItem[] | undefined =>
	store.userActivity.userActivity?.tableData;

export const selectUserActivityRows = createSelector(getUserActivityRows, (rows) => rows);
