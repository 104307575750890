import { createSlice } from '@reduxjs/toolkit';
import { IUsersReducer } from 'types';
import {
	createUser,
	deleteCardById,
	getApartment,
	getCardList,
	getUserById,
	getUsersList,
	updateUserById
} from './actions';

const initialState: IUsersReducer = {
	isLoading: true,
	allUsers: null,
	userDetails: null,
	userDetailsApartment: null,
	userCardList: [],
};

const usersSlice = createSlice({
	name: 'usersSlice',
	initialState,
	reducers: {
		clearUsersData: (state) => {
			state.allUsers = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUsersList.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getUsersList.fulfilled, (state, { payload }) => {
			state.allUsers = payload;
			state.isLoading = false;
		});
		builder.addCase(getUsersList.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(getUserById.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getUserById.fulfilled, (state, { payload }) => {
			state.userDetails = payload;
			state.isLoading = false;
		});

		builder.addCase(getUserById.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(updateUserById.fulfilled, (state, { payload }) => {
			state.isLoading = false;
		});

		builder.addCase(updateUserById.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(updateUserById.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(createUser.fulfilled, (state, { payload }) => {
			state.isLoading = false;
		});

		builder.addCase(createUser.rejected, (state, { payload }) => {
			state.isLoading = false;
		});

		builder.addCase(createUser.pending, (state, { payload }) => {
			state.isLoading = true;
		});
		builder.addCase(getApartment.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getApartment.fulfilled, (state, { payload }) => {
			state.userDetailsApartment = payload;
			state.isLoading = false;
		});
		builder.addCase(getApartment.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getCardList.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getCardList.fulfilled, (state, { payload }, ) => {
			state.userCardList = payload;
			state.isLoading = false;
		});
		builder.addCase(getCardList.rejected, (state) => {
			state.isLoading = false;
		})
		builder.addCase(deleteCardById.fulfilled, (state, { payload }) => {
			if (state.userCardList !== null) {
				state.userCardList = state.userCardList.filter((item)=> item.cardId !== payload.cardId)
			}
		});
	},
});

export const { clearUsersData } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
