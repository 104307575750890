import { IPagination } from 'types/common.interface';

export enum PAGINATION_LIMIT {
	SMALL = 15,
	MIDDLE = 25,
	BIG = 50,
}

export const PAGINATION_LIMIT_OPTIONS: PAGINATION_LIMIT[] = [
	PAGINATION_LIMIT.SMALL,
	PAGINATION_LIMIT.MIDDLE,
	PAGINATION_LIMIT.BIG,
];

export const DEFAULT_PAGINATION: IPagination = {
	offset: 0,
	limit: PAGINATION_LIMIT.MIDDLE,
};
