import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	IGenerateReportsRequest,
	IGenerateReportsResponse,
	IReportData,
	IReportsDataList,
	IReportsRequest,
} from 'types';
import { getErrorResponse } from 'utils/helpers';
import { ReportsService } from 'utils/services';

export const generateReports = createAsyncThunk(
	'reportsSlice/generateReports',
	async (reqBody: IGenerateReportsRequest, { rejectWithValue }) => {
		try {
			const generateReportsResponse: AxiosResponse<IGenerateReportsResponse> = await ReportsService.generateReports(
				reqBody
			);
			return generateReportsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getReportsList = createAsyncThunk(
	'reportsSlice/getReportsList',
	async (requestBody: IReportsRequest, { rejectWithValue }) => {
		try {
			const reportsResponse: AxiosResponse<IReportData[]> = await ReportsService.getReportsList(requestBody);
			return reportsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const deleteReportById = createAsyncThunk(
	'reportsSlice/deleteReport',
	async (id: number, { rejectWithValue }) => {
		try {
			await ReportsService.deleteReportById(id);
			return id;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getReportListById = createAsyncThunk(
	'reportsSlice/getReportList',
	async (id: number, { rejectWithValue }) => {
		try {
      const reportsResponseList: AxiosResponse<IReportsDataList[]> = await ReportsService.getReportListById(id);
      return reportsResponseList.data;
    } catch (error) {
      return rejectWithValue(getErrorResponse(error));
    }
	}
)
