import { createSelector } from '@reduxjs/toolkit';
import { IOption } from 'components/elements';
import { RootState } from 'store';
import { IStreetsResponse } from 'types';

export const getStreetsTypesSelector = (store: RootState): IStreetsResponse | null => store.streetTypes.data || null;

export const getStreetTypesIsLoadingSelector = (store: RootState): boolean => store.streetTypes.isLoading;

export const getStreetTypeOptions = (store: RootState): IOption[] => {
	const streetTypes = getStreetsTypesSelector(store);

	return streetTypes ? streetTypes.results.map(({ name, id }) => ({ title: name, value: id })) : [];
};

export const getStreetTypeOptionsSelector = createSelector(getStreetTypeOptions, (options) => options);