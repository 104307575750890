import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IClientsRequest, IClientsResponse, ICreateClientRequest } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { ClientsService } from 'utils/services';

export const getClients = createAsyncThunk(
	'clientsSlice/getClients',
	async (reqBody: IClientsRequest, { rejectWithValue }) => {
		try {
			const clientsResponse: AxiosResponse<IClientsResponse> = await ClientsService.getClients(reqBody);
			return clientsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const createClient = createAsyncThunk(
	'clientsSlice/createClient',
    async (reqBody: ICreateClientRequest, { rejectWithValue }) => {
        try {
            const clientsResponse: AxiosResponse = await ClientsService.createClient(reqBody);
            return clientsResponse.data;
        } catch (error) {
            return rejectWithValue(getErrorResponse(error));
        }
    }
)
