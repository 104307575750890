import { FC, memo } from 'react';
import { FormattedJSONComponent } from 'components/elements/FormattedJSON';
import { CellWithModal } from '../CellWithModal';

interface FormattedJSONCellProps {
	formattedValue: string;
	headerName?: string;
}

export const FormattedJSONCell: FC<FormattedJSONCellProps> = memo(({ formattedValue, headerName = '' }) => (
	<CellWithModal formattedValue={formattedValue} columnName={headerName}>
		<FormattedJSONComponent value={formattedValue} />
	</CellWithModal>
));
