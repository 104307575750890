export const REJECTED_DATA_HEADER_TITLES = {
	CITY: 'Населений пункт',
	STREET_TYPE: 'Типи вулиць',
	STREET: 'Вулиця',
	HOUSE: 'Будинок',
	FLAT: 'Квартира',
	SERVICE_CODE: 'Код сервісу',
	SERVICES_NAME: 'Сервіс',
	PROVIDER: 'Провайдер',
	REASON: 'Причина',
	PROVIDER_ID: 'Особовий рахунок',
	CREATED_AT: 'Дата створення'
};
