import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { ReactComponent as HostMain } from 'assets/images/host-main.svg';
import { ROUTES } from "routes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserProfileSelector, userLogout } from "store/user";
import styles from './index.module.scss';

export const ForbiddenPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const profile = useAppSelector(getUserProfileSelector);

    useEffect(() => {
        if (!profile) {
            navigate(`/${ROUTES.login}`);
        }
    }, [profile, navigate])

    const logout = () => {
        if (profile) {
            dispatch(userLogout({ userId: profile.userId }));
        }
    }
    return (
        <div className={styles.layout}>
            <Box className={styles.card}>
                <Stack className={styles.content}>
                    {/* <Link to='/'> */}
                    <HostMain className={styles.clickable} onClick={() => logout()} />
                    {/* </Link> */}
                    <Typography variant="h3" className={styles.content__title}>
                        У доступі відмовлено!
                    </Typography>
                    <Typography variant="body1">
                        <p>Нажаль у вас нема доступу до означеної сторінки.</p>
                        <p>Будь ласка, зверніться до адміністрації, або спробуйте інший аккаунт.</p>
                    </Typography>
                </Stack>
            </Box>
        </div>);
}
