import { DataGrid, GridPaginationModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import { PAGINATION_LIMIT } from 'utils/constants';
import { SORT_DIR, USER_ACTIVITY_FIELD_NAME } from 'utils/enums';
import { IUserActivityItem } from 'types';
import { useMemo } from 'react';
import { COLUMNS_CONFIG } from './config';
import { useResizeDataGridColumn } from 'utils/hooks';
import { ROUTES } from 'routes';

interface UserActivityTableProps<T> {
	rows?: T[];
	total: number;
	isLoading: boolean;
	onPageChange?: (data: GridPaginationModel) => void;
	onSortTable: (data: GridSortModel) => void;
	page?: number;
	limit?: number;
	sortBy?: string;
	sortDirection?: SORT_DIR;
	pageRoute: ROUTES;
}

export const UserActivityTable = <T extends IUserActivityItem>({
	rows = [],
	total = 0,
	isLoading,
	onPageChange,
	onSortTable,
	page = 0,
	limit = PAGINATION_LIMIT.SMALL,
	sortBy = USER_ACTIVITY_FIELD_NAME.DATE,
	sortDirection,
	pageRoute,
}: UserActivityTableProps<T>) => {
	const apiRef = useGridApiRef();

	const initialState = useMemo(
		() => ({
			pagination: {
				paginationModel: { page, pageSize: limit, rowCount: total },
			},
			sorting: {
				sortModel: [{ field: sortBy, sort: sortDirection }],
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const { handleColumnResize, columns } = useResizeDataGridColumn(pageRoute, COLUMNS_CONFIG);

	return (
		<DataGrid
			apiRef={apiRef}
			disableColumnFilter
			disableRowSelectionOnClick
			rows={rows}
			rowCount={total}
			loading={isLoading}
			onColumnResize={handleColumnResize}
			columns={columns}
			initialState={initialState}
			slotProps={{
				pagination: {
					labelRowsPerPage: 'Показувати :',
					page,
					rowsPerPage: limit,
				},
			}}
			paginationMode="server"
			sortingMode="server"
			onSortModelChange={onSortTable}
			onPaginationModelChange={onPageChange}
			pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
		/>
	);
};
