import { createSlice } from '@reduxjs/toolkit';
import { IClientsReducer } from 'types';
import { getClients } from './actions';

const initialState: IClientsReducer = {
	isLoading: true,
	data: null,
};

const clientsSlice = createSlice({
	name: 'clientsSlice',
	initialState,
	reducers: {
		clearClientsData: (state) => {
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getClients.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getClients.fulfilled, (state, { payload }) => {
			state.data = payload;
			state.isLoading = false;
		});
		builder.addCase(getClients.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { clearClientsData } = clientsSlice.actions;
export const clientsReducer = clientsSlice.reducer;
