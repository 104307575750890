import { HTMLProps } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import icon from '../../assets/icons/edit.svg';

const StyledBoxImg = styled(Box)<HTMLProps<HTMLImageElement>>(({ theme }) => ({
	height: '14px',
	width: '14px',
	top: '2px',
	right: '2px',
	position: 'absolute',
}));

const StyledBoxText = styled(Box)(({ theme }) => ({
	marginTop: '10px',
}));

export const useColumns = (config: GridColDef[]) => {
	return config.map((column) => ({
		...column,
		renderCell: (params: any) => {
			if (column.editable) {
				return (
					<Box>
						<StyledBoxText className="text">{params.value}</StyledBoxText>
						<StyledBoxImg component="img" src={icon} alt="Edit" />
					</Box>
				);
			}
				return params.value;

		},
	}));
};

