import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ICategoriesDetails, ICategoriesRequest, ICategoriesRequestSN, ICategoriesResponse } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { CategoriesService } from 'utils/services';

export const getCategories = createAsyncThunk(
	'categoriesSlice/getCategories',
	async (reqBody: ICategoriesRequest, { rejectWithValue }) => {
		try {
			const categoriesResponse: AxiosResponse<ICategoriesResponse> = await CategoriesService.getCategories(reqBody);
			return categoriesResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateCategoriesSN = createAsyncThunk(
	'categoriesSlice/updateCategoriesSN',
	async (reqBody: ICategoriesRequestSN, { rejectWithValue }) => {
		try {
      const updateRowResponse: AxiosResponse<ICategoriesDetails> = await CategoriesService.updateCategoriesSN(reqBody)
			return updateRowResponse.data;
    } catch (error) {
      return rejectWithValue(getErrorResponse(error));
    }
	}
)
