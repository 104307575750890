import { ReactNode } from 'react';
import {
 Box, Button, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Typography
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Form, Formik, FormikValues } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeModalOpen, getModalState } from 'store/system';
import { Schema } from 'yup';
import s from './index.module.scss';

interface CreateModalProps<T> extends Omit<DialogProps, 'open' | 'onClose' | 'onSubmit'> {
	title: string;
	subTitle: string;
	onSubmit: (values: T) => void;
	onClose?: () => void;
	children: ReactNode;
	initialValues: FormikValues;
	validationSchema: Schema;
}

export const CreateModal = <T,>({
	title,
	subTitle,
	onSubmit,
	children,
	initialValues,
	validationSchema,
	onClose,
	...props
}: CreateModalProps<T>) => {
	const dispatch = useAppDispatch();

	const isOpen = useAppSelector(getModalState);

	const handleClose = () => {
		dispatch(changeModalOpen());
		onClose?.();
	};

	const onSubmitCreate = (values: FormikValues) => {
		onSubmit(values as T);
		handleClose();
	};

	return (
		<Dialog open={isOpen} onClose={handleClose} className={s.dialog} {...props}>
			<DialogTitle>
				{title}
				<IconButton onClick={handleClose} className={s.dialog__close}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<Typography variant="body2">{subTitle}</Typography>
			<DialogContent>
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmitCreate}
					validationSchema={validationSchema}
					validateOnBlur
					validateOnMount
				>
					{(formik) => (
						<Form>
							<Box className={s.wrapper}>
								<Box className={s.wrapper}>{children}</Box>
								<Button sx={{ mt: '26px' }} variant="rounded" disabled={!formik.isValid || !formik.dirty} type="submit">
									Додати
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Dialog>
	);
};
