import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { addressesReducer } from './addresses';
import { AliasesByProviderReducer } from './aliases-by-provider';
import { servicesReducer } from './bill-services';
import { categoriesReducer } from './categories';
import { clientsReducer } from './clients';
import { dataSettingsReducer } from './data-settings';
import { otherReceiptsReducer } from './other-receipts';
import { paymentsReducer } from './payment';
import { providersReducer } from './providers';
import { rejectedDataReducer } from './rejected-data';
import { reportsReducer } from './reports';
import { rightDetailsReducer } from './right';
import { rightsReducer } from './rights-list';
import { roleDetailsReducer } from './role';
import { rolesReducer } from './roles-list';
import { singleReceiptsReducer } from './single-receipts';
import { streetsReducer } from './streets';
import { streetsTypesReducer } from './streets-types';
import { systemReducer } from './system';
import { userReducer } from './user';
import { usersReducer } from './users-list';
import { userActivityReducer } from './user-activity';

export const store = configureStore({
	reducer: {
		user: userReducer,
		users: usersReducer,
		userActivity: userActivityReducer,
		clients: clientsReducer,
		categories: categoriesReducer,
		services: servicesReducer,
		providers: providersReducer,
		addresses: addressesReducer,
		aliasesByProvider: AliasesByProviderReducer,
		streets: streetsReducer,
		streetTypes: streetsTypesReducer,
		rejectedData: rejectedDataReducer,
		system: systemReducer,
		reports: reportsReducer,
		dataSettings: dataSettingsReducer,
		singleReceipts: singleReceiptsReducer,
		otherReceipts: otherReceiptsReducer,
		roles: rolesReducer,
		rights: rightsReducer,
		roleDetails: roleDetailsReducer,
		rightDetails: rightDetailsReducer,
		payments: paymentsReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
