import { FC } from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ICreateUserFormik } from 'types';

export const AddClient: FC = () => {
	const { getFieldProps } = useFormikContext<ICreateUserFormik>();

	return (
		<>
			<TextField
				variant="outlined"
				label="Ім’я"
				{...getFieldProps('name')}
			/>
			<TextField
				variant="outlined"
				label="Кодове імя"
				{...getFieldProps('codeName')}
			/>
			<TextField
				variant="outlined"
				label="Посилання на сайт клієнта"
				{...getFieldProps('siteURL')}
			/>
		</>
	);
};
