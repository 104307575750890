import { FC } from "react";
import { FieldInputProps, useField } from "formik";
import { CustomDatePicker, CustomDatePickerProps } from "../CustomDatePicker";
import dayjs from "dayjs";

interface CustomDatePickerFormikProps extends CustomDatePickerProps {
    field: FieldInputProps<string>
}

export const CustomDatePickerFormik: FC<CustomDatePickerFormikProps> = ({ field, ...props }) => {
    const [, , { setValue }] = useField(field)

    const handleChangeDate = (value: Date | null) => {
        setValue(dayjs(value))
    }
    return <CustomDatePicker {...props} {...field}  onChange={handleChangeDate} />
}
