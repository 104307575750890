import { createSlice } from '@reduxjs/toolkit';
import { dataSettings, getStatusSingleReceipt, uploadSingleReceiptStatus } from './actions';

const dataSettingsSlice = createSlice({
	name: 'dataSettings',
	initialState: {
		isLoading: false,
		singleReceiptStatus: false,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(dataSettings.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(dataSettings.fulfilled, (state) => {
				state.isLoading = false;
			})
			.addCase(dataSettings.rejected, (state) => {
				state.isLoading = false;
			})
			.addCase(getStatusSingleReceipt.fulfilled, (state, { payload }) => {
				state.singleReceiptStatus = payload.valueOf();
			})
			.addCase(uploadSingleReceiptStatus.fulfilled, (state) => {
				state.singleReceiptStatus = !state.singleReceiptStatus;
			});
	},
});

export const dataSettingsReducer = dataSettingsSlice.reducer;
