import { createSlice } from '@reduxjs/toolkit';
import { IPaymentsReducer } from 'types';
import { getPaymentBillInfo, getPaymentList } from "./actions";

const initialState: IPaymentsReducer = {
	paymentList: null,
	paymentInfo: null,
	isLoading: true,
};

const paymentsSlice = createSlice({
	name: 'paymentSlice',
	initialState,
	reducers: {
		clearPaymentsData: (state) => {
			state.paymentList = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPaymentList.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getPaymentList.fulfilled, (state, { payload }) => {
			state.paymentList = payload;
			state.isLoading = false;
		});
		builder.addCase(getPaymentList.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getPaymentBillInfo.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getPaymentBillInfo.fulfilled, (state, { payload }) => {
			state.paymentInfo = payload;
			state.isLoading = false;
		});
		builder.addCase(getPaymentBillInfo.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { clearPaymentsData } = paymentsSlice.actions;

export const paymentsReducer = paymentsSlice.reducer;
