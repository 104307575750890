import { FC } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/question.svg';

interface ICustomTooltip {
	title: string;
}

export const CustomTooltip: FC<ICustomTooltip> = ({ title }) => {
	return (
		<Tooltip title={title} placement="top-start">
			<IconButton>
				<InfoIcon />
			</IconButton>
		</Tooltip>
	);
};
