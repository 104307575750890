import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Link, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ROUTES } from 'routes';
import { useAppDispatch } from 'store/hooks';
import { userLogin } from 'store/user';
import { IUserLoginRequest } from 'types';
import styles from './index.module.scss';

export const LoginForm: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [phone, setPhone] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [authError, setError] = useState<string>('');

	const formatPhoneNumber = (inputPhoneNumber: string): string => {
		let formattedPhoneNumber = inputPhoneNumber;

		if (inputPhoneNumber.startsWith('+380')) {
			formattedPhoneNumber = inputPhoneNumber.replace('+380', '380');
		} else if (inputPhoneNumber.startsWith('0')) {
			formattedPhoneNumber = `380${  inputPhoneNumber.substring(1)}`;
		}
		return formattedPhoneNumber;
	};

	const handleSubmit = async (): Promise<void> => {
		const formattedPhone = formatPhoneNumber(phone);
		const reqBody: IUserLoginRequest = {
			password,
			...(formattedPhone && { phone: formattedPhone }),
			...(email && { email }),
		};
		try {
			await dispatch(userLogin(reqBody)).unwrap();
			navigate('/');
		} catch (error) {
			console.log(error);
			setError('Невірні пошта/телефон або пароль');
		}
	};

	return (
		<>
			<Box className={styles.wrapper}>
				<Box className={styles.form}>
					<TextField
						variant="outlined"
						value={phone || email}
						label="Номер телефону або пошта"
						onChange={(event) => {
							const inputValue = event.target.value;
							if (inputValue.includes('@')) {
								setEmail(inputValue);
								setPhone('');
							} else {
								setPhone(inputValue);
								setEmail('');
							}
						}}
					/>
					<TextField
						sx={{
							'& input[type="password"]': {
								fontFamily: 'Verdana',
								letterSpacing: '0.15px',
							},
						}}
						name="password"
						variant="outlined"
						value={password}
						type="password"
						label="Пароль"
						onChange={(event) => setPassword(event.target.value)}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								handleSubmit();
							}
						}}
					/>
				</Box>
				{authError && (
					<Typography variant="body2" color="error" position="absolute" bottom={50}>
						{authError}
					</Typography>
				)}
				<Button
					variant="rounded"
					className={styles.action}
					onClick={handleSubmit}
					disabled={!((phone || email) && password)}
				>
					Продовжити
				</Button>
			</Box>
			<Box className={styles.forgotPassword}>
				<Typography variant="body2" color="common.textLightGray">
					Забули пароль?
				</Typography>
				<Link href={ROUTES.resetPassword} sx={{ textDecoration: 'none' }}>
					Відновити
				</Link>
			</Box>
		</>
	);
};
