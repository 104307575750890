import { createSlice } from '@reduxjs/toolkit';
import { ISystemReducer } from 'types/system.interface';

const initialState: ISystemReducer = {
	isModalOpen: false,
};

const systemSlice = createSlice({
	name: 'systemSlice',
	initialState,
	reducers: {
		changeModalOpen: (state) => {
			state.isModalOpen = !state.isModalOpen;
		},
	},
	extraReducers: (builder) => {},
});

export const { changeModalOpen } = systemSlice.actions;
export const systemReducer = systemSlice.reducer;
