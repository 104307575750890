import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IGetUserProfileResponse, IUpdateUserProfileRequest } from 'types';
import { API_URL } from 'utils/enums';

class UserProfileService {
	static getUserProfile = async (userId: string | null): Promise<AxiosResponse<IGetUserProfileResponse>> =>
		API.post(API_URL.USER_PROFILE_GET, { userId });

	static updateUserProfile = async (
		reqBody: IUpdateUserProfileRequest
	): Promise<AxiosResponse<IGetUserProfileResponse>> => API.post(API_URL.USER_PROFILE_UPDATE, reqBody);

	// TODO:  endpoint for delete profile
	// static deleteUserProfile = async (userId: string): Promise<AxiosResponse<IUserProfileResponse>> =>
	// 	API.post(API_URL.USER_PROFILE_DELETE, { userId });
}

export { UserProfileService };
