export enum ParsingStages {
	parsing = 'parsing',
	stopping = 'stopping',
}

export enum ParsingStatus {
	InProgress = 'InProgress',
	Completed = 'Completed',
	Stopping = 'Stopping',
}

export enum EventsStatus {
	InProgress = 'InProgress',
	Completed = 'Completed',
}

export enum StepOfCurrentParser {
	ETL_INIT_REDIS = 'ETL_INIT_REDIS',
	ETL_FILES_UPLOAD = 'ETL_FILES_UPLOAD',
	ETL_FILES_PARSING = 'ETL_FILES_PARSING',
	ETL_UNDEFINED_DATA = 'ETL_UNDEFINED_DATA',
	BILL_CREATE_SINGLE_RECEIPT = 'BILL_CREATE_SINGLE_RECEIPT',
	REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT = 'REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT',
}

export enum StepOfParserWithStopping {
	ETL_FILES_PARSING = 'ETL_FILES_PARSING',
	ETL_UNDEFINED_DATA = 'ETL_UNDEFINED_DATA',
	BILL_CREATE_SINGLE_RECEIPT = 'BILL_CREATE_SINGLE_RECEIPT',
	REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT = 'REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT',
}
