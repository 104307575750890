import { FC } from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ICreateUserFormik } from 'types';
import { CustomSelect } from 'components/elements';
import { USER_ROLE_OPTIONS } from 'utils/constants';

export const AddUser: FC = () => {
	const { getFieldProps, errors, touched } = useFormikContext<ICreateUserFormik>();

	return (
		<>
			<TextField
				variant="outlined"
				label="Ім’я"
				{...getFieldProps('firstName')}
				helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
			/>
			<TextField
				variant="outlined"
				label="Прізвище"
				{...getFieldProps('lastName')}
				helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
			/>
			<TextField
				variant="outlined"
				label="Email"
				{...getFieldProps('email')}
				helperText={errors.email && touched.email ? errors.email : ''}
			/>
			<CustomSelect
				placeholder="Роль"
				options={USER_ROLE_OPTIONS}
				{...getFieldProps('type')}
				helperText={errors.type && touched.type ? errors.type : ''}
			/>
		</>
	);
};
