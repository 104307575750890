import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	IPaymentListRequest,
	IPaymentListResponse,
	IPaymentListResponseInCSV,
	IPaymentResponseInfo
} from "types/payment.interface";
import { getErrorResponse } from 'utils/helpers';
import { PaymentService } from "utils/services/payment.service";

export const getPaymentList = createAsyncThunk(
	'paymentSlice/getPaymentList',
	async (reqBody: IPaymentListRequest, { rejectWithValue }) => {
		try {
			const paymentsResponse: AxiosResponse<IPaymentListResponse> =
				await PaymentService.getPaymentList(reqBody);
			return paymentsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getPaymentListInCSV = createAsyncThunk(
	'paymentSlice/getPaymentListInCSV',
	async (reqBody: IPaymentListRequest, { rejectWithValue }) => {
		try {
			const paymentsResponse: AxiosResponse<IPaymentListResponseInCSV> =
				await PaymentService.getPaymentList(reqBody);
			return paymentsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getPaymentBillInfo = createAsyncThunk(
	'paymentSlice/getPaymentBillInfo',
    async (billId: string, { rejectWithValue }) => {
        try {
            const paymentBillInfoResponse: AxiosResponse<IPaymentResponseInfo> =
                await PaymentService.getPaymentBillInfo(billId);
            return paymentBillInfoResponse.data;
        } catch (error) {
            return rejectWithValue(getErrorResponse(error));
        }
    }
)
