import { GridRenderCellParams } from '@mui/x-data-grid';
import { FormattedJSONCell } from 'components/modules/FormattedJSONCell';
import dayjs from 'dayjs';
import { IUserActivityItem } from 'types';
import { USER_ACTIVITY_HEADER_TITLES } from 'utils/constants/user-activity';
import { USER_ACTIVITY_FIELD_NAME } from 'utils/enums';

export const COLUMNS_CONFIG = [
	{
		field: USER_ACTIVITY_FIELD_NAME.DATE,
		headerName: USER_ACTIVITY_HEADER_TITLES.DATE,
		flex: 0.5,
		valueGetter: (_: unknown, row: IUserActivityItem) => dayjs(row.createdAt).format('DD.MM.YYYY'),
	},
	{
		field: USER_ACTIVITY_FIELD_NAME.TIME,
		headerName: USER_ACTIVITY_HEADER_TITLES.TIME,
		flex: 0.4,
		valueGetter: (_: unknown, row: IUserActivityItem) => dayjs(row.createdAt).format('HH:mm:ss'),
		sortable: false,
	},
	{ field: USER_ACTIVITY_FIELD_NAME.METHOD, headerName: USER_ACTIVITY_HEADER_TITLES.METHOD, flex: 1, sortable: false },
	{
		field: USER_ACTIVITY_FIELD_NAME.REQUEST,
		headerName: USER_ACTIVITY_HEADER_TITLES.REQUEST,
		flex: 1.5,
		sortable: false,
		renderCell: (data: GridRenderCellParams) => {
			return <FormattedJSONCell formattedValue={data.formattedValue} headerName={data.colDef.headerName} />;
		},
	},
	{
		field: USER_ACTIVITY_FIELD_NAME.RESPONSE,
		headerName: USER_ACTIVITY_HEADER_TITLES.RESPONSE,
		flex: 2,
		sortable: false,
		renderCell: (data: GridRenderCellParams) => {
			return <FormattedJSONCell formattedValue={data.formattedValue} headerName={data.colDef.headerName} />;
		},
	},
];
