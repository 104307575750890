import { createSlice } from '@reduxjs/toolkit';
import { IServicesReducer } from 'types';
import { getServiceById, getServices, updateServiceById } from './actions';

const initialState: IServicesReducer = {
	isLoading: true,
	data: null,
	serviceDetails: null,
};

const servicesSlice = createSlice({
	name: 'servicesSlice',
	initialState,
	reducers: {
		clearServicesData: (state) => {
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getServices.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getServices.fulfilled, (state, { payload }) => {
			state.data = payload;
			state.isLoading = false;
		});
		builder.addCase(getServices.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getServiceById.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getServiceById.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getServiceById.fulfilled, (state, { payload }) => {
			state.serviceDetails = payload;
		});
		builder.addCase(updateServiceById.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateServiceById.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(updateServiceById.fulfilled, (state) => {
			state.isLoading = false;
		});
	},
});

export const { clearServicesData } = servicesSlice.actions;
export const servicesReducer = servicesSlice.reducer;
