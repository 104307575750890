import { createSlice } from '@reduxjs/toolkit';
import { IStreetsReducer } from 'types';
import { getStreets, updateStreets } from './actions';

const initialState: IStreetsReducer = {
	isLoading: true,
	data: null,
};

const streetsSlice = createSlice({
	name: 'streetsSlice',
	initialState,
	reducers: {
		clearStreetsData: (state) => {
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getStreets.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getStreets.fulfilled, (state, { payload }) => {
			state.data = payload;
			state.isLoading = false;
		});
		builder.addCase(getStreets.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(updateStreets.fulfilled, (state, { payload }) =>{ 
			if(state.data?.results) {
				state.data.results = state.data.results.map((street) => street.id === payload.id ? payload : street);
			}
		})
	},
});

export const { clearStreetsData } = streetsSlice.actions;
export const streetsReducer = streetsSlice.reducer;
