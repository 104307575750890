import { FC, useEffect, useRef } from 'react';
import { Box, CircularProgress } from '@mui/material';
import styles from './index.module.scss';

interface ObserverProps {
	isLoading?: boolean;
	onObserve: () => void;
}

export const Observer: FC<ObserverProps> = ({ onObserve, isLoading }) => {
	const observerRef = useRef(null);

	const observerCallback = (entries: Array<IntersectionObserverEntry>) => {
		entries.forEach((entry: IntersectionObserverEntry) => {
			if (entries[0].intersectionRatio <= 0) return;
			if (onObserve && entry.isIntersecting) {
				onObserve();
			}
		});
	};

	useEffect(() => {
		let observer: IntersectionObserver | null = null;

		if (observerRef.current) {
			const options = {
				threshold: 1,
			};

			observer = new IntersectionObserver(observerCallback, options);
			observer.observe(observerRef.current);
		}

		return () => {
			if (observerRef.current && observer) {
				observer.unobserve(observerRef.current);
			}
		};
	}, [observerRef.current]);

	return (
		<div className={styles.wrapper}>
			{!isLoading ? <Box className={styles.observer} ref={observerRef} /> : <CircularProgress />}
		</div>
	);
};
