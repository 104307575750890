import {
	AuditProcessesAvailableStatusEnum,
	AuditProcessesStatusEnum, IAuditGeneralProcessStatus,
	IAuditProcessesStatus, uploadFiles,
} from "socket/DataSettingsSocket/context";


export const isSomeProcessInProgress  = (processesStatus: IAuditProcessesStatus): boolean => {
	if (!processesStatus) {
		return true;
	}
	return Object.values(processesStatus).some((statusObj) => {
		return statusObj.status === AuditProcessesStatusEnum.IN_PROGRESS;
	})
};

export const checkStatusFile = (processesStatus: IAuditProcessesStatus, fieldName: uploadFiles): boolean => {
	if (!processesStatus.adminApiDirectUploadParsingFiles) {
		return true;
	}
		return processesStatus.adminApiDirectUploadParsingFiles.uploadFiles[fieldName].availableStatus !== AuditProcessesAvailableStatusEnum.ACTIVE;
}

export const checkStatusOption = (processesStatusObj?: IAuditGeneralProcessStatus): boolean => {
	if (!processesStatusObj) {
		return true;
	}
	return processesStatusObj.availableStatus !== AuditProcessesAvailableStatusEnum.ACTIVE;
}
