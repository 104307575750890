import { FC, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { ReactComponent as HostMain } from 'assets/images/host-main.svg';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { useAppDispatch } from 'store/hooks';
import { userPasswordRecovery } from 'store/user';
import { RECOVERY_TIME_MS } from 'utils/constants';
import { STORAGE_KEY } from 'utils/enums';
import { getDiffTimeString } from 'utils/helpers/date';
import { StorageService } from 'utils/services';
import { IForgotFormValues, infoWithEmailMask, initialValues, validationSchema } from './config';
import styles from './index.module.scss';

export const ForgotPasswordForm: FC = () => {
	const [emailSent, setEmailSent] = useState(false);
	const [errorMessage, setErrorMessage] = useState<boolean>(false);
	const [timeString, setTimeString] = useState('00:00');
	const [counter, setCounter] = useState(0);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const recoveryDate = StorageService.get(STORAGE_KEY.RECOVERY_DATE);
		let timeoutMs = RECOVERY_TIME_MS;

		if (recoveryDate) {
			const diff = RECOVERY_TIME_MS - dayjs().diff(dayjs(timeoutMs), 'ms');
			timeoutMs = diff <= 0 ? 0 : diff;
		}
		setCounter(timeoutMs);
	}, []);

	useEffect(() => {
		setTimeString(getDiffTimeString(counter));

		const timer = setTimeout(() => {
			setCounter(counter <= 0 ? 0 : counter - 1000);
		}, 1000);

		return () => clearTimeout(timer);
	}, [counter]);

	const handleResendRequest = (values: IForgotFormValues) => {
		dispatch(userPasswordRecovery(values.email));
		const recoveryDate = dayjs().toISOString();
		StorageService.set(STORAGE_KEY.RECOVERY_DATE, recoveryDate);
		setCounter(RECOVERY_TIME_MS);
	};

	const onSubmit = async (values: IForgotFormValues): Promise<void> => {
		try {
			const res = await dispatch(userPasswordRecovery(values.email));
			if (res.payload === 'Verification is not exist') {
				setErrorMessage(true);
			} else {
				setEmailSent(true);
				const recoveryDate = dayjs().toISOString();
				StorageService.set(STORAGE_KEY.RECOVERY_DATE, recoveryDate);
				setCounter(RECOVERY_TIME_MS);
			}
		} catch (error) {
			console.log('error :', error);
		}
	};

	return (
		<Box className={styles.wrapper}>
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
				{({ values, errors, touched }) => (
					<Form>
						<Box className={styles.form}>
							<HostMain />
							<Typography variant="h4" color="text.primary">
								Відновлення паролю
							</Typography>
							{emailSent ? (
								<>
									<Typography variant="body1" color="common.textLightGray" className={styles.info}>
										{infoWithEmailMask(values.email)}
									</Typography>

									<Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '12px' }}>
										<Typography variant="body2" color="common.textLightGray">
											Не отримали посилання?
										</Typography>
										{!counter ? (
											<Box sx={{ cursor: 'pointer' }} onClick={() => handleResendRequest(values)}>
												Надіслати повторно
											</Box>
										) : (
											<Box>Надіслати повторно {timeString}</Box>
										)}
									</Box>
								</>
							) : (
								<>
									{errorMessage ? (
										<Typography variant="body1" color="error" className={styles.error}>
											Користувача з такою поштою не знайдено
										</Typography>
									) : (
										<Typography variant="body1" color="common.textLightGray" className={styles.info}>
											Введіть пошту від вашого облікового запису
										</Typography>
									)}
									<Field as={TextField} type="Email" name="email" label="Email" error={errors.email && touched.email} />
									<Button variant="rounded" color="success" type="submit">
										Продовжити
									</Button>
								</>
							)}
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
