import { FC } from 'react';
import { Button, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { CustomSelectFormik } from 'components/elements';
import { useAppSelector } from 'store/hooks';
import { getStreetTypeOptionsSelector } from 'store/streets-types';
import { CITIES_OPTIONS } from 'utils/constants/cities';
import s from './index.module.scss';

interface StreetFormProps {
	buttonText: string;
	initialValues: any;
	onSubmit: (values: any) => void;
}

export const StreetForm: FC<StreetFormProps> = ({ buttonText, initialValues, onSubmit }) => {
	const streetTypeOptions = useAppSelector(getStreetTypeOptionsSelector);

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			{({ isValid, dirty }) => (
				<Form className={s.form}>
					<Field label="Назва вулиці" name="name" variant="outlined" as={TextField} />
					<Field label="Тип Вулиці" name="streetTypeId" options={streetTypeOptions} component={CustomSelectFormik} />
					<Field label="Населений пункт" name="cityId" options={CITIES_OPTIONS} component={CustomSelectFormik} />

					<Button className={s.button} type="submit" disabled={!isValid || !dirty} variant="rounded">
						{buttonText}
					</Button>
				</Form>
			)}
		</Formik>
	);
};
