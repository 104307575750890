import { API } from 'api';
import { AxiosResponse } from 'axios';
import { ICategoriesDetails, ICategoriesRequest, ICategoriesRequestSN, ICategoriesResponse } from 'types';
import { API_URL } from 'utils/enums';

class CategoriesService {
	static getCategories = async (reqBody: ICategoriesRequest): Promise<AxiosResponse<ICategoriesResponse>> =>
		API.post(API_URL.CATEGORIES_LIST_GET, reqBody);

	static updateCategoriesSN = async (reqBody: ICategoriesRequestSN): Promise<AxiosResponse<ICategoriesDetails>> =>
		API.post(API_URL.CATEGORIES_UPDATE_SN, reqBody);
}

export { CategoriesService };
