import React, { FC, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import {
	DataGrid,
	GridPaginationModel,
	GridRow,
	GridRowProps,
	GridSortDirection,
	GridSortModel,
	useGridApiRef
} from '@mui/x-data-grid';
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ROUTES } from "routes";
import {
	clearCategoriesData,
	getCategories,
	getCategoriesIsLoadingSelector,
	getCategoriesSelector,
	getUpdateRowSelector,
	updateCategoriesSN,
} from 'store/categories';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IQuery } from 'types';
import { PAGINATION_LIMIT } from 'utils/constants';
import { CATEGORIES_FIELDS_NAME, DATE_FORMAT, SORT_DIR } from 'utils/enums';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { formatDateView } from 'utils/helpers/date';
import { getTableSetting } from "utils/helpers/table-setting";
import { COLUMNS_CONFIG } from './config';
import s from './index.module.scss';

export const CategoriesPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const categories = useAppSelector(getCategoriesSelector);
	const updateRow = useAppSelector(getUpdateRowSelector);
	const isLoading = useAppSelector(getCategoriesIsLoadingSelector);

	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const [rows, setRows] = useState<any[]>([]);

	const requestBody = useMemo(
		() => ({
			pagination: {
				offset: (query.page || 0) * (query.pageSize || 0),
				limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			},
			sorting: {
				field: CATEGORIES_FIELDS_NAME.SEQUENCE_NUMBER,
				direction: query.sort || SORT_DIR.ASC,
			},
			search: `${query.searchQuery || ''}`,
		}),
		[query]
	);

	useEffect(() => {
		dispatch(getCategories(requestBody));
	}, [requestBody, dispatch, updateRow]);

	useEffect(() => {
		if (categories?.results) {
			setRows(
				categories.results.map((category) => ({
					...category,
					createdAt: formatDateView(category.createdAt, DATE_FORMAT.DATE_FULL),
				}))
			);
		}
	}, [categories]);

	useEffect(() => {
		return () => {
			dispatch(clearCategoriesData());
		};
	}, [dispatch]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.categories, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.categories));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const newRows = Array.from(rows);
		const [movedRow] = newRows.splice(result.source.index, 1);
		newRows.splice(result.destination.index, 0, movedRow);

		setRows(newRows);

		const body = {
			sequenceNumber: result.destination.index,
			serviceCategoryId: movedRow.id,
		};

		dispatch(updateCategoriesSN(body));
	};

	const CustomRowWrapper = ({ row, index, ...rest }: GridRowProps) => {

		return (
			<Draggable
				key={`row-${row.id}`}
				draggableId={`row-${row.id}`}
				index={index}
			>
				{(provided, snapshot) => (
					<GridRow
						index={index}
						row={row}
						{...rest}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						sx={{
							...provided.draggableProps.style,
						}}
					/>
				)}
			</Draggable>
		);
	};

	return (
		<Box className={s.tableWrapper}>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="droppable" direction="vertical">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<DataGrid
								onColumnResize={handleColumnResize}
								apiRef={apiRef}
								disableColumnFilter
								disableRowSelectionOnClick
								rows={rows}
								columns={COLUMNS_CONFIG}
								rowCount={categories?.total || 0} // Only used with server-side pagination
								loading={isLoading}
								paginationMode="server" // Ensure server-side pagination
								sortingMode="server"
								onSortModelChange={handleSortField}
								onPaginationModelChange={handleChangePage}
								className={s.table}
								pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
								initialState={{
									pagination: {
										paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
									},
									sorting: {
										sortModel: [{ field: query.field || CATEGORIES_FIELDS_NAME.ID, sort: query.sort as GridSortDirection }],
									},
								}}
								slots={{
									row: CustomRowWrapper,
								}}
							/>

							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Box>
	);
};
