import { FC, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import dayjs from 'dayjs';
import {
	AddressDetailsPage,
	AddressesPage,
	AliasesByProviderPage,
	CategoriesPage,
	ClientsPage,
	DataSettingsPage,
	ForgotPasswordPage,
	LoginPage,
	OtherReceiptsPage,
	PaymentPage,
	ProvidersPage,
	RejectedDataPage,
	ReportDataPage,
	RightDetailsPage,
	ServiceDetailsPage,
	ServicesPage,
	SetPasswordPage,
	SingleReceiptDetailsPage,
	SingleReceiptsPage,
	StreetsPage,
	StreetTypesPage,
	UserActivityPage,
	UserProfileDetails,
	UsersDetailsPage,
	UsersPage,
} from 'pages';
import { ForbiddenPage } from 'pages/ForbiddenPage';
import { RightsPage } from 'pages/RightsPage';
import { RolesDetailsPage } from 'pages/RolesDetailsPage';
import { RolesPage } from 'pages/RolesPage';
import { ROUTES } from 'routes';
import ProtectedRoute from 'routes/ProtectedRoute';
import { DataSettingsSocketProvider, ReportsSocketProvider } from 'socket';
import { useAppSelector } from 'store/hooks';
import { getUserProfileSelector } from 'store/user';
import MainLayout from 'components/layouts/MainLayout';
import { ROUTES_PERMISSIONS } from 'utils/constants/routes-permissions';
import { Rights } from 'utils/enums/rights.enum';
import { CanIFn, usePermissions } from 'utils/hooks/use-permissions';
import 'dayjs/locale/uk';
import { PaymentDetailsPage } from './pages/PaymentDetailsPage';
import 'react-toastify/dist/ReactToastify.css';
import customDateFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import PageTitleLayout from 'components/layouts/PageTitleLayout';

dayjs.locale('uk');
dayjs.extend(customDateFormat);
dayjs.extend(utc);

const getIndexRoute = (canI: CanIFn): ROUTES => {
	const route = Object.keys(ROUTES_PERMISSIONS).find((key: string): boolean => {
		const permission = ROUTES_PERMISSIONS[key as ROUTES];
		if (!permission) {
			return true;
		}
		return canI(permission);
	});

	if (route) {
		return route as ROUTES;
	}

	return ROUTES.forbidden;
};

const App: FC = () => {
	const canI = usePermissions();
	const userProfile = useAppSelector(getUserProfileSelector);
	const indexRoute = useMemo(() => {
		return getIndexRoute(canI);
	}, [canI, userProfile]);

	return (
		<PageTitleLayout>
			<Routes>
				<Route path={`${ROUTES.main}*`} element={<ProtectedRoute allowedRoles={['ADMIN']} />}>
					<Route element={<MainLayout />}>
						<Route index element={<Navigate replace to={indexRoute} />} />
						{canI(Rights.USERS_READ) && <Route path={ROUTES.users} element={<UsersPage />} />}
						{canI(Rights.CLIENTS_READ) && <Route path={ROUTES.clients} element={<ClientsPage />} />}
						{canI(Rights.SERVICES_READ) && <Route path={ROUTES.services} element={<ServicesPage />} />}
						{canI(Rights.PROVIDERS_READ) && <Route path={ROUTES.providers} element={<ProvidersPage />} />}
						{canI(Rights.CATEGORIES_READ) && <Route path={ROUTES.categories} element={<CategoriesPage />} />}
						<Route path={ROUTES.paymentStatus} element={<PaymentPage />} /> {/*TODO: add rights*/}
						<Route path={ROUTES.paymentStatusDetails} element={<PaymentDetailsPage />} /> {/*TODO: add rights*/}
						{canI(Rights.APARTMENTS_READ) && <Route path={ROUTES.addresses} element={<AddressesPage />} />}
						{canI(Rights.STREETS_READ) && <Route path={ROUTES.streets} element={<StreetsPage />} />}
						{canI(Rights.ROLES_READ) && <Route path={ROUTES.roles} element={<RolesPage />} />}
						{canI(Rights.ROLES_READ) && <Route path={ROUTES.rolesDetails} element={<RolesDetailsPage />} />}
						{canI(Rights.RIGHTS_READ) && <Route path={ROUTES.rights} element={<RightsPage />} />}
						<Route path={ROUTES.rightsDetails} element={<RightDetailsPage />} /> {/*TODO: add right*/}
						{canI(Rights.RIGHTS_READ) && <Route path={ROUTES.aliasesByProvider} element={<AliasesByProviderPage />} />}
						{/*TODO: change right*/}
						{canI(Rights.STREET_TYPES_READ) && <Route path={ROUTES.streetsType} element={<StreetTypesPage />} />}
						{canI(Rights.SPECIFY_DATA_READ) && <Route path={ROUTES.rejectedData} element={<RejectedDataPage />} />}
						{canI(Rights.APARTMENTS_READ) && <Route path={ROUTES.addressDetails} element={<AddressDetailsPage />} />}
						{canI(Rights.USERS_READ) && <Route path={ROUTES.usersDetails} element={<UsersDetailsPage />} />}
						{canI(Rights.USERS_READ) && <Route path={ROUTES.usersDetailsActivity} element={<UserActivityPage />} />}
						{canI(Rights.SERVICES_READ) && <Route path={ROUTES.serviceDetails} element={<ServiceDetailsPage />} />}
						{canI(Rights.SINGLE_RECEIPTS_READ) && (
							<Route path={ROUTES.singleReceipts} element={<SingleReceiptsPage />} />
						)}
						{canI(Rights.SINGLE_RECEIPTS_READ) && (
							<Route path={ROUTES.singleReceiptDetails} element={<SingleReceiptDetailsPage />} />
						)}
						{canI(Rights.OTHER_RECEIPTS_READ) && <Route path={ROUTES.otherReceipts} element={<OtherReceiptsPage />} />}
						{/* TODO: Uncommented when backend will be ready    */}
						{canI(Rights.DATA_SETTINGS_READ) && (
							<Route
								path={ROUTES.dataSettings}
								element={
									<DataSettingsSocketProvider>
										<DataSettingsPage />
									</DataSettingsSocketProvider>
								}
							/>
						)}
						{canI(Rights.REPORTS_READ) && (
							<Route
								path={ROUTES.reportData}
								element={
									<ReportsSocketProvider>
										<ReportDataPage />
									</ReportsSocketProvider>
								}
							/>
						)}
						<Route path={ROUTES.profileSetups} element={<UserProfileDetails />} />
					</Route>
				</Route>
				<Route path={ROUTES.login} element={<LoginPage />} />
				<Route path={ROUTES.resetPassword} element={<ForgotPasswordPage />} />
				<Route path={ROUTES.setPassword} element={<SetPasswordPage />} />
				<Route path={ROUTES.forbidden} element={<ForbiddenPage />} />
			</Routes>
		</PageTitleLayout>
	);
};

export default App;
