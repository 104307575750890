import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IAddressesRequest, IAddressesResponse, IApartmentAccount, IApartmentAccountUpdate } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { AddressesService } from 'utils/services';

export const getAllAddresses = createAsyncThunk(
	'addressesSlice/getAllAddresses',
	async (reqBody: IAddressesRequest, { rejectWithValue }) => {
		try {
			const addressesResponse: AxiosResponse<IAddressesResponse> = await AddressesService.getAllAddresses(reqBody);
			return addressesResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getAddressById = createAsyncThunk(
	'addressesSlice/getAddressById',
	async (id: string, { rejectWithValue }) => {
		try {
			const addressResponse: AxiosResponse<IApartmentAccount> = await AddressesService.getAddressById(id);
			return addressResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateAddressById = createAsyncThunk(
	'addressesSlice/updateAddressById',
	async (reqBody: IApartmentAccountUpdate, { rejectWithValue }) => {
		try {
			const addressResponse: AxiosResponse<IApartmentAccount> = await AddressesService.updateAddressById(reqBody);
			return addressResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
