import { RootState } from 'store';
import { IAddressesResponse } from 'types/addresses.interface';
import { IApartmentAddress } from 'types/apartment-account.interface';

export const getAllAddressesSelector = (store: RootState): IAddressesResponse | null =>
	store.addresses.allAddresses || null;

export const getAddressDetailsSelector = (store: RootState): IApartmentAddress | null => store.addresses.addressDetails || null;

export const getAddressesIsLoadingSelector = (store: RootState): boolean => store.addresses.isLoading;
