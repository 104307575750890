import { IDetailsItem } from 'types';
import {
	PAYMENTS_MAIN_DETAILS_KEYS, PAYMENTS_SUB_BILLS_DETAILS_KEYS,
	SERVICE_DETAILS_FEE_KEYS,
	SERVICE_DETAILS_KEYS,
	SERVICE_DETAILS_REG_KEYS
} from 'utils/enums/details-pages-keys.enum';
import { Rights } from 'utils/enums/rights.enum';
import { CanIFn } from 'utils/hooks/use-permissions';

export const PAYMENTS_MAIN_DETAILS_TITLES: IDetailsItem<PAYMENTS_MAIN_DETAILS_KEYS>[] = [
	{
		title: 'Загальна сума',
		key: PAYMENTS_MAIN_DETAILS_KEYS.AMOUNT,
		modalTitle: '',
	},
	{
		title: 'Загальна комісія',
		key: PAYMENTS_MAIN_DETAILS_KEYS.FEE,
		modalTitle: '',
	},
	{
		title: 'ID білу',
		key: PAYMENTS_MAIN_DETAILS_KEYS.ID,
		modalTitle: '',
	},
	{
		title: 'Провайдер',
		key: PAYMENTS_MAIN_DETAILS_KEYS.PROVIDER,
		modalTitle: '',
	},
	{
		title: 'Статус',
		key: PAYMENTS_MAIN_DETAILS_KEYS.STATUS,
		modalTitle: '',
	},
	{
		title: 'ID платника',
		key: PAYMENTS_MAIN_DETAILS_KEYS.USER_ID,
		modalTitle: '',
	},
	{
		title: 'ID Апартмента',
		key: PAYMENTS_MAIN_DETAILS_KEYS.APARTMENT_ACCOUNT_ID,
		modalTitle: '',
	},
	{
		title: 'Імʼя платника',
		key: PAYMENTS_MAIN_DETAILS_KEYS.CLIENT_NAME,
		modalTitle: '',
	},
	{
		title: 'Телефон платника',
		key: PAYMENTS_MAIN_DETAILS_KEYS.CLIENT_PHONE,
		modalTitle: '',
	},
	{
		title: 'MCC',
		key: PAYMENTS_MAIN_DETAILS_KEYS.MCC,
		modalTitle: '',
	},
	{
		title: 'Вид оплати',
		key: PAYMENTS_MAIN_DETAILS_KEYS.PAY_TYPE,
		modalTitle: '',
	},
	{
		title: 'ID оплати',
		key: PAYMENTS_MAIN_DETAILS_KEYS.PAYMENT_ID,
		modalTitle: '',
	},
	{
		title: 'Тип оплати',
		key: PAYMENTS_MAIN_DETAILS_KEYS.PAYMENT_TYPE,
		modalTitle: '',
	},
	{
		title: 'ID єидиної квитанції',
		key: PAYMENTS_MAIN_DETAILS_KEYS.SINGLE_RECEIPT_ID,
		modalTitle: '',
	},
];

export const PAYMENTS_SUB_BILLS_DETAILS_TITLES: IDetailsItem<PAYMENTS_SUB_BILLS_DETAILS_KEYS>[] = [
	{
		title: 'Сума',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.AMOUNT,
		modalTitle: '',
	},
	{
		title: 'Комісія',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.FEE,
		modalTitle: '',
	},
	{
		title: 'Провайдер',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.PROVIDER,
		modalTitle: '',
	},
	{
		title: 'Назва провайдеру',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.PROVIDER_TITLE,
		modalTitle: '',
	},
	{
		title: 'ID сервісу',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.SERVICE_ID,
		modalTitle: '',
	},
	{
		title: 'ID сервіс провайдера',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.SERVICE_PROVIDER_ID,
		modalTitle: '',
	},
	{
		title: 'Статус',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.STATUS,
		modalTitle: '',
	},
	{
		title: 'Назва сервісу',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.SERVICE_NAME,
		modalTitle: '',
	},
	{
		title: 'IBAN',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.ACCOUNT,
		modalTitle: '',
	},
	{
		title: 'МФО',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.BANK_MFO,
		modalTitle: '',
	},
	{
		title: 'Назва банку',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.BANK_NAME,
		modalTitle: '',
	},
	{
		title: 'Опис',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.DESCRIPTION,
		modalTitle: '',
	},
	{
		title: 'Назва',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.NAME,
		modalTitle: '',
	},
	{
		title: 'ZKPO',
		key: PAYMENTS_SUB_BILLS_DETAILS_KEYS.ZKPO,
		modalTitle: '',
	},
];


// TODO for this function it would be nice to have some caching like useMemo, or useCallback
export const getLockedFields = (items: IDetailsItem<any>[], canI: CanIFn) => (
		items
			.filter((item) => item.managePermission && !canI(item.managePermission as Rights))
			.map((item) => item.key)
	);
