// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentDetailsPage_wrapper__9gaow {
  max-width: 684px;
  display: grid;
  gap: 12px;
  height: 97%;
  overflow-y: scroll;
  padding-right: 10px;
}
.PaymentDetailsPage_wrapper__9gaow .PaymentDetailsPage_actions__uvCM4 {
  display: flex;
  gap: 12px;
}
.PaymentDetailsPage_wrapper__9gaow .PaymentDetailsPage_actions__uvCM4 button {
  width: 100%;
}
.PaymentDetailsPage_wrapper__9gaow .PaymentDetailsPage_fee__KkiwS {
  display: flex;
  flex-direction: column;
  gap: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PaymentDetailsPage/index.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,aAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;AACD;AACC;EACC,aAAA;EACA,SAAA;AACF;AACE;EACC,WAAA;AACH;AAGC;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AADF","sourcesContent":[".wrapper {\n\tmax-width: 684px;\n\tdisplay: grid;\n\tgap: 12px;\n\theight: 97%;\n\toverflow-y: scroll;\n\tpadding-right: 10px;\n\n\t.actions {\n\t\tdisplay: flex;\n\t\tgap: 12px;\n\n\t\tbutton {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n\n\t.fee {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tgap: 12px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PaymentDetailsPage_wrapper__9gaow`,
	"actions": `PaymentDetailsPage_actions__uvCM4`,
	"fee": `PaymentDetailsPage_fee__KkiwS`
};
export default ___CSS_LOADER_EXPORT___;
