import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetUserActivityListDto } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { UserActivityService } from 'utils/services';

export const getUserActivityList = createAsyncThunk(
	'userSlice/getUserActivityList',
	async (reqBody: GetUserActivityListDto, { rejectWithValue }) => {
		try {
			const { data } = await UserActivityService.getUserActivityList(reqBody);
			return data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
