import { RootState } from 'store';
import { IPaymentListResponse } from "types/payment.interface";

export const getPaymentListSelector = (store: RootState): IPaymentListResponse | null =>
	store.payments.paymentList || null;

export const getPaymentInfoSelector = (store: RootState): any | null =>
	store.payments.paymentInfo || null;

export const getPaymentListIsLoadingSelector = (store: RootState): boolean => store.payments.isLoading;
