import { IAddressesDetails } from 'types';
import { ADDRESSES_HEADER_TITLES } from 'utils/constants/addresses';
import { ADDRESSES_FIELDS_NAME } from 'utils/enums';
import { getCityWithShortPrefix } from 'utils/helpers';

export const COLUMNS_CONFIG = [
	{ field: ADDRESSES_FIELDS_NAME.ID, headerName: ADDRESSES_HEADER_TITLES.ID, sortable: false, flex: 1 },
	{
		field: ADDRESSES_FIELDS_NAME.CITY,
		headerName: ADDRESSES_HEADER_TITLES.CITY,
		sortable: false,
		flex: 1,
		valueGetter: (_: unknown, row: IAddressesDetails) => getCityWithShortPrefix(row.address.street.city),
	},
	{ field: ADDRESSES_FIELDS_NAME.STREET_TYPE, headerName: ADDRESSES_HEADER_TITLES.STREET_TYPE, flex: 1 },
	{ field: ADDRESSES_FIELDS_NAME.STREET, headerName: ADDRESSES_HEADER_TITLES.STREET, flex: 1 },
	{ field: ADDRESSES_FIELDS_NAME.HOUSE, headerName: ADDRESSES_HEADER_TITLES.HOUSE, flex: 1 },
	{ field: ADDRESSES_FIELDS_NAME.FLAT, headerName: ADDRESSES_HEADER_TITLES.FLAT, flex: 1 },
	{ field: ADDRESSES_FIELDS_NAME.CREATED_AT, headerName: ADDRESSES_HEADER_TITLES.CREATED_AT, sortable: false, flex: 1 },
];
