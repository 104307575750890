import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IProvidersRequest, IProvidersResponse } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { ProvidersService } from 'utils/services';

export const getProviders = createAsyncThunk(
	'providersSlice/getProviders',
	async (reqBody: IProvidersRequest, { rejectWithValue }) => {
		try {
			const providersResponse: AxiosResponse<IProvidersResponse> = await ProvidersService.getProviders(reqBody);
			return providersResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
