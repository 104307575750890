import { IReportsRequest } from 'types';
import { SORT_DIR } from 'utils/enums';

export const DEFAULT_PAGINATED_REPORTS_LIST_REQUEST_BODY: IReportsRequest = {
	pagination: {
		offset: 0,
		limit: 8,
	},
	sorting: {
		field: 'createdAt',
		direction: SORT_DIR.ASC,
	},
	report_type: '',
	provider_type: '',
};
