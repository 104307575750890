import { createSlice } from '@reduxjs/toolkit';
import { IUserActivityReducer } from 'types';
import { getUserActivityList } from './actions';

const initialState: IUserActivityReducer = {
	userActivity: null,
};

const userActivitySlice = createSlice({
	name: 'userActivitySlice',
	initialState,
	reducers: {
		clearUsersActivityData: (state) => {
			state.userActivity = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserActivityList.fulfilled, (state, { payload }) => {
			state.userActivity = payload;
		});
	},
});

export const { clearUsersActivityData } = userActivitySlice.actions;
export const userActivityReducer = userActivitySlice.reducer;
