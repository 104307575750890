import { IDetailsItem } from 'types';
import { RIGHT_DETAILS_KEYS } from 'utils/enums/details-pages-keys.enum';

export const DETAILS_TITLES: IDetailsItem<RIGHT_DETAILS_KEYS>[] = [
	{
		title: 'Назва',
		key: RIGHT_DETAILS_KEYS.NAME,
		modalTitle: 'Вкажіть назву ролі',
	},
	{
		title: 'Опис',
		key: RIGHT_DETAILS_KEYS.DESCRIPTION,
		modalTitle: 'Вкажіть опис',
	},
	{
		title: 'Права',
		key: RIGHT_DETAILS_KEYS.STATUS,
		modalTitle: 'Виберіть права',
	},
	{
		title: 'Створено',
		key: RIGHT_DETAILS_KEYS.CREATED_AT,
		modalTitle: '',
	},
	{
		title: 'Оновлено',
		key: RIGHT_DETAILS_KEYS.UPDATE_AT,
		modalTitle: '',
	},
];

export const RIGHT_LOCKED_FIELDS = [ RIGHT_DETAILS_KEYS.CREATED_AT, RIGHT_DETAILS_KEYS.UPDATE_AT ];

