import { createSlice } from '@reduxjs/toolkit';
import { IRightsDetailsReducer } from 'types';
import { getRightDetails } from "./actions";

const initialState: IRightsDetailsReducer = {
	rightDetails: null,
	isLoading: true,
};

const rightSlice = createSlice({
	name: 'rightDetailsSlice',
	initialState,
	reducers: {
		clearRoleData: (state) => {
			state.rightDetails = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getRightDetails.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getRightDetails.fulfilled, (state, { payload }) => {
			state.rightDetails = payload;
			state.isLoading = false;
		});
		builder.addCase(getRightDetails.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const rightDetailsReducer = rightSlice.reducer;
