import { API } from 'api';
import { AxiosResponse } from 'axios';
import {
	IGenerateReportsRequest,
	IReceiptRequest,
	IReportData,
	IReportSingleReceiptResponse,
	IReportsRequest,
} from 'types';
import { API_URL } from 'utils/enums';
import { removeEmptyProps } from 'utils/helpers';

class ReportsService {
	static generateReports = async (reqBody: IGenerateReportsRequest): Promise<AxiosResponse> =>
		API.post(API_URL.REPORTS_GENERATE, reqBody);

	static getReportsList = async (reqBody: IReportsRequest): Promise<AxiosResponse<IReportData[]>> =>
		API.post(API_URL.REPORTS_LIST_GET, reqBody);

	static deleteReportById = async (id: number): Promise<AxiosResponse> =>
		API.delete(`${API_URL.REPORT_DELETE}?id=${id}`);

	static getReportSingleReceipt = async (
		reqBody: IReceiptRequest
	): Promise<AxiosResponse<IReportSingleReceiptResponse>> =>
		API.post(API_URL.REPORT_GET_SINGLE_RECEIPT, removeEmptyProps(reqBody));

	static getReportListById = async (billSingleReceiptId: number): Promise<any> =>
		API.post(API_URL.REPORT_LIST_PAYMENT_GET, { billSingleReceiptId });
}

export { ReportsService };
