export enum ERROR_CODE {
	SESSION_NOT_AUTHORIZED = 'UAPI003',
	SESSION_NOT_FOUND = 'UAPI005',
	INVALID_CONFIRM_CODE = 'U023',
	INVALID_PASSWORD_OR_NOT_FOUND = 'U003',
}

export enum ERROR_MESSAGE {
	INVALID_PASSWORD_OR_NOT_FOUND = 'Користувача з таким номером телефону не існує або PIN код неправильний',
}

export enum VALIDATION_ERROR {
	MIN_LENGTH = 'Мінімальні довжина 2 символи',
	SPECIAL_CHARACTERS = "Поле може містити лише такі символи ` - ` ` ' `",
	FIRST_CHARACTER = 'Поле повинно починатися з літери',
	REQUIRED = 'Це поле обов`язкове',
	EMAIL = 'Неправильний формат',
}
