import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	IGetServiceDetailsResponse,
	IServicesRequest,
	IServicesResponse,
	IUpdateServiceFeeRequest,
	IUpdateServiceRequest
} from 'types';
import { getErrorResponse } from 'utils/helpers';
import { ServicesService } from 'utils/services';

export const getServices = createAsyncThunk(
	'servicesSlice/getServices',
	async (reqBody: IServicesRequest, { rejectWithValue }) => {
		try {
			const servicesResponse: AxiosResponse<IServicesResponse> = await ServicesService.getServices(reqBody);
			return servicesResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const createService = createAsyncThunk(
	'servicesSlice/createServices',
	async (reqBody: IUpdateServiceRequest, { rejectWithValue }) => {
		try {
			const serviceResponse: AxiosResponse<IGetServiceDetailsResponse> = await ServicesService.createService(reqBody);
			return serviceResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getServiceById = createAsyncThunk(
	'servicesSlice/createServiceById',
	async (id: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IGetServiceDetailsResponse> = await ServicesService.getServiceById(id);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateServiceById = createAsyncThunk(
	'servicesSlice/updateServiceById',
	async (reqBody: IUpdateServiceRequest, { rejectWithValue }) => {
		try {
			const response = await ServicesService.updateService(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
export const updateServiceFee = createAsyncThunk(
	'servicesSlice/updateServiceFee',
	async (reqBody: IUpdateServiceFeeRequest, { rejectWithValue }) => {
		try {
			const response = await ServicesService.updateServiceFee(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
