import { FC } from 'react';
import { FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export interface IOption {
	title: string;
	value: string;
}

export interface CustomSelectProps {
	options?: IOption[];
	value: string;
	placeholder: string;
	onChange: (value: SelectChangeEvent<string>) => void;
	name: string;
	className?: string;
	helperText?: string;
	disableOption?: boolean;
	multiple?: boolean;
}

export const CustomSelect: FC<CustomSelectProps> = ({
	value,
	options,
	className,
	placeholder,
	onChange,
	name,
	helperText,
	multiple,
}) => {
	const handleRenderSelectValue = (value: string | string[]) => {
		const isValueEmptyArray = Array.isArray(value) && !value.length;

		if (isValueEmptyArray) return placeholder;

		const inputValue = options?.reduce((acc, curr) => {
			if (Array.isArray(value)) {
				return value.includes(curr.value) ? `${acc}${curr.title ? `${acc ? ', ' : ''}${curr.title}` : ''}` : acc;
			}
			return value === curr.value ? curr.title : acc;
		}, '');

		return inputValue;
	};

	return (
		<div>
			<Select
				multiple={multiple}
				displayEmpty
				value={value ?? ''}
				className={className}
				onChange={onChange}
				name={name}
				renderValue={handleRenderSelectValue}
			>
				<MenuItem style={{ display: 'none' }} disabled value={''}>
					<em>{placeholder}</em>
				</MenuItem>
				{options?.map((item) => (
					<MenuItem key={item.value} value={item.value} title={item.title}>
						{item.title}
					</MenuItem>
				))}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</div>
	);
};
