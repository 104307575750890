import { FC, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'assets/images/logo-host.svg';
import { PLATFORM_TABS, withPermissions } from 'utils/constants/tabs';
import { usePermissions } from 'utils/hooks/use-permissions';
import s from './styles.module.scss';

const Sidebar: FC = () => {
	const { pathname } = useLocation();
	const activeTab = pathname.split('/').filter(Boolean)[0];
	const checkRights = usePermissions();

	const selectedTab = useMemo(
		() => (PLATFORM_TABS.some((el) => el.path === activeTab) ? activeTab : false),
		[activeTab]
	);

	return (
		<Box className={s.wrapper}>
			<Box className={s.logo}>
				<Logo />
			</Box>
			<Tabs className={s.navbar} orientation="vertical" value={selectedTab} scrollButtons={false} variant="scrollable">
				{withPermissions(checkRights)
					.filter(({ visible }) => visible)
					.map(({ path, title }) => (
						<Tab key={path} component={Link} value={path} label={title} to={path} />
					))}
			</Tabs>
			<Typography
				className={s.version}
			>v1.9.8 (12.11.2024)</Typography>
		</Box>
	);
};

export default Sidebar;
