import { isObject } from './is-object';

export const isDeepEqual = <T extends object>(previousValues: T, newValues: T): boolean => {
	const previousValuesKeys = Object.keys(previousValues);

	const newValuesKeys = Object.keys(newValues) as (keyof T)[];

	if (previousValuesKeys.length !== newValuesKeys.length) return false;

	return newValuesKeys.every((key) => {
		const previous = previousValues[key];
		const current = newValues[key];

		if (isObject(previous) && isObject(current)) {
			return isDeepEqual(previous as object, current as object);
		}

		return previous === current;
	});
};
