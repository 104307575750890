import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IProvidersRequest, IProvidersResponse } from 'types';
import { API_URL } from 'utils/enums';

class ProvidersService {
	static getProviders = async (reqBody: IProvidersRequest): Promise<AxiosResponse<IProvidersResponse>> =>
		API.post(API_URL.PROVIDERS_LIST_GET, reqBody);
}

export { ProvidersService };
