export enum RadabankMethods {
    InternalClientPhone = 'bankissues/auth/internal-client-phone',
    InfoBegin = 'bankissues/auth/info-begin',
    InternalTokenVerify = 'bankissues/auth/internal-token-verify',
    InfoStatus = 'bankissues/auth/info-status',
    InfoApproveCode = 'bankissues/auth/info-approve-code',
    InfoSendCode = 'bankissues/auth/info-send-code',
    InfoDiyaBegin = 'bankissues/auth/info-diya-begin',
    InfoImageSet = 'bankissues/auth/info-image-set',
    InfoImageCommit = 'bankissues/auth/info-image-commit',
    InfoSprData = 'bankissues/auth/info-spr-data',
    InfoGetAddressRegion = 'bankissues/auth/info-get-address-region',
    InfoGetAddressDistrict = 'bankissues/auth/info-get-address-district',
    InfoGetAddressCityType = 'bankissues/auth/info-get-address-city-type',
    InfoGetAddressCity = 'bankissues/auth/info-get-address-city',
    InfoGetAddressStreetType = 'bankissues/auth/info-get-address-street-type',
    InfoSetAnketa = 'bankissues/auth/info-set-anketa',
    InfoSetPassword = 'bankissues/auth/info-set-password',
    InfoCreateStartDocument = 'bankissues/auth/info-create-start-document',
    InfoGetImage = 'bankissues/auth/info-get-image',
    InfoCommitStartDocument = 'bankissues/auth/info-commit-start-document',
    InfoCommitSignDocument = 'bankissues/auth/info-commit-sign-document',
    InternalStartActivate = 'bankissues/auth/internal-start-activate',
    InternalVerifyActivateCode = 'bankissues/auth/internal-verify-activate-code',
    InternalActivate = 'bankissues/auth/internal-activate',
    InternalAuthorize = 'bankissues/auth/internal-authorize',
    InternalSmsVerify = 'bankissues/auth/internal-sms-verify',
    InternalCreateCoBrandCard = 'bankissues/auth/internal-co-brand-create-card',
    InternalVerifyCoBrandCard = 'bankissues/auth/internal-co-brand-verify-card',
    InternalListCurrentCard = 'bankissues/auth/internal-list-current-card',
    InternalListClosedCard = 'bankissues/auth/internal-list-closed-card',
    InternalGetCvvCard = 'bankissues/auth/internal-get-cvv-card',
    ShowStatementDoc = 'bankissues/auth/show-statement-doc',
    CardCreatePayment = 'bankissues/auth/card-create-payment',
    CardFee = 'bankissues/auth/card-fee',
    TransferCreatePayment = 'bankissues/auth/transfer-create-payment',
    TransferFee = 'bankissues/auth/transfer-fee',
    GetStatusPaymentById = 'bankissues/auth/get-status-payment-by-id',
    InternalGetCountryRule = 'bankissues/auth/internal-get-country-rule',
    InternalGetCurrencyRule = 'bankissues/auth/internal-get-currency-rule',
    GetBudgetCode = 'bankissues/auth/get-budget-code',
    InternalGetCardLockReasons = 'bankissues/auth/internal-card-lock-reasons',
    InternalBlockCard = 'bankissues/auth/internal-bloack-card',
    InternalDeblockCard = 'bankissues/auth/internal-deblock-card',
    InternalRemindPinCard = 'bankissues/auth/internal-remind-pin-card',
    InternalChangePinCard = 'bankissues/auth/internal-change-pin-card',
    InternalCardCurrentRestData = 'bankissues/auth/internal-card-current-rest-data',
    InternalClientRequestOver = 'bankissues/auth/internal-client-request-over',
    InternalClientPreRequestOverMB = 'bankissues/auth/internal-client-pre-request-over-mb',
    InternalSprData = 'bankissues/auth/internal-spr-data',
    InternalDataCardLimit = 'bankissues/auth/internal-data-card-limit',
    InternalGetSelectedDoc = 'bankissues/auth/internal-get-selected-doc',
    InternalCurrentRules = 'bankissues/auth/internal-current-rules',
    InternalAddRule = 'bankissues/auth/internal-add-rule',
    InternalDeleteRule = 'bankissues/auth/internal-delete-rule',
    InternalPrintDocumentData = 'bankissues/auth/internal-print-document-data',
    InternalSmsCard = 'bankissues/auth/internal-sms-card',
    InternalSetCardSms = 'bankissues/auth/internal-set-card-sms',
    CreateP2pData = 'bankissues/auth/create-2p2-data',
    GetAmountP2pTransfer = 'bankissues/auth/get-amount-p2p-transfer',
    InternalGetFeeOther = 'bankissues/auth/internal-get-fee-other',
    InternalCheckCardBin = 'bankissues/auth/internal-check-card-bin',
    PrintStatementDocData = 'bankissues/auth/print-statement-doc-data',
    PrintEmailStatementDocData = 'bankissues/auth/print-email-statement-doc-data',
    InternalChangeClientPass = 'bankissues/auth/internal-change-client-pass',
    InternalApproveClientPass = 'bankissues/auth/internal-approve-client-pass',
    NpInternalCity = 'bankissues/np/internal-city',
    NpInternalStreet = 'bankissues/np/internal-street',
    NpInternalDivision = 'bankissues/np/internal-division',
    NpInternalCreateRequest = 'bankissues/np/internal-create-request',
    NpInternalCardStatus = 'bankissues/np/internal-card-status',
    NpInternalActivateCard = 'bankissues/np/internal-activate-card'
  }
  