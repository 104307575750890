import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPaymentBillInfo, getPaymentInfoSelector } from "store/payment";
import { IPaymentDetailsMain, IPaymentsDetailsSubBills, ISubBillsItem } from "types/payment.interface";
import { DetailsContent } from 'components/modules/DetailsContent';
import Loading from 'components/modules/Loading';
import { PAYMENTS_LOCKED_MAIN_FIELDS, PAYMENTS_LOCKED_SUB_FIELDS } from "utils/constants";
import {
    PAYMENTS_MAIN_DETAILS_KEYS,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS,
} from 'utils/enums';
import { convertCoinsToUAH, getLocaleAmountString } from "utils/helpers";
import { usePermissions } from 'utils/hooks/use-permissions';
import { PAYMENTS_MAIN_DETAILS_TITLES, PAYMENTS_SUB_BILLS_DETAILS_TITLES } from "./config";
import s from './index.module.scss';


// TODO remove commented block when will be approve

export const PaymentDetailsPage: FC = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const canI = usePermissions();

    const paymentDetails = useAppSelector(getPaymentInfoSelector);

    const [paymentsDetailsData, setPaymentsDetailsData] = useState<null | IPaymentDetailsMain>(null);
    const [paymentSubBillsData, setPaymentSubBillsData] = useState<null | IPaymentsDetailsSubBills[]>(null);

    useEffect(() => {
        if (id) {
            dispatch(getPaymentBillInfo(id));
        }
        setPaymentsDetailsData(null)
        setPaymentSubBillsData(null)
    }, [id]);

    useEffect(() => {
        if (paymentDetails) {
            setPaymentsDetailsData(
                {
                    amount: getLocaleAmountString(convertCoinsToUAH(paymentDetails.amount)),
                    fee: getLocaleAmountString(convertCoinsToUAH(paymentDetails.fee)),
                    id: paymentDetails.id,
                    provider: paymentDetails.provider,
                    status: paymentDetails.status,
                    userId: paymentDetails.userId,
                    apartmentAccountId: paymentDetails.payerData.apartmentAccountId,
                    clientName: paymentDetails.payerData.clientName,
                    clientPhone: paymentDetails.payerData.clientPhone,
                    mcc: paymentDetails.payerData.mcc,
                    payType: paymentDetails.payerData.payType,
                    paymentId: paymentDetails.payerData.paymentId,
                    paymentType: paymentDetails.payerData.paymentType,
                    singleReceiptId: paymentDetails.payerData.singleReceiptId,
                }
            )
                setPaymentSubBillsData(() => []);
                const updatedSubBills = paymentDetails.subBills.map((subBillItem: ISubBillsItem) => ({
                    amount: getLocaleAmountString(convertCoinsToUAH(subBillItem.amount)),
                    fee: getLocaleAmountString(convertCoinsToUAH(subBillItem.fee)),
                    provider: subBillItem.provider,
                    providerTitle: subBillItem.providerTitle,
                    serviceId: subBillItem.serviceId,
                    status: subBillItem.status,
                    serviceProviderId: subBillItem.payerData.serviceProviderId,
                    serviceName: subBillItem.payerData.serviceName,
                    account: subBillItem.service?.regDataObject.account,
                    bankMfo: subBillItem.service?.regDataObject.bankMfo,
                    bankName: subBillItem.service?.regDataObject.bankName,
                    description: subBillItem.service?.regDataObject.description,
                    name: subBillItem.service?.regDataObject.name,
                    zkpo: subBillItem.service?.regDataObject.zkpo,
                }));

                setPaymentSubBillsData((prevState) => [
                    ...(prevState || []),
                    ...updatedSubBills,
                ]);
        }
    }, [paymentDetails]);

    if (!paymentDetails) return <Loading/>;

    return (
        <Box className={s.wrapper}>
            {paymentsDetailsData &&
            <DetailsContent<PAYMENTS_MAIN_DETAILS_KEYS, IPaymentDetailsMain>
                title="Загальні дані про платіж"
                detailsData={paymentsDetailsData}
                detailsTitles={PAYMENTS_MAIN_DETAILS_TITLES}
                lockedFields={PAYMENTS_LOCKED_MAIN_FIELDS}
            />
            }
            {paymentSubBillsData &&
                paymentSubBillsData.map((subBillsItem: IPaymentsDetailsSubBills, item: number) =>
                    <DetailsContent<PAYMENTS_SUB_BILLS_DETAILS_KEYS, IPaymentsDetailsSubBills>
                        title={`Субрахунок ${item + 1}`}
                        detailsData={subBillsItem}
                        detailsTitles={PAYMENTS_SUB_BILLS_DETAILS_TITLES}
                        lockedFields={PAYMENTS_LOCKED_SUB_FIELDS}
                        key={subBillsItem.serviceId}
                    />
                )
            }
        </Box>
    );
};
