import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { isObject } from 'utils/helpers/is-object';
import s from './index.module.scss';

interface ICustomCell {
	reqData: Record<string, string>;
}

const CustomCell: FC<ICustomCell> = ({ reqData }): ReactElement | null => {
	if (reqData === null) return null;
	const renderKeyValuePairs = () => {
		return Object.entries(reqData).map(([key, value]) => (
			<Typography variant="button" key={key}>
				{key}:{value}
			</Typography>
		));
	};

	if (isObject(reqData)) {
		const values = Object.values(reqData);

		if (values.length === 1) {
			const [value] = values;

			return <Typography variant="button">{value}</Typography>;
		}
	}

	return <Box className={s.cell}>{renderKeyValuePairs()}</Box>;
};

export const MemoizedCustomCell = React.memo(CustomCell);
