import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IOtherReceiptsRequest } from 'types';
import { API_URL } from 'utils/enums';

class OtherReceiptsService {
	static getOtherReceiptsList = async (reqBody: IOtherReceiptsRequest): Promise<AxiosResponse> =>
		API.post(API_URL.OTHER_RECEIPTS_GET, reqBody);

	static getOtherReceiptsReport = async (billId: string): Promise<AxiosResponse> =>
		API.post(API_URL.OTHER_RECEIPTS_REPORT_GET, { billId });

	static getOtherReceiptsBillCancel = async (billId: string): Promise<AxiosResponse> =>
		API.post(API_URL.OTHER_RECEIPTS_BILL_CANCELED, { deviceFP: "6037e65eefbdasdfadsfasdf3", billId });
}
export { OtherReceiptsService };
