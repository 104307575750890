import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IClientsRequest, IClientsResponse, ICreateClientRequest } from 'types';
import { API_URL } from 'utils/enums';

class ClientsService {
	static getClients = async (reqBody: IClientsRequest): Promise<AxiosResponse<IClientsResponse>> =>
		API.post(API_URL.CLIENTS_LIST_GET, reqBody);

	static createClient = async (reqBody: ICreateClientRequest): Promise<AxiosResponse<IClientsResponse>> =>
		API.post(API_URL.CLIENTS_CREATE, reqBody);
}

export { ClientsService };
