import { REJECTED_DATA_HEADER_TITLES } from "utils/constants/rejected-data";
import { REJECTED_DATA_FIELDS_NAME } from 'utils/enums';

export const COLUMNS_CONFIG = [
	{
		field: REJECTED_DATA_FIELDS_NAME.CITY,
		headerName: REJECTED_DATA_HEADER_TITLES.CITY,
		sortable: true,
		editable: true,
		flex: 1.2
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.STREET_TYPE,
		headerName: REJECTED_DATA_HEADER_TITLES.STREET_TYPE,
		sortable: true,
		editable: true,
		flex: 1
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.STREET,
		headerName: REJECTED_DATA_HEADER_TITLES.STREET,
		sortable: true,
		editable: true,
		flex: 1.3
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.HOUSE,
		headerName: REJECTED_DATA_HEADER_TITLES.HOUSE,
		sortable: true,
		editable: true,
		flex: 0.8
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.FLAT,
		headerName: REJECTED_DATA_HEADER_TITLES.FLAT,
		sortable: true,
		editable: true,
		flex: 1
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.SERVICES_CODE,
		headerName: REJECTED_DATA_HEADER_TITLES.SERVICE_CODE,
		flex: 0.8
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.SERVICES_NAME,
		headerName: REJECTED_DATA_HEADER_TITLES.SERVICES_NAME,
		flex: 0.8
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.REASON,
		headerName: REJECTED_DATA_HEADER_TITLES.REASON,
		sortable: false,
		editable: false,
		flex: 1
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.PROVIDER_ACCOUNT_ID,
		headerName: REJECTED_DATA_HEADER_TITLES.PROVIDER_ID,
		sortable: false,
		flex: 1
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.PROVIDER,
		headerName: REJECTED_DATA_HEADER_TITLES.PROVIDER,
		sortable: false,
		flex: 0.8
	},
	{
		field: REJECTED_DATA_FIELDS_NAME.CREATED_AT,
		headerName: REJECTED_DATA_HEADER_TITLES.CREATED_AT,
		sortable: false,
    	editable: false,
		flex: 1
	}
];
