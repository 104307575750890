import { FC } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';

export const EditIconButton: FC<Omit<IconButtonProps, 'children'>> = (props) => {
	return (
		<IconButton color="primary" aria-label="Edit" {...props}>
			<Edit />
		</IconButton>
	);
};
