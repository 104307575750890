import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Checkbox, TextField } from '@mui/material';
import { DataGrid, GridPaginationModel, GridSortDirection, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ROUTES } from "routes";
import {
	clearAliasesByProviderData,
	getAliasesByProvider,
	getAliasesByProviderIsLoadingSelector,
	getAliasesByProviderSelector,
	updateAliasesByProvider,
} from "store/aliases-by-provider";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IQuery,IStreetsDetails  } from 'types';
import { CustomSelect } from "components/elements";
import { PAGINATION_LIMIT } from 'utils/constants';
import { DATE_FORMAT, PROVIDER_TYPE, SORT_DIR, STREETS_FIELDS_NAME } from 'utils/enums';
import {
	formatQueryString,
	parseQueryString,
} from 'utils/helpers';
import { formatDateView } from "utils/helpers/date";
import { getTableSetting } from "utils/helpers/table-setting";
import { usePermissions } from 'utils/hooks/use-permissions';
import { useColumns } from 'utils/hooks/use-selection-fields';
import { COLUMNS_CONFIG } from "./config"
import s from './index.module.scss';

// TODO OPTIMIZE

export const AliasesByProviderPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const checkRights = usePermissions();
	const aliasesByProviderList = useAppSelector(getAliasesByProviderSelector);
	const isLoading = useAppSelector(getAliasesByProviderIsLoadingSelector);
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const [inputValue, setInputValue] = useState<string>('');
	const [streetId, setStreetId] = useState<string | null | undefined>(undefined);
	const [isUndefinedChecked, setIsUndefinedChecked] = useState<boolean>(false);

	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const requestBody = useMemo(
		() => ({
			offset: (query.page || 0) * (query.pageSize || 0),
			limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			orderBy: [
				{
					field: query.field || STREETS_FIELDS_NAME.NAME,
					direction: query.sort || SORT_DIR.ASC,
				},
			],
			searchBy: {
				name: query.searchQuery,
				streetId,
				providerName: inputValue || undefined,
			},
			deviceFP: "6037e65eefbdasdfadsfasdf3"
		}),
		[query, inputValue, streetId]
	);

	useEffect(() => {
		dispatch(getAliasesByProvider(requestBody));
	}, [requestBody, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearAliasesByProviderData());
		};
	}, [dispatch]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.aliasesByProvider, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.aliasesByProvider));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleCellEdit = async (newRow: IStreetsDetails, row: IStreetsDetails): Promise<IStreetsDetails> => {
		const { id, streetId } = newRow;
		const requestBody = {
			id,
			streetId,
			deviceFP: '6037e65eefbdasdfadsfasdf3'
		};

		try {
			const { payload } = await dispatch(updateAliasesByProvider(requestBody));

			if (payload.errorData) {
				console.warn('Payload error data:', payload.errorData);
				return row;
			}

			const { street } = payload;
			return {
				...newRow,
				street: street.name,
				streetType: street.streetType.name,
				city: street.city.name
			};
		} catch (error) {
			console.error('Error updating aliases:', error.message || error);
			return row;
		}
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	const handleChangeSelect = (event: any) => {
		setInputValue(event.target.value)
	}

	const providerOption = [
		{ value: PROVIDER_TYPE.HCS, title: 'HCS' },
		{ value: PROVIDER_TYPE.KTS, title: 'KTS' },
		{ value: PROVIDER_TYPE.KVBO, title: 'KVBO' },
		{ value: PROVIDER_TYPE.KVD, title: 'KVD' },
		{ value: '', title: 'Показати будь який' },
	];

	const handleChangeStreetId = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setStreetId(value || undefined);
		setIsUndefinedChecked(false);
	};

	const handleCheckStreetId = () => {
		setIsUndefinedChecked(!isUndefinedChecked);
		setStreetId(isUndefinedChecked ? undefined : null);
	};

	const handleClickStreetId = () => {
		setIsUndefinedChecked(false);
		setStreetId(undefined);
	};

	const modifiedStreets = useMemo(
		() =>
			aliasesByProviderList?.results.map((alias: any) => ({
				...alias,
				cityCE: alias.city.name,
				'streetType.name': alias.streetType.name,
				city: alias.street !== null && alias.street.city.name,
				streetType: alias.street !== null && alias.street.streetType.name,
				street: alias.street !== null && alias.street.name,
				'provider.name': alias.provider.name,
				createdAt: formatDateView(alias.createdAt, DATE_FORMAT.DATE_FULL),
				updatedAt: formatDateView(alias.updatedAt, DATE_FORMAT.DATE_FULL),
			})),
		[aliasesByProviderList]
	);

	return (
		<>
			<Box className={s.filter}>
				<Box>
					<CustomSelect
						value={inputValue}
						name="Провайдер"
						onChange={handleChangeSelect}
						placeholder="Провайдер"
						options={providerOption}
					/>
				</Box>
				<TextField
					value={streetId ?? ''}
					placeholder="Пошук по streetId"
					onChange={handleChangeStreetId}
				/>
				<Box className={s.filter__checkbox}>
					<Checkbox checked={isUndefinedChecked} onChange={handleCheckStreetId} />
					<Box>Показати лише не визначені</Box>
				</Box>
				<Button variant="contained" onClick={handleClickStreetId}>Очистити</Button>
			</Box>
			<Box className={s.tableWrapper}>
				<DataGrid
					disableColumnFilter
					onColumnResize={handleColumnResize}
					apiRef={apiRef}
					disableRowSelectionOnClick
					rows={modifiedStreets || []}
					columns={useColumns(COLUMNS_CONFIG)}
					rowCount={aliasesByProviderList?.total || 0}
					loading={isLoading}
					getCellClassName={(params) => params.colDef.type === 'custom' ? s.custom : ''}
					initialState={{
						pagination: {
							paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
						},
						sorting: {
							sortModel: [{ field: query.field || STREETS_FIELDS_NAME.CREATE_AT, sort: query.sort as GridSortDirection }],
						},
					}}
					slotProps={{
						pagination: {
							labelRowsPerPage: 'Показувати :',
							page: query.page || 0,
							rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
						},
					}}
					processRowUpdate={handleCellEdit}
					paginationMode="server"
					sortingMode="server"
					onSortModelChange={handleSortField}
					onPaginationModelChange={handleChangePage}
					className={s.table}
					pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
				/>
			</Box>
		</>
	);
};
