export enum STREET_TYPE {
	STREET = 'ВУЛИЦЯ',
	STREET_TYPE = 'ПРОВУЛОК',
}

export enum USER_STATUS {
	ACTIVE = 'ACTIVE',
	BLOCKED = 'BLOCKED',
	DELETED = 'DELETED',
	TEMPORARY_BLOCKED = 'TEMPORARY_BLOCKED'
}

export enum SERVICE_STATUS {
	ACTIVE = 'ACTIVE',
	BLOCKED = 'BLOCKED',
	DISABLED = 'DISABLED',
	TEMPORARY_BLOCKED = 'TEMPORARY_BLOCKED'
}
