import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { ROUTES } from 'routes';
import { IErrorResponse } from 'types';
import { STORAGE_KEY } from 'utils/enums';
import { StorageService } from 'utils/services';

const axiosConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_ADMIN_API_URL,
	withCredentials: true,
};

const instance: AxiosInstance = axios.create(axiosConfig);

instance.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		if (config.headers['Content-Type'] === 'multipart/form-data') return config;
		// for each request adding a session id
		const sessionId: string | null = StorageService.get(STORAGE_KEY.SESSION_ID);
		// eslint-disable-next-line no-param-reassign
		config.data = { ...config.data };

		if (sessionId) {
			// eslint-disable-next-line no-param-reassign
			config.data = { ...config.data, sessionId };
		}

		return config;
	},

	(error: AxiosError<IErrorResponse>) => {
		throw error;
	}
);

instance.interceptors.response.use(
	async (res: AxiosResponse) => res,

	async (error: AxiosError<IErrorResponse>) => {
		const status: number | undefined = error.response?.status;

		if (status === 401) {
			window.location.href = `/${ROUTES.login}`;
		}

		return Promise.reject(error);
	}
);

export const API = instance;
