import { FC, useContext, useEffect, useState } from 'react';
import {
 Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Switch, Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import SocketContext, { uploadFiles } from 'socket/DataSettingsSocket/context';
import {
	dataSettings,
	getSingleReceiptStatusSelector,
	getStatusSingleReceipt, initRedis,
	uploadSingleReceiptStatus,
} from 'store/data-settings';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { UploadFile } from 'components/modules/UploadFile';
import { FILE_FORMAT } from 'utils/enums';
import { checkStatusFile, checkStatusOption,isSomeProcessInProgress } from "utils/helpers";
import { initialValues, IParserForm, RadioInputOptions, validationSchema } from './config';

export const SettingsForm: FC = () => {
	const singleReceiptStatus = useAppSelector(getSingleReceiptStatusSelector);
	const { processesStatus } = useContext(SocketContext);
	const dispatch = useAppDispatch();

	const handleSubmit = async (values: IParserForm) => {
		await initRedis()
		dispatch(dataSettings(values));
	};

	const handleToggleSingleReceiptStatus = () => {
		dispatch(uploadSingleReceiptStatus(!singleReceiptStatus));
	};

	useEffect(() => {
		dispatch(getStatusSingleReceipt());
	}, [dispatch, singleReceiptStatus]);

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ errors, handleChange, values }) => (
				<Form id="data">
					<Box>
						<FormControl component="fieldset" disabled={isSomeProcessInProgress(processesStatus)}>
							<RadioGroup
								aria-label="config-settings"
								name="radioValue"
								value={ values.radioValue }
								sx={{ display: 'flex', gap: '4px' }}
								onChange={(e: any) => {
									handleChange(e);
								}}
							>
								<FormControlLabel
									value={RadioInputOptions.ETL_FILES_PARSING}
									control={<Radio />}
									label="Запустити парсинг файлів"
								/>
								{/*<Box sx={{ display: 'flex', gap: '24px', alignItems: 'center' }}>*/}
								{/*	<Typography variant="body2" color={checkStatusAllObj(processesStatus) ? 'text.disabled' : 'text.primary'}>*/}
								{/*		Мануальний парсинг*/}
								{/*	</Typography>*/}
								{/*	/!*<Switch checked={false} disabled={values.radioValue !== RadioInputOptions.ETL_FILES_PARSING} />*!/*/}
								{/*	<Typography variant="body2" color={checkStatusAllObj(processesStatus) ? 'text.disabled' : 'text.primary'}>*/}
								{/*		Автоматичний парсинг*/}
								{/*	</Typography>*/}
								{/*</Box>*/}
								<UploadFile
									fileFormat={FILE_FORMAT.DBF}
									fieldName="kvbo"
									title="Постачальник КВБО"
									isDisabled={isSomeProcessInProgress(processesStatus) || checkStatusFile(processesStatus, uploadFiles.kvbo)}
								/>
								<UploadFile
									fileFormat={FILE_FORMAT.TXT}
									fieldName="hvd"
									title="Постачальник ХВД"
									isDisabled={isSomeProcessInProgress(processesStatus) || checkStatusFile(processesStatus, uploadFiles.kvd)}
								/>
								<UploadFile
									fileFormat={FILE_FORMAT.DBF}
									fieldName="hts"
									title="Постачальник ХТС"
									isDisabled={isSomeProcessInProgress(processesStatus) || checkStatusFile(processesStatus, uploadFiles.kts)}
								/>
								<UploadFile
									fileFormat={FILE_FORMAT.CSV}
									fieldName="jks"
									title="Постачальник ЖКС"
									isDisabled={isSomeProcessInProgress(processesStatus) || checkStatusFile(processesStatus, uploadFiles.hcs)}
								/>
								<Divider />
								<FormControlLabel
									value={RadioInputOptions.ETL_UNDEFINED_DATA}
									control={<Radio />}
									label="Необроблені дані"
									disabled={true}
								/>
								<Divider />
								<FormControlLabel
									value={RadioInputOptions.BILL_CREATE_SINGLE_RECEIPT}
									control={<Radio />}
									label="Відправка даних КП на Біл" // TODO: etlMapAndSendToBill
									disabled={isSomeProcessInProgress(processesStatus) || checkStatusOption(processesStatus?.etlMapAndSendToBill)}
								/>
								<Divider />
								<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<FormControlLabel
										value={RadioInputOptions.REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT}
										control={<Radio />}
										disabled={isSomeProcessInProgress(processesStatus) || checkStatusOption(processesStatus?.billSingleReceiptsSendToReport)}
										label="Відправка ЄК на репорт" // TODO: billSingleReceiptsSendToReport
									/>
								</Box>
								<Divider />
								<FormControlLabel
									value={RadioInputOptions.TEMP}
									control={<Radio />}
									label="Генерація pdf для типографії" // TODO: reportTypographySingleReceiptCreate
									disabled={isSomeProcessInProgress(processesStatus) || checkStatusOption(processesStatus?.reportTypographySingleReceiptCreate)}
								/>
							</RadioGroup>
						</FormControl>
					</Box>
					{errors.radioValue && (
						<Typography variant="body2" color="secondary">
							{errors.radioValue}
						</Typography>
					)}
				</Form>
			)}
		</Formik>
	);
};
