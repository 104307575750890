import LogoImg from 'assets/images/logo.png';
import { Content, ContentText, CustomTableLayout, TableCell, TableCellProperties } from 'pdfmake/interfaces';
import { RECEIPT_DOCUMENT_COLOR } from 'utils/constants';
import { PDF_SIZE } from 'utils/enums';

const convertImageToBase64 = async (imageUrl: string): Promise<string> => {
	try {
		const response = await fetch(imageUrl);
		const blob = await response.blob();
		const reader = new FileReader();

		return new Promise((resolve, reject) => {
			reader.onloadend = () => {
				// When the file reading is complete, resolve with the base64 representation
				resolve(reader.result as string);
			};

			// Read the blob as a data URL (base64)
			reader.readAsDataURL(blob);

			reader.onerror = () => {
				// If there's an error during reading, reject the promise
				reject(new Error('Error reading image file.'));
			};
		});
	} catch (error) {
		throw new Error('Error fetching image:', error);
	}
};

export const getDocumentHeaderDefinitions = async (): Promise<Content> => {
	const logo = await convertImageToBase64(LogoImg);

	return {
		table: {
			widths: ['*', 'auto'],
			body: [
				[
					{
						image: logo,
						width: 66,
						fillColor: RECEIPT_DOCUMENT_COLOR.PRIMARY,
					},
					{
						stack: [
							{ text: '044 785-45-78', fontSize: PDF_SIZE.SIZE_36, bold: true },
							{ text: 'hostpay.com.ua', fontSize: PDF_SIZE.SIZE_32, bold: true },
						],
						fillColor: RECEIPT_DOCUMENT_COLOR.PRIMARY,
						alignment: 'left',
						color: '#FFFFFF',
					},
				],
			],
		},
		layout: {
			defaultBorder: false,
			paddingLeft() {
				return PDF_SIZE.SIZE_24;
			},
			paddingRight() {
				return PDF_SIZE.SIZE_24;
			},
			paddingTop() {
				return PDF_SIZE.SIZE_48;
			},
			paddingBottom() {
				return PDF_SIZE.SIZE_48;
			},
		},
	};
};

export const getDocumentHostDataSubheader = (): Content[] => [
	{
		text: 'ТОВ «ХОСТПЕЙ»',
		marginBottom: 2,
	},
	{
		text: 'ЄДРПОУ: 39763909',
		marginBottom: 2,
	},
	{
		text: 'Ліцензія НБУ № 21 від 02.05.2023 р.',
		marginBottom: 2,
	},
	{
		text: 'тел. +380 (482) 30 00 32',
		marginBottom: 2,
	},
	{
		text: 'hostpay@com.ua',
		marginBottom: PDF_SIZE.SIZE_24,
	},
];

export const getDividerDefinitions = (columns: number, color = RECEIPT_DOCUMENT_COLOR.PRIMARY): TableCell[] =>
	Array(columns)
		.fill('')
		.map(() => ({
			text: '',
			colSpan: columns,
			rowSpan: 1,
			fillColor: color,
			border: [false, false, false, false],
			margin: [2, 2, 2, 2],
		}));

export const getProviderTableLayout = (thickness = 0.5, color = RECEIPT_DOCUMENT_COLOR.BORDER): CustomTableLayout => ({
	...getProviderTableLayoutEmptyPaddingDefinition(),
	hLineWidth: () => thickness,
	vLineWidth: () => thickness,
	hLineColor: () => {
		return color;
	},
	vLineColor: () => {
		return color;
	},
});

export const getProviderTableLayoutEmptyPaddingDefinition = (
	index?: undefined | number,
	value = 2
): CustomTableLayout => {
	const getPaddingValue = (indexOfRow: number) => {
		if (index === indexOfRow || index === undefined) return 0;
		return value;
	};

	return {
		paddingLeft: getPaddingValue,
		paddingRight: getPaddingValue,
		paddingTop: getPaddingValue,
		paddingBottom: getPaddingValue,
	};
};

export const getTableHeaderDefinitions = (
	headingsList: string[],
	color = RECEIPT_DOCUMENT_COLOR.SECONDARY,
	margin = [3, 3, 3, 3]
): TableCell[] =>
	headingsList.map((item) => ({
		text: item,
		bold: true,
		fontSize: PDF_SIZE.SIZE_16,
		fillColor: color,
		margin,
	}));

export const getTableRowDefinitions = (
	values: string[],
	margin = [3, 3, 3, 3] as [number, number, number, number]
): (TableCellProperties & ContentText)[] =>
	values.map((item) => ({
		text: item,
		fontSize: PDF_SIZE.SIZE_14,
		margin,
	}));
