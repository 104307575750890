import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	IGetUserDetailsResponse,
	IRequestDeleteCard,
	IRequestUpdateUserStatus,
	IUpdateUserRequest,
	IUserCardsListResponse,
	IUserDetailsApartmentsResponse,
	IUsersRequest,
	IUsersResponse,
} from 'types';
import { getErrorResponse } from 'utils/helpers';
import { UsersService } from 'utils/services';

export const getUsersList = createAsyncThunk(
	'usersSlice/getUsersList',
	async (reqBody: IUsersRequest, { rejectWithValue }) => {
		try {
			const usersResponse: AxiosResponse<IUsersResponse> = await UsersService.getUsersList(reqBody);
			return usersResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getUserById = createAsyncThunk('usersSlice/getUserById', async (id: string, { rejectWithValue }) => {
	try {
		const userResponse: AxiosResponse<IGetUserDetailsResponse> = await UsersService.getUserById(id);
		return userResponse.data;
	} catch (error) {
		return rejectWithValue(getErrorResponse(error));
	}
});

export const updateUserById = createAsyncThunk(
	'usersSlice/updateUserById',
	async (reqBody: IUpdateUserRequest, { rejectWithValue }) => {
		try {
			const userResponse: AxiosResponse = await UsersService.updateUserById(reqBody);
			return userResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const deleteUserById = createAsyncThunk(
	'usersSlice/deleteUserById',
	async ({ userId, type }: { userId: string; type: string }, { rejectWithValue }) => {
		try {
			await UsersService.deleteUserById(userId, type);
			return userId;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const createUser = createAsyncThunk(
	'usersSlice/createUser',
	async (reqBody: IUpdateUserRequest, { rejectWithValue }) => {
		try {
			const userResponse: AxiosResponse<IGetUserDetailsResponse> = await UsersService.createUser(reqBody);
			return userResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getApartment = createAsyncThunk(
	'usersSlice/getApartment',
	async (reqBody: string, { rejectWithValue }) => {
		try {
			const { data }: AxiosResponse<IUserDetailsApartmentsResponse> = await UsersService.getApartment(reqBody);
			return data.tableData;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getCardList = createAsyncThunk('usersSlice/getCardList', async (userId: string, { rejectWithValue }) => {
	try {
		const { data }: AxiosResponse<IUserCardsListResponse> = await UsersService.getCardList(userId);
		return data.cards;
	} catch (error) {
		return rejectWithValue(getErrorResponse(error));
	}
});

export const deleteCardById = createAsyncThunk(
	'usersSlice/deleteCard',
	async (reqBody: IRequestDeleteCard, { rejectWithValue }) => {
		try {
			await UsersService.deleteCardById(reqBody);
			return reqBody;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateUserStatusBlock = createAsyncThunk(
	'usersSlice/updateUserStatusBlock',
	async (reqBody: IRequestUpdateUserStatus, { rejectWithValue }) => {
		try {
			await UsersService.updateUserStatusBlock(reqBody);
			return reqBody;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateUserStatusBlockTemporary = createAsyncThunk(
	'usersSlice/updateUserStatusBlockTemporary',
	async (reqBody: IRequestUpdateUserStatus, { rejectWithValue }) => {
		try {
			await UsersService.updateUserStatusBlockTemporary(reqBody);
			return reqBody;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
