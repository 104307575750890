import { FC } from 'react';
import { Box, Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { IUserActivityFilters } from 'types';
import { DATE_FORMAT } from 'utils/enums';
import { USER_ACTIVITY_METHODS_OPTIONS } from 'utils/constants';
import { CustomDatePickerFormik, CustomSelectFormik } from 'components/elements';
import { shouldDisableMonth, disablePastYear } from 'pages/OtherReceiptsPage/config';
import s from './index.module.scss';

interface UserActivityFiltersProps {
	initialValues: IUserActivityFilters;
	onSubmitFilters: (values: IUserActivityFilters) => void;
	onResetFilters: () => void;
}

export const UserActivityFilters: FC<UserActivityFiltersProps> = ({
	initialValues,
	onSubmitFilters,
	onResetFilters,
}) => {
	return (
		<Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitFilters}>
			<Form className={s.filter}>
				<Box className={s.date__filters}>
					<Field
						name="gte"
						views={['year', 'month', 'day']}
						format={DATE_FORMAT.DAY_MONTH_NAME_YEAR}
						shouldDisableMonth={shouldDisableMonth}
						shouldDisableYear={disablePastYear}
						className={s.date__input}
						component={CustomDatePickerFormik}
					/>
					<span>-</span>
					<Field
						name="lte"
						views={['year', 'month', 'day']}
						format={DATE_FORMAT.DAY_MONTH_NAME_YEAR}
						shouldDisableMonth={shouldDisableMonth}
						shouldDisableYear={disablePastYear}
						className={s.date__input}
						component={CustomDatePickerFormik}
					/>
				</Box>
				<Field
					className={s.select}
					name="methods"
					placeholder="Оберіть методи"
					options={USER_ACTIVITY_METHODS_OPTIONS}
					component={CustomSelectFormik}
					multiple
				/>
				<Button variant="contained" type="submit">
					Застосувати
				</Button>
				<Button onClick={onResetFilters} variant="outlined" type="reset">
					Очистити
				</Button>
			</Form>
		</Formik>
	);
};
