import { useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import editIcon from 'assets/icons/edit.svg';
import { useAppSelector } from "store/hooks";
import { getRightsSelector } from "store/rights-list";
import { getRolesSelector } from "store/roles-list";
import { IDetailsItem, IDetailsSetItem } from "types";
import styles from './index.module.scss';

type RightListPropsType<T, K> = {
    handleSelect: (item: IDetailsSetItem<T>) => void,
    lockedFields: T[],
    detailsData: K,
    item: IDetailsItem<T>,
    detailsTitles: IDetailsItem<T>[],
    index: number,
};

// TODO Should be reworked as well as apps/admin/src/components/modules/DetailsContent/index.tsx
export const RolesAndRightsList = <T, K>({
    handleSelect,
    lockedFields,
    detailsData,
    item,
    detailsTitles,
    index,
}: RightListPropsType<T, K>) => {
    const value = detailsData[item.key as keyof K] as any;

    const rights = useAppSelector(getRightsSelector)?.tableData;
    const roles = useAppSelector(getRolesSelector)?.tableData;


    const selectedRights = useMemo(() => {
        const entries = value ?? [];
        return (
            [...(roles ?? []), ...(rights ?? [])]
                .filter((item: any) => (entries.includes(item.id ?? item.name)))
                .map((item) => item.description) ?? []);
    }, [roles, rights, value]);



    return (<Box key={item.key}>

        <Box
            className={styles.page__details_item}
            onClick={() =>
                !lockedFields.includes(item.key) &&
                handleSelect({ value: value ?? [], ...item })
            }
        >
            <Typography variant="h6">{item.title}</Typography>
            <Box className={styles.page__details_editor}>
                <Typography variant="h5" align="right">{selectedRights.join(', ')}</Typography>
                {!lockedFields.includes(item.key) && <Box component="img" src={editIcon} alt='edit' />}
            </Box>
        </Box>
        <Box className={styles.page__details_divider}>{detailsTitles.length - 1 !== index && <Divider />}</Box>
    </Box>);
}
