import * as Yup from 'yup';

export interface IForgotFormValues {
	email: string;
}

export const initialValues: IForgotFormValues = {
	email: '',
};

export const validationSchema = Yup.object().shape({
	email: Yup.string().required('Email is required').email('Invalid email address'),
});

export const infoWithEmailMask = (email: string) => {
	const atIndex = email.indexOf('@');
	const maskedEmail = `${email.substring(0, 1)  }*****${  email.substring(atIndex)}`;
	return `На вашу пошту ${maskedEmail} було відправлено посилання для відновлення пароля. Будь ласка перевірте вашу пошту`;
};
