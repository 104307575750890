import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { DataGrid, GridPaginationModel, GridSortDirection, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { AxiosResponse } from "axios";
import { ROUTES } from "routes";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	getRejectedDataIsLoadingSelector,
	getRejectedDataList,
	getRejectedDataSelector
} from "store/rejected-data";
import { clearStreetsData } from 'store/streets';
import { IQuery } from 'types';
import { CustomSelect } from "components/elements";
import { PAGINATION_LIMIT } from 'utils/constants';
import { DATE_FORMAT, SORT_DIR, SPECIFY_DATA_FIELDS_NAME } from 'utils/enums';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { formatDateView } from "utils/helpers/date";
import { getTableSetting } from "utils/helpers/table-setting";
import { useColumns } from 'utils/hooks/use-selection-fields';
import { RejectedDataService } from "utils/services/rejected.service";
import { COLUMNS_CONFIG } from './config';
import s from './index.module.scss';

//TODO IMPROVE THE LOGIC TO MOVE THE CODE AND AUTOMATE IT

export const RejectedDataPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const rejectedData = useAppSelector(getRejectedDataSelector);
	const isLoading = useAppSelector(getRejectedDataIsLoadingSelector);

	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);
	const [inputValue, setInputValue] = useState<any>([]);
	const [isAutocompleteLoading, setIsAutocompleteLoading] = useState({
		district: false,
		street: false,
		house: false,
		flat: false,
	});

	const [options, setOptions] = useState<{
		district: string[];
		street: string[];
		house: string[];
		flat: string[];
	}>({
		district: [],
		street: [],
		house: [],
		flat: [],
	});

	const requestBody = useMemo(
		() => ({
			offset: (query.page || 0) * (query.pageSize ||  0),
			limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			orderBy: [{
				field: query.field,
				direction: query.sort || SORT_DIR.ASC,
			}],
			searchBy: {
				city: query.district,
				street: query.street,
				house: query.house && `${query.house}`,
				flat: query.flat && `${query.flat}`,
				general: query.searchQuery && `${query.searchQuery}`,
				reasons: inputValue?.length ? inputValue : undefined,
			},
			deviceFP: "6037e65eefbdasdfadsfasdf3"
		}),
		[query, inputValue]
	);

	useEffect(() => {
		dispatch(getRejectedDataList(requestBody));
	}, [requestBody, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearStreetsData());
		};
	}, [dispatch]);

	useEffect(() => {
		query.district && handleSearchStreet(query.district)
	}, [query.district])

	useEffect(() => {
		query.district && query.street && handleSearchHouse(query.street)
  }, [query.street])

	useEffect(() => {
		query.district && query.street && query.house && handleSearchFlat(query.house)
	}, [query.house]);

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.rejectedData));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.rejectedData, JSON.stringify(newWidths));
	};

	const modifiedSpecifyData = useMemo(
		() =>
			rejectedData?.results.map((data) => ({
				...data,
				provider: data.provider.name,
				reasons: data.reasons.replace(/Unprocessed value\.|Unprocessable data\./g, '').trim(),
				createdAt: formatDateView(data.createdAt, DATE_FORMAT.DATE_FULL)
			})),
		[rejectedData]
	);

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	const handleAutocompleteChange = (fieldName: string, value: string | null): void => {
		switch (fieldName) {
			case ('district'): {
				navigate(
					{
						search: formatQueryString({
							[fieldName]: value
						}),
					},
					{ replace: true }
				);
				break;
			}
			case ('street'): {
				navigate(
					{
						search: formatQueryString({
							'district': query.district,
							[fieldName]: value,
							'house': ''
						}),
					},
					{ replace: true }
				);
				break;
			}
			case ('house'): {
				navigate(
					{
						search: formatQueryString({
							'district': query.district,
							'street': query.street,
							[fieldName]: value
						}),
					},
					{ replace: true }
				);
				break;
			}
			case ('flat'): {
				navigate(
					{
						search: formatQueryString({
							...query,
							[fieldName]: value
						}),
					},
					{ replace: true }
				);
				break;
			}
		}
	};

	const handleSearchDistrict = async (value: string | null): Promise<void> => {
		try {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, district: true });
			const reqBody = {
				city: value,
			};
			const response: AxiosResponse<string[]> = await RejectedDataService.getRejectedDistinctCities(reqBody);
			setOptions({ ...options, district: response.data });
		} catch (error) {
			console.log(error);
		} finally {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, district: false });
		}
	};

	const handleSearchStreet = async (value: string): Promise<void> => {
		try {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, street: true });
			const reqBody = {
				city: value,
			};
			const response: AxiosResponse<string[]> = await RejectedDataService.getRejectedDataDistinctStreet(reqBody);
			setOptions({ ...options, street: response.data });
		} catch (error) {
			console.log(error);
		} finally {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, street: false });
		}
	};

	const handleSearchHouse = async (value: string): Promise<void> => {
		try {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, house: true });
			const reqBody = {
				city: query.district,
				street: value,
			};
			const response: AxiosResponse<string[]> = await RejectedDataService.getRejectedDataDistinctHouse(reqBody);
			setOptions({ ...options, house: response.data });
		} catch (error) {
			console.log(error);
		} finally {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, house: false });
		}
	};

	const handleSearchFlat = async (value: string): Promise<void> => {
		try {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, flat: true });
			const reqBody = {
				city: query.district,
				street: query.street,
				house: `${query.house}`,
			};
			const response: AxiosResponse<string[]> = await RejectedDataService.getRejectedDataDistinctFlat(reqBody);
			setOptions({ ...options, flat: response.data });
		} catch (error) {
			console.log(error);
		} finally {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, flat: false });
		}
	};

	const rejectReasonOption = [
		{ value: 'APARTMENT_HAS_MULTIPLE_PROVIDER_ACCOUNT', title: 'Має кілька провайдерів' },
		{ value: 'CITY_UNSUPPORTED', title: 'Місто не підтримується' },
		{ value: 'STREET_TYPE_UNSUPPORTED', title: 'Тип вулиці не підтримується' },
		{ value: 'STREET_UNSUPPORTED', title: 'Вулиця не підтримується' },
		{ value: 'HOUSE_NUMBER_INVALID', title: 'Не коректний номер будинку' },
		{ value: 'FLAT_NUMBER_INVALID', title: 'Не коректний номер квартири' }
	];

	const handleSearchClear = () => {
		navigate(
			{
				search: formatQueryString({
					query: '',
				}),
			},
			{ replace: true }
		);
	};

	const handleChangeSelect = (event: any) => {
		setInputValue(event.target.value)
	}

	return (
		<>
			<Box className={s.tableActions}>
				<Box className={s.tableActions__items}>
					<Box className={s.tableActions__apartments}>
						<Autocomplete
							noOptionsText="Не знайдено"
							options={options.district}
							renderInput={(params) => <TextField label="Населений пункт" {...params} />}
							getOptionLabel={(option: string) => option}
							onFocus={() => handleSearchDistrict(null)}
							onInputChange={(_, value) => handleSearchDistrict(value)}
							onChange={(_, value, reason) => handleAutocompleteChange('district', value)}
							sx={{
								'& .MuiOutlinedInput-root': {
									padding: 0,
								},
							}}
							value={query.district || ''}
						/>
						<Autocomplete
							noOptionsText="Не знайдено"
							options={options.street}
							renderInput={(params) => <TextField label="Вуллиця" {...params} />}
							getOptionLabel={(option: string) => option}
							disabled={!query.district}
							onChange={(_, value, reason) => handleAutocompleteChange('street', value)}
							sx={{
								'& .MuiOutlinedInput-root': {
									padding: 0,
								},
							}}
							value={query.street || ''}
						/>
						<Autocomplete
							noOptionsText="Не знайдено"
							options={options.house}
							renderInput={(params) => <TextField label="Будинок" {...params} />}
							getOptionLabel={(option: string) => option}
							disabled={!query.street}
							onChange={(_, value, reason) => handleAutocompleteChange('house', value)}
							sx={{
								'& .MuiOutlinedInput-root': {
									padding: 0,
								},
							}}
							value={query.house || ''}
						/>
						<Autocomplete
							noOptionsText="Не знайдено"
							options={options.flat}
							renderInput={(params) => <TextField label="Квартира" {...params} />}
							getOptionLabel={(option: string) => option}
							disabled={!query.house}
							onChange={(_, value) => handleAutocompleteChange('flat', value)}
							sx={{
								'& .MuiOutlinedInput-root': {
									padding: 0,
								},
							}}
							value={query.flat || ''}
						/>
					</Box>
					<Button variant="contained" disabled={!(query.district)} onClick={handleSearchClear}>
						Очистити
					</Button>
				</Box>
			</Box>
			<Box className={s.select}>
				<Box>
					<Box className={s.select__label}>Причина відхилиння</Box>
					<CustomSelect
						multiple
						value={inputValue}
						name="Причина"
						onChange={handleChangeSelect}
						placeholder="Причина відхилення"
						options={rejectReasonOption}
					/>
				</Box>
			</Box>
			<Box className={s.tableWrapper}>
				<DataGrid
					onColumnResize={handleColumnResize}
					apiRef={apiRef}
					disableColumnFilter
					disableRowSelectionOnClick
					rows={modifiedSpecifyData || []}
					columns={useColumns(COLUMNS_CONFIG)}
					rowCount={rejectedData?.total || 0}
					loading={isLoading}
					initialState={{
						pagination: {
							paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
						},
						sorting: {
							sortModel: [
								{ field: query.field || SPECIFY_DATA_FIELDS_NAME.STREET, sort: query.sort as GridSortDirection },
							],
						},
					}}
					slotProps={{
						pagination: {
							labelRowsPerPage: 'Показувати :',
							page: query.page || 0,
							rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
						},
					}}
					getCellClassName={(params) => `${params.field === 'reasons' ? 'wrap-text' : ''}`}
					paginationMode="server"
					sortingMode="server"
					onSortModelChange={handleSortField}
					onPaginationModelChange={handleChangePage}
					className={s.table}
					pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
				/>
			</Box>
		</>
	);
};
