import { createSlice } from '@reduxjs/toolkit';
import { IRightsReducer } from 'types';
import { getRights } from "./actions";

const initialState: IRightsReducer = {
    rightsList: null,
    isLoading: true,
};

const rightSlice = createSlice({
    name: 'rightSlice',
    initialState,
    reducers: {
        clearRoleData: (state) => {
            state.rightsList = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRights.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRights.fulfilled, (state, { payload }) => {
            state.rightsList = payload;
            state.isLoading = false;
        });
        builder.addCase(getRights.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const rightsReducer = rightSlice.reducer;
