import { FC } from "react";
import classNames from "classnames";
import s from './index.module.scss';

interface FormattedJSONComponentProps {
    value: string;
    className?: string;
}

export const FormattedJSONComponent:FC<FormattedJSONComponentProps> = ({ value, className = '' }) => (
	<pre className={classNames(s.content, className)}>{JSON.stringify(JSON.parse(value), null, 2)}</pre>
);
