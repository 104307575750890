import { createSlice } from '@reduxjs/toolkit';
import { ISingleReceiptsReducer } from 'types';
import { getSingleReceiptById, getSingleReceiptsList } from './actions';

const initialState: ISingleReceiptsReducer = {
	isLoading: true,
	singleReceiptsList: null,
	singleReceiptsDetails: null,
};

const singleReceiptsSlice = createSlice({
	name: 'singleReceiptsSlice',
	initialState,
	reducers: {
		clearSingleReceiptsData: (state) => {
			state.singleReceiptsList = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSingleReceiptsList.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getSingleReceiptsList.fulfilled, (state, { payload }) => {
			state.singleReceiptsList = payload;
			state.isLoading = false;
		});
		builder.addCase(getSingleReceiptsList.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getSingleReceiptById.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getSingleReceiptById.fulfilled, (state, { payload }) => {
			state.singleReceiptsDetails = payload;
			state.isLoading = false;
		});

		builder.addCase(getSingleReceiptById.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { clearSingleReceiptsData } = singleReceiptsSlice.actions;
export const singleReceiptsReducer = singleReceiptsSlice.reducer;
