// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../fonts/FixelDisplay/FixelDisplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  font-family: "Fixel Display";
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

@font-face {
  font-family: "Fixel Display";
  src: local("FixelDisplay"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
.styles_input__u1g-K {
  max-width: 342px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/base/_resets.scss","webpack://./src/components/modules/SearchBar/styles.module.scss","webpack://./src/styles/base/_fonts.scss"],"names":[],"mappings":"AAAA;;;EAGC,sBAAA;ACCD;;ADEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EAmDC,SAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,wBAAA;EACA,kCAAA;EACA,mCAAA;EACA,sBAAA;ACCD;;ADEA;;;;;EAKC,cAAA;ACCD;;ADEA;EACC,4BAAA;EACA,cAAA;ACCD;;ADEA;;EAEC,gBAAA;ACCD;;ADEA;;EAEC,YAAA;ACCD;;ADEA;;;;EAIC,WAAA;EACA,aAAA;ACCD;;ADEA;EACC,yBAAA;EACA,iBAAA;ACCD;;ADEA;EACC,wBAAA;EACA,gBAAA;ACCD;;AC3GA;EACC,4BAAA;EACA,sFAAA;AD8GD;AA9GA;EACC,gBAAA;EACA,WAAA;AAgHD","sourcesContent":["*,\n*:before,\n*:after {\n\tbox-sizing: border-box;\n}\n\nhtml,\nbody,\ndiv,\nspan,\nobject,\niframe,\nfigure,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nblockquote,\npre,\na,\ncode,\nem,\nimg,\nsmall,\nstrike,\nstrong,\nsub,\nsup,\ntt,\nb,\nu,\ni,\nol,\nul,\nli,\nfieldset,\nform,\nlabel,\ntable,\ncaption,\ntbody,\ntfoot,\nthead,\ntr,\nth,\ntd,\nmain,\ncanvas,\nembed,\nfooter,\nheader,\nnav,\nsection,\nvideo {\n\tmargin: 0;\n\tpadding: 0;\n\tborder: 0;\n\tfont-size: 100%;\n\tfont: inherit;\n\tvertical-align: baseline;\n\ttext-rendering: optimizeLegibility;\n\t-webkit-font-smoothing: antialiased;\n\ttext-size-adjust: none;\n}\n\nfooter,\nheader,\nnav,\nsection,\nmain {\n\tdisplay: block;\n}\n\nbody {\n\tfont-family: 'Fixel Display';\n\tline-height: 1;\n}\n\nol,\nul {\n\tlist-style: none;\n}\n\nblockquote,\nq {\n\tquotes: none;\n}\n\nblockquote:before,\nblockquote:after,\nq:before,\nq:after {\n\tcontent: '';\n\tcontent: none;\n}\n\ntable {\n\tborder-collapse: collapse;\n\tborder-spacing: 0;\n}\n\ninput {\n\t-webkit-appearance: none;\n\tborder-radius: 0;\n}\n","@import 'styles';\n\n.input {\n\tmax-width: 342px;\n\twidth: 100%;\n}\n","@font-face {\n\tfont-family: 'Fixel Display';\n\tsrc: local('FixelDisplay'), url('../../fonts/FixelDisplay/FixelDisplay-Regular.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `styles_input__u1g-K`
};
export default ___CSS_LOADER_EXPORT___;
