import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IParserForm, RadioInputOptions } from 'pages/DataSettingsPage/DataSettings/DataSettingForm/config';
import { StepOfCurrentParser } from 'utils/enums';
import { getErrorResponse } from 'utils/helpers';
import { DataSettingsService } from 'utils/services';

export const initRedis = createAsyncThunk('dataSettingsSlice/initRedis', async (_, { rejectWithValue }) => {
	try {
		await DataSettingsService.initRedis();
		return null;
	} catch (error) {
		return rejectWithValue(getErrorResponse(error));
	}
});

export const pushNotification = createAsyncThunk(
	'dataSettingsSlice/initRedis',
	async (_, { rejectWithValue }) => {
		try {
			await DataSettingsService.pushNotification();
			return null;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
});

export const dataSettings = createAsyncThunk(
	'dataSettingsSlice/dataSettings',
	async (values: IParserForm, { rejectWithValue }) => {
		try {
			switch (values.radioValue) {
				case RadioInputOptions.ETL_FILES_PARSING:
					await Promise.all(
						Object.entries(values).map(async ([fieldName, file]) => {
							if (fieldName !== 'radioValue' && file) {
								const formData = new FormData();
								formData.append('file', file);
								try {
									const dataSettingsParseResponse: AxiosResponse<string> = await DataSettingsService.etlFilesParsing(
										fieldName,
										formData
									);

									return dataSettingsParseResponse;
								} catch (error) {
									console.error('Error uploading file:', error);
									throw error;
								}
							}
							return null;
						})
					);
					break;
				case RadioInputOptions.ETL_UNDEFINED_DATA:
					const dataUndefinedProviderResponse: AxiosResponse<string> =
						await DataSettingsService.undefinedProviderDataParsing();
					return dataUndefinedProviderResponse;

				case RadioInputOptions.BILL_CREATE_SINGLE_RECEIPT:
					const dataGenerateOnBillResponse: AxiosResponse<string> = await DataSettingsService.generateDataOnBill();
					return dataGenerateOnBillResponse;

				case RadioInputOptions.REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT:
					const dataGenerateReportResponse: AxiosResponse<string> =
						await DataSettingsService.dataGenerateSingleReceipt();
					return dataGenerateReportResponse;
			}
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const stopParse = createAsyncThunk(
	'dataSettingsSlice/stopParse',
	async (currentStep: StepOfCurrentParser | null, { rejectWithValue }) => {
		try {
			switch (currentStep) {
				case StepOfCurrentParser.ETL_FILES_PARSING:
					await DataSettingsService.stopETLFilesParsing();
					break;
				case StepOfCurrentParser.ETL_UNDEFINED_DATA:
					await DataSettingsService.stopETLUndefinedDataParsing();
					break;
				case StepOfCurrentParser.BILL_CREATE_SINGLE_RECEIPT:
					await DataSettingsService.stopGenerateSingleReceiptOnBill();
					break;
				case StepOfCurrentParser.REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT:
					await DataSettingsService.stopGeneratePDFSingleReceipt();
					break;
				default:
					break;
			}
			return null;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getStatusSingleReceipt = createAsyncThunk(
	'dataSettingsSlice/statusSingleReceipt',
	async (_, { rejectWithValue }) => {
		try {
			const response = await DataSettingsService.getSingleReceiptStatus();
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const uploadSingleReceiptStatus = createAsyncThunk(
	'dataSettingsSlice/uploadSingleReceiptStatus',
	async (status: boolean, { rejectWithValue }) => {
		try {
			await DataSettingsService.uploadSingleReceiptStatus(status);
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const auditStatusesReset = createAsyncThunk(
	'dataSettingsSlice/auditStatusesReset',
	async (_, { rejectWithValue }) => {
		try {
			const data = await DataSettingsService.auditStatusesReset();
			console.log(data)
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
)
