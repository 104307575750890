import { FC, PropsWithChildren,useEffect, useState } from 'react';
import { io,Socket } from 'socket.io-client';
import { REPORT_STATUS } from 'utils/enums';
import SocketContext from './context';

const URL = process.env.REACT_APP_ADMIN_SOCKET_API_URL;

export const ReportsSocketProvider: FC<PropsWithChildren> = (props) => {
	const [isConnected, setIsConnected] = useState(false);
	const [generate, setReportsStatus] = useState<REPORT_STATUS | string>('');

	const handleReportsStatus = (data: REPORT_STATUS) => {
		setReportsStatus(Object.values(data)[0]);
	};

	const handleConnect = () => {
		setIsConnected(true);
	};

	const initSocket = () => {
		try {
			if (!URL) {
				throw new Error('URL is not defined');
			}
			const socket: Socket = io(URL);

			return socket;
		} catch (error) {
			console.log('Error initializing socket:', error);
		}
	};

	useEffect(() => {
		const socket = initSocket();
		if (socket) {
			socket.on('connect', handleConnect);
			socket.on('generateReportStatus', handleReportsStatus);
		}
		return () => {
			if (socket) {
				socket.off('connect', handleConnect);
				socket.off('generateReportStatus', handleReportsStatus);
			}
		};
	}, []);

	return <SocketContext.Provider value={{ generate, isConnected }}>{props.children}</SocketContext.Provider>;
};
