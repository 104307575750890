import { createSlice } from '@reduxjs/toolkit';
import { IOtherReceiptsReducer } from 'types';
import { getOtherReceiptsList, getOtherReceiptsReport } from './actions';

const initialState: IOtherReceiptsReducer = {
	otherReceiptsList: null,
	OtherReceiptsReport: null,
	isLoading: true,
};

const otherReceiptsSlice = createSlice({
	name: 'otherReceiptsSlice',
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder.addCase(getOtherReceiptsList.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getOtherReceiptsList.fulfilled, (state, { payload }) => {
			state.otherReceiptsList = payload;
			state.isLoading = false;
		});
		builder.addCase(getOtherReceiptsList.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getOtherReceiptsReport.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getOtherReceiptsReport.fulfilled, (state, { payload }) => {
			state.OtherReceiptsReport = payload;
			state.isLoading = false;
		});
		builder.addCase(getOtherReceiptsReport.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const otherReceiptsReducer = otherReceiptsSlice.reducer;
