import { createSlice } from '@reduxjs/toolkit';
import { IProvidersReducer } from 'types';
import { getProviders } from './actions';

const initialState: IProvidersReducer = {
	isLoading: true,
	data: null,
};

const providersSlice = createSlice({
	name: 'providersSlice',
	initialState,
	reducers: {
		clearProvidersData: (state) => {
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getProviders.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getProviders.fulfilled, (state, { payload }) => {
			state.data = payload;
			state.isLoading = false;
		});
		builder.addCase(getProviders.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { clearProvidersData } = providersSlice.actions;
export const providersReducer = providersSlice.reducer;
