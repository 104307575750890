import { FC, useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { formatNumber } from 'utils/helpers/numbers-format';
import styles from './index.module.scss';

interface IProgressCircle {
	currentValue: number;
	totalValue: number;
	status: string;
}

export const ProgressCircle: FC<IProgressCircle> = ({ currentValue, totalValue, status }) => {
	const percentage = totalValue ? (currentValue / totalValue) * 100 : 0;

	const totalNumber = useMemo(() => formatNumber(totalValue), [totalValue]);
	const currentNumber = useMemo(() => formatNumber(currentValue), [currentValue]);

	return (
		<Box className={styles.progress}>
			<CircularProgress
				size={230}
				value={100}
				variant="determinate"
				thickness={2}
				sx={{ position: 'absolute', color: 'primary.light' }}
			/>
			<CircularProgress size={230} value={percentage} variant="determinate" thickness={2} />
			<Box className={styles.progress__info}>
				<Typography variant="h4" color="text.primary">
					{status}
        </Typography>
				<Typography variant="h2" color="text.primary">
					{currentNumber}
				</Typography>
				<Typography variant="body2">З</Typography>
				<Typography variant="body1" color="text.primary">
					{totalNumber}
				</Typography>
			</Box>
		</Box>
	);
};
