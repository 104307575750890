export enum ADDRESS_DETAILS_KEYS {
	CITY = 'city',
	STREET_TYPE = 'streetType',
	STREET = 'street',
	HOUSE = 'house',
	FLAT = 'flat',
	ID = 'id',
}

export enum USER_DETAILS_KEYS {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	TYPE = 'type',
	EMAIL = 'email',
	STATUS = 'status',
	PHONE = 'phone',
	ROLES = 'roles',
	CREATED_AT = 'createdAt',
	LOGGED_AT = 'loggedAt',
}

export enum ROLE_DETAILS_KEYS {
	NAME = 'name',
	DESCRIPTION = 'description',
	RIGHTS = 'rights',
	STATUS = 'status',
}

export enum RIGHT_DETAILS_KEYS {
	NAME = 'name',
	DESCRIPTION = 'description',
	STATUS = 'status',
	CREATED_AT = 'createdAt',
	UPDATE_AT = 'updatedAt',
}

export enum USER_PROFILE_KEYS {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	TYPE = 'type',
	EMAIL = 'email',
	PHONE = 'phone',
}

export enum SERVICE_DETAILS_KEYS {
	NAME = 'name',
	STATUS = 'status',
}

export enum SERVICE_DETAILS_REG_KEYS {
	NAME = 'name',
	ZKPO = 'zkpo',
	BANK_NAME = 'bankName',
	BANK_MFO = 'bankMfo',
	ACCOUNT = 'account',
	DESCRIPTION = 'description'
}

export enum SERVICE_DETAILS_FEE_KEYS {
	FEE_MIN = 'feeMin',
	FEE_MAX = 'feeMax',
	SUM_FROM = `sumFrom`,
	SUM_TO = 'sumTo',
	FEE_PERCENT = 'percent',
	FIXED_AMOUNT = 'fixedAmount',
}

export enum EDIT_MODAL_FIELDS {
	STREET = 'street',
	STREET_TYPE = 'streetType',
	STATUS = 'status',
	ROLE = 'type',
	ROLES = 'roles',
	RIGHTS = 'rights',
}

export enum SINGLE_RECEIPT_DETAILS_KEYS {
	PAYMENT_TYPE = 'paymentType',
	CITY = 'city',
	STREET = 'street',
	ID = 'id',
	BALANCE = 'balance',
}

export enum PAYMENTS_MAIN_DETAILS_KEYS {
	AMOUNT = 'amount',
	FEE = 'fee',
    ID = 'id',
	PROVIDER = 'provider',
    STATUS = 'status',
    USER_ID = 'userId',
    APARTMENT_ACCOUNT_ID = 'apartmentAccountId',
    CLIENT_NAME = 'clientName',
	CLIENT_PHONE = 'clientPhone',
    MCC = 'mcc',
    PAY_TYPE = 'payType',
    PAYMENT_ID = 'paymentId',
    PAYMENT_TYPE = 'paymentType',
	SINGLE_RECEIPT_ID = 'singleReceiptId',
}

export enum PAYMENTS_SUB_BILLS_DETAILS_KEYS {
	AMOUNT = 'amount',
	FEE = 'fee',
    PROVIDER = 'provider',
    PROVIDER_TITLE = 'providerTitle',
    SERVICE_ID ='serviceId',
    SERVICE_PROVIDER_ID ='serviceProviderId',
	STATUS = 'status',
	SERVICE_NAME = 'serviceName',
	ACCOUNT = 'account',
	BANK_MFO = 'bankMfo',
    BANK_NAME = 'bankName',
    DESCRIPTION = 'description',
    NAME = 'name',
    ZKPO = 'zkpo',
}
