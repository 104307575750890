import { createContext } from 'react';

export enum AuditProcessesAvailableStatusEnum {
	ACTIVE = 'ACTIVE',
	DISABLE = 'DISABLE',
}

export enum AuditProcessesStatusEnum {
	READY = 'READY',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
	// // STOPPED = 'STOPPED',
	// CRASHED = 'CRASHED',
}

export enum uploadFiles {
	kvbo = 'kvbo',
	kvd = 'kvd',
	kts = 'kts',
	hcs = 'hcs',
}

export interface IAuditGeneralProcessStatus {
	availableStatus: AuditProcessesAvailableStatusEnum;
	status: AuditProcessesStatusEnum;
	counter: {
		totalCount: number;
		processedCount: number;
	};
}
export interface IAuditStatusParsingFilesDto extends IAuditGeneralProcessStatus {
	uploadFiles: {
		kvbo: IAuditGeneralProcessStatus;
		kvd: IAuditGeneralProcessStatus;
		kts: IAuditGeneralProcessStatus;
		hcs: IAuditGeneralProcessStatus;
	};
}

export interface IAuditStatusMappingDataFromFilesDto extends IAuditGeneralProcessStatus {
	providersDada: {
		kvbo: IAuditGeneralProcessStatus;
		kvd: IAuditGeneralProcessStatus;
		kts: IAuditGeneralProcessStatus;
		hcs: IAuditGeneralProcessStatus;
	};
}


export interface IAuditProcessesStatus {
		adminApiDirectUploadParsingFiles?: IAuditStatusParsingFilesDto;
		etlMappingDataFromFiles?: IAuditStatusMappingDataFromFilesDto;
		etlMapAndSendToBill?: IAuditGeneralProcessStatus;
		billSingleReceiptsCreate?: IAuditGeneralProcessStatus;
		billSingleReceiptsSendToReport?: IAuditGeneralProcessStatus;
		reportSingleReceiptsCreate?: IAuditGeneralProcessStatus;
		reportTypographySingleReceiptCreate?: IAuditGeneralProcessStatus;
}

export interface ISocketContextProps {
	isConnected: boolean;
	processesStatus: IAuditProcessesStatus;
}

const SocketContext = createContext<ISocketContextProps>({
	isConnected: false,
	processesStatus: {},
});
export default SocketContext;


