import { ICityDetails } from 'types';
import { STREET_TYPE } from 'utils/enums';

export const getApartmentTitle = (apartment: any): string => {
	const streetType = `${apartment.streetType
		.slice(0, apartment.streetType === STREET_TYPE.STREET ? 3 : 5)
		.toLowerCase()}`;
	const street: string = apartment.street
		.split(' ')
		.map((item: string) => `${item.slice(0, 1).toUpperCase()}${item.slice(1).toLowerCase()}`)
		.join(' ');
	return `${streetType}. ${street}, кв. ${apartment.flat}, м.Харків`;
};

export const getCityWithShortPrefix = (city: ICityDetails): string => {
	const { shorPrefix = '', name = '' } = city;
	return `${shorPrefix} ${name}`;
};
