import { SERVICES_HEADER_TITLES } from 'utils/constants/services';
import { SERVICES_FIELDS_NAME } from 'utils/enums';

export const COLUMNS_CONFIG = [
	{ field: SERVICES_FIELDS_NAME.ID, headerName: SERVICES_HEADER_TITLES.ID, flex: 1 },
	{ field: SERVICES_FIELDS_NAME.PARTNER_ID, headerName: SERVICES_HEADER_TITLES.PARTNER_ID, flex: 1, sortable: false },
	{ field: SERVICES_FIELDS_NAME.NAME, headerName: SERVICES_HEADER_TITLES.NAME, flex: 1 },
	{ field: SERVICES_FIELDS_NAME.STATUS, headerName: SERVICES_HEADER_TITLES.STATUS, flex: 1 },
	{ field: SERVICES_FIELDS_NAME.CREATED_AT, headerName: SERVICES_HEADER_TITLES.CREATED_AT, flex: 1, sortable: false },
];

export const getCellClassName = (params: any) => {
	if (params.field === SERVICES_FIELDS_NAME.STATUS && params.value !== null) {
		return params.value === 'Не активний' ? 'negative' : 'positive';
	}
		return '';

};
