export enum PAYMENT_STATUS {
	PAID = 'PAID',
	REJECTED = 'REJECTED',
	PENDING = 'PENDING',
	CONFIRMED = 'CONFIRMED',
	NEEDS_CONFIRMATION = 'NEEDS_CONFIRMATION',
	RETURNED = 'RETURNED',
	EXPIRED = 'EXPIRED',
}

export enum AUTHORIZED_STATUS {
	IS_UNAUTHORIZED = 'IS_UNAUTHORIZED',
	IS_AUTHORIZED = 'IS_AUTHORIZED',
}
