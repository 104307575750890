import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import {
	DataGrid,
	GridCellParams,
	GridPaginationModel,
	GridSortDirection,
	GridSortModel,
	useGridApiRef
} from '@mui/x-data-grid';
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ROUTES } from 'routes';
import {
	clearServicesData,
	getBillServicesSelector,
	getServices,
	getServicesIsLoadingSelector,
} from 'store/bill-services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IQuery } from 'types';
import { PAGINATION_LIMIT, SERVICE_STATUS_TITLE } from 'utils/constants';
import { DATE_FORMAT, SERVICE_STATUS, SERVICES_FIELDS_NAME, SORT_DIR } from 'utils/enums';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { formatDateView } from 'utils/helpers/date';
import { getTableSetting } from "utils/helpers/table-setting";
import { usePermissions } from 'utils/hooks/use-permissions';
import { COLUMNS_CONFIG, getCellClassName } from './config';
import s from './index.module.scss';

export const ServicesPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const services = useAppSelector(getBillServicesSelector);
	const isLoading = useAppSelector(getServicesIsLoadingSelector);
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const canI = usePermissions();
	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const requestBody = useMemo(
		() => ({
			pagination: {
				offset: (query.page || 0) * (query.pageSize || 0),
				limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			},
			sorting: {
				field: query.field || SERVICES_FIELDS_NAME.NAME,
				direction: query.sort || SORT_DIR.ASC,
			},
			search: `${query.searchQuery || ''}`,
		}),
		[query]
	);

	useEffect(() => {
		dispatch(getServices(requestBody));
	}, [requestBody, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearServicesData());
		};
	}, [dispatch]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.services, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.services));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const modifiedServicesData = useMemo(
		() =>
			services?.results.map((service) => ({
				...service,
				payeeId: service.payeeId ?? '',
				status: service.status === SERVICE_STATUS.ACTIVE ? SERVICE_STATUS_TITLE.ACTIVE : SERVICE_STATUS_TITLE.DISABLED,
				createdAt: formatDateView(service.createdAt, DATE_FORMAT.DATE_FULL),
			})),
		[services]
	);

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleClickEdit = (params: GridCellParams) => {
		navigate(`/${ROUTES.services}/${params.id}`);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	return (
		<Box
			className={s.tableWrapper}
			sx={{
				'& .negative': {
					color: 'text.secondary',
				},
				'& .positive': {
					color: 'success.main',
				},
			}}
		>
			<DataGrid
				disableRowSelectionOnClick
				onColumnResize={handleColumnResize}
				apiRef={apiRef}
				disableColumnFilter
				rows={modifiedServicesData || []}
				columns={[
					...COLUMNS_CONFIG,
					{
						field: 'edit',
						headerName: ' ',
						sortable: false,
						align: 'center',
						width: 60,
						renderCell: (params) => (
							<IconButton onClick={() => handleClickEdit(params)} color="primary" aria-label="Edit">
								<Edit />
							</IconButton>
						),
					},
				]}
				getCellClassName={(params: GridCellParams) => getCellClassName(params)}
				rowCount={services?.total || 0}
				loading={isLoading}
				initialState={{
					pagination: {
						paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
					},
					sorting: {
						sortModel: [{ field: query.field || SERVICES_FIELDS_NAME.ID, sort: query.sort as GridSortDirection }],
					},
				}}
				slotProps={{
					pagination: {
						labelRowsPerPage: 'Показувати :',
						page: query.page || 0,
						rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
					},
				}}
				paginationMode="server"
				sortingMode="server"
				onSortModelChange={handleSortField}
				onPaginationModelChange={handleChangePage}
				className={s.table}
				pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
			/>
		</Box>
	);
};
