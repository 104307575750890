import { RootState } from 'store';
import { ISingleReceiptsDetails, ISingleReceiptsResponse } from 'types';

export const getSingleReceiptsListSelector = (store: RootState): ISingleReceiptsResponse | null =>
	store.singleReceipts.singleReceiptsList || null;

export const getSingleReceiptDetailsSelector = (store: RootState): ISingleReceiptsDetails | null =>
	store.singleReceipts.singleReceiptsDetails || null;

export const getSingleReceiptsIsLoadingSelector = (store: RootState): boolean => store.singleReceipts.isLoading;
