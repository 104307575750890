import { API } from 'api';
import { AxiosResponse } from 'axios';
import {
	IPasswordRecoveryResetRequest,
	ISetPasswordRequest,
	IUserLoginRequest,
	IUserLoginResponse,
	IUserLogoutRequest,
	IUserLogoutResponse,
	PasswordRecoveryResponse,
} from 'types';
import { API_URL } from 'utils/enums';

class UserAuthService {
	static login = async (reqBody: IUserLoginRequest): Promise<AxiosResponse<IUserLoginResponse>> =>
		API.post(API_URL.USER_AUTH_LOGIN, reqBody);

	static logout = async (reqBody: IUserLogoutRequest): Promise<AxiosResponse<IUserLogoutResponse>> =>
		API.post(API_URL.USER_AUTH_LOGOUT, reqBody);

	static passwordRecovery = async (email: string): Promise<AxiosResponse<PasswordRecoveryResponse>> =>
		API.post(API_URL.USER_PASSWORD_RECOVERY, { email, clientCodeName: 'hostpay' });

	static recoveryReset = async (reqBody: IPasswordRecoveryResetRequest): Promise<AxiosResponse> =>
		API.post(API_URL.USER_PASSWORD_RECOVERY_RESET, reqBody);

	static setPassword = async (reqBody: ISetPasswordRequest): Promise<AxiosResponse> =>
		API.post(API_URL.USER_SET_PASSWORD, reqBody);
}

export { UserAuthService };
