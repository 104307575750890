// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__BRUmg {
  border-right: 1px solid var(--palette-primary-light);
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.styles_wrapper__BRUmg .styles_logo__9gUW0 {
  padding: 32px 30px 40px 20px;
}
.styles_wrapper__BRUmg .styles_navbar__bo1Vb {
  flex-grow: 1;
}
.styles_wrapper__BRUmg .styles_version__uh85B {
  display: block;
  font-size: 14px;
  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding: 12px 24px;
  background-color: #fff;
  width: 100%;
  color: #4d7c79;
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/Sidebar/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,oDAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AACD;AACC;EACC,4BAAA;AACF;AAEC;EACC,YAAA;AAAF;AAGC;EACC,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;AADF","sourcesContent":[".wrapper {\n\tborder-right: 1px solid var(--palette-primary-light);\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100vh;\n\n\t.logo {\n\t\tpadding: 32px 30px 40px 20px;\n\t}\n\n\t.navbar {\n\t\tflex-grow: 1;\n\t}\n\n\t.version {\n\t\tdisplay: block;\n\t\tfont-size: 14px;\n\t\tposition: sticky;\n\t\tbottom: 0;\n\t\tmargin-top: auto;\n\t\tpadding: 12px 24px;\n\t\tbackground-color: #fff;\n\t\twidth: 100%;\n\t\tcolor: #4d7c79;\n\t\topacity: 0.8;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__BRUmg`,
	"logo": `styles_logo__9gUW0`,
	"navbar": `styles_navbar__bo1Vb`,
	"version": `styles_version__uh85B`
};
export default ___CSS_LOADER_EXPORT___;
