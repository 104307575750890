import { API } from 'api';
import { AxiosResponse } from 'axios';
import { ISingleReceiptsDetails, ISingleReceiptsRequest, ISingleReceiptsResponse } from 'types';
import { API_URL } from 'utils/enums';

class SingleReceiptsService {
	static getSingleReceiptsList = async (
		reqBody: ISingleReceiptsRequest
	): Promise<AxiosResponse<ISingleReceiptsResponse>> => API.post(API_URL.SINGLE_RECEIPTS_LIST_GET, reqBody);

	static getSingleReceiptById = async (id: number): Promise<AxiosResponse<ISingleReceiptsDetails>> =>
		API.post(API_URL.SINGLE_RECEIPT_DETAILS_GET, { id: +id });
}

export { SingleReceiptsService };
