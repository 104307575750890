export enum PDF_SIZE {
	SIZE_12 = 3,
  SIZE_13 = 4,
  SIZE_14 = 5,
  SIZE_16 = 6,
  SIZE_24 = 7,
  SIZE_32 = 9,
  SIZE_36 = 11,
  SIZE_48 = 15,
};
