import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "@mui/material";
import {Box} from "@mui/system";
import {ReactComponent as RefreshIcon} from 'assets/icons/refresh.svg';
import {ReactComponent as TrashService} from 'assets/icons/trash.svg';
import {ROUTES} from "routes";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {deleteRight, getRightDetails, getRightDetailsSelector, updateRightDetails} from "store/right";
import {IDetailsRightResponse, IUpdateRightRequest,} from "types/roles-and-rights.interface";
import {DetailsContent} from "components/modules/DetailsContent";
import Loading from "components/modules/Loading";
import {DATE_FORMAT, RIGHT_DETAILS_KEYS} from "utils/enums";
import {DETAILS_TITLES, RIGHT_LOCKED_FIELDS} from "./config";
import styles from './index.module.scss';
import {formatDateView} from "utils/helpers/date";

export const RightDetailsPage = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [updatedRightDetailsData, setUpdatedRightDetailsData] = useState<IDetailsRightResponse | null>(null);

    const rightDetails = useAppSelector(getRightDetailsSelector);

    useEffect(() => {
        if (id) {
            dispatch(getRightDetails(id));
        }
    }, [id]);

    useEffect(() => {
        if (rightDetails) {
            setUpdatedRightDetailsData({
                ...rightDetails,
                createdAt: formatDateView(rightDetails.createdAt, DATE_FORMAT.DATE_FULL),
                updatedAt: formatDateView(rightDetails.updatedAt, DATE_FORMAT.DATE_FULL)
            })
        }
    }, [rightDetails]);

    const handleUpdateRightData = (data: IUpdateRightRequest) => {
        if (updatedRightDetailsData)
            setUpdatedRightDetailsData({ ...updatedRightDetailsData, ...data })
    };

    const handleSubmitUpdateRightData = () => {
        if (updatedRightDetailsData)
            dispatch(updateRightDetails(updatedRightDetailsData))
    };

    const handleDeleteRight = () => {
        if (!id) return;
        dispatch(deleteRight(id))
        navigate(`/${ROUTES.rights}`);
    };

    if (!updatedRightDetailsData) return <Loading />;

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.content}>
                <Box className={styles.content}>
                    <DetailsContent<RIGHT_DETAILS_KEYS, IDetailsRightResponse>
                        detailsTitles={DETAILS_TITLES}
                        title="Особисті дані"
                        detailsData={updatedRightDetailsData}
                        setDetailsData={setUpdatedRightDetailsData}
                        onSetUpdatedData={handleUpdateRightData}
                        lockedFields={RIGHT_LOCKED_FIELDS}
                    />
                </Box>
                <Box className={styles.actions}>
                    <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={handleSubmitUpdateRightData}
                        disabled={!Object.values(updatedRightDetailsData).length}
                    >
                        Оновити дані
                    </Button>
                    <Button variant="outlined" color="secondary" startIcon={<TrashService />} onClick={handleDeleteRight}>
                        Видалити обліковий запис
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
