import { FC, useContext, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import SocketContext, {
	AuditProcessesStatusEnum,
	IAuditGeneralProcessStatus,
} from 'socket/DataSettingsSocket/context';
import { auditStatusesReset, initRedis, pushNotification } from 'store/data-settings';
import { useAppDispatch } from 'store/hooks';
import { ProgressCircle } from 'components/elements';
import ConfirmationModal from 'components/modules/ConfirmationModal';
import Loading from 'components/modules/Loading';
import { InstructionComponent } from './DataSettings/DataParseInstruction';
import { SettingsForm } from './DataSettings/DataSettingForm';
import styles from './index.module.scss';

export const DataSettingsPage: FC = () => {
	const [isModalInstructionOpen, setIsModalInstructionOpen] = useState(false);
	const [isInfoBarOpen, setIsInfoBarOpen] = useState(true);
	const { isConnected, processesStatus } = useContext(SocketContext);

	const progressCircleIsVisible = (param?: IAuditGeneralProcessStatus): boolean => {
		if (!param){
			return false;
		}
		const isNotEmpty =
			param.status !== AuditProcessesStatusEnum.READY ||
			param.counter.processedCount !== 0 ||
			param.counter.totalCount !== 0;
		return isNotEmpty;
	};

	const dispatch = useAppDispatch();

	const handleOpenInstruction = () => {
		setIsModalInstructionOpen(true);
	};

	const handleInfoClose = () => {
		setIsModalInstructionOpen(false);
	};

	const handleInitRedis = () => {
		dispatch(initRedis());
	};

	const handlePushNotification = () => {
		dispatch(pushNotification());
	};

	const handleAuditResetStatus  = () => {
		dispatch(auditStatusesReset());
	};

	if (!isConnected) return <Loading />;

	return (
		<Box className={styles.wrapper}>
			<Box className={styles.content}>
				<ConfirmationModal open={isInfoBarOpen} title="Увага!!!">
					<Typography variant="body1" mb={2}>
						Перед тим як продовжити, будь ласка, зверніть увагу, що процес, який ви збираєтеся запустити на цій
						сторінці, несе значну відповідальність і повинен здійснюватися з особливою уважністю та дотриманням всіх
						вимог та інструкцій . Цей процес передбачає виконання критичних операцій, які впливають на функціональність
						нашої системи та цілісність даних. Тому надзвичайно важливо, щоб ви дотримувалися встановлених вказівок та
						процедур дуже уважно.
					</Typography>
					<Typography variant="body2" mb={2}>
						Це повідомлення наголошує на важливості уважного виконання процесу, навіть у тестовому режимі, для
						забезпечення правильності його виконання.
					</Typography>
					<Button
						style={{ marginRight: '10px', borderRadius: '40px' }}
						variant="rounded"
						onClick={() => setIsInfoBarOpen(false)}
					>
						Зрозуміло
					</Button>
				</ConfirmationModal>
					<Box>
						{
							processesStatus?.adminApiDirectUploadParsingFiles &&
							progressCircleIsVisible(processesStatus?.adminApiDirectUploadParsingFiles) &&
							<Box className={styles.circle}>
								<ProgressCircle
									currentValue={processesStatus.adminApiDirectUploadParsingFiles.counter.processedCount}
									totalValue={processesStatus.adminApiDirectUploadParsingFiles.counter.totalCount}
									status={processesStatus.adminApiDirectUploadParsingFiles.status}
								/>
								<Box>
									<div>Парсиг файлів</div>
									<div>
										<div>status:  {processesStatus.adminApiDirectUploadParsingFiles.status}</div>
										<div>availableStatus:  {processesStatus.adminApiDirectUploadParsingFiles.availableStatus}</div>
										<div>
											<span> {processesStatus.adminApiDirectUploadParsingFiles.counter.processedCount} /</span>
											<span> {processesStatus.adminApiDirectUploadParsingFiles.counter.totalCount}</span>
										</div>
									</div>

										<div>
											<div> ---------- </div>
											<div>kvbo</div>
											<div>status:  {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kvbo.status}</div>
											<div>availableStatus:  {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kvbo.availableStatus}</div>
											<div>
												<span> {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kvbo.counter.processedCount} /</span>
												<span> {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kvbo.counter.totalCount}</span>
											</div>
										</div>

										<div>
											<div> ---------- </div>
											<div>hcs</div>
											<div>status:  {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.hcs.status}</div>
											<div>availableStatus:  {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.hcs.availableStatus}</div>
											<div>
												<span> {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.hcs.counter.processedCount} /</span>
												<span> {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.hcs.counter.totalCount}</span>
											</div>
										</div>

										<div>
											<div> ---------- </div>
											<div>kts</div>
											<div>status:  {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kts.status}</div>
											<div>availableStatus:  {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kts.availableStatus}</div>
											<div>
												<span> {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kts.counter.processedCount} /</span>
												<span> {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kts.counter.totalCount}</span>
											</div>
										</div>

										<div>
											<div> ---------- </div>
											<div>kvd</div>
											<div>status:  {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kvd.status}</div>
											<div>availableStatus:  {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kvd.availableStatus}</div>
											<div>
												<span> {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kvd.counter.processedCount} /</span>
												<span> {processesStatus.adminApiDirectUploadParsingFiles.uploadFiles.kvd.counter.totalCount}</span>
											</div>
										</div>

								</Box>
							</Box>
						}
						{
							processesStatus?.etlMappingDataFromFiles &&
							progressCircleIsVisible(processesStatus?.etlMappingDataFromFiles) &&
							<Box className={styles.circle}>
								<ProgressCircle
									currentValue={processesStatus.etlMappingDataFromFiles.counter.processedCount}
									totalValue={processesStatus.etlMappingDataFromFiles.counter.totalCount}
                  					status={processesStatus.etlMappingDataFromFiles.status}
                				/>
								<Box>Мапінг отриманих данних</Box>
							</Box>
						}
						{
							processesStatus?.etlMapAndSendToBill &&
							progressCircleIsVisible(processesStatus?.etlMapAndSendToBill) &&
							<Box className={styles.circle}>
								<ProgressCircle
									currentValue={processesStatus.etlMapAndSendToBill.counter.processedCount}
									totalValue={processesStatus.etlMapAndSendToBill.counter.totalCount}
                  					status={processesStatus.etlMapAndSendToBill.status}
								/>
								<Box>Відправка данних на білл</Box>
							</Box>
						}
						{
							processesStatus?.billSingleReceiptsCreate &&
							progressCircleIsVisible(processesStatus?.billSingleReceiptsCreate) &&
							<Box className={styles.circle}>
								<ProgressCircle
									currentValue={processesStatus.billSingleReceiptsCreate.counter.processedCount}
									totalValue={processesStatus.billSingleReceiptsCreate.counter.totalCount}
                  					status={processesStatus.billSingleReceiptsCreate.status}
								/>
								<Box>Білл створення SingleReceipts</Box>
							</Box>
						}
						{
							processesStatus?.billSingleReceiptsSendToReport &&
							progressCircleIsVisible(processesStatus?.billSingleReceiptsSendToReport) &&
							<Box className={styles.circle}>
								<ProgressCircle
									currentValue={processesStatus.billSingleReceiptsSendToReport.counter.processedCount}
									totalValue={processesStatus.billSingleReceiptsSendToReport.counter.totalCount}
                  					status={processesStatus.billSingleReceiptsSendToReport.status}
								/>
								<Box>Відправка ЄК на репорт</Box>
							</Box>
						}
						{
							processesStatus?.reportSingleReceiptsCreate &&
							progressCircleIsVisible(processesStatus?.reportSingleReceiptsCreate) &&
							<Box className={styles.circle}>
								<ProgressCircle
									currentValue={processesStatus.reportSingleReceiptsCreate.counter.processedCount}
									totalValue={processesStatus.reportSingleReceiptsCreate.counter.totalCount}
                  					status={processesStatus.reportSingleReceiptsCreate.status}
								/>
								<Box>Створення ЄК на Репорт</Box>
							</Box>
						}
						{
							processesStatus?.reportTypographySingleReceiptCreate &&
							progressCircleIsVisible(processesStatus?.reportTypographySingleReceiptCreate) &&
							<Box  className={styles.circle}>
								<ProgressCircle
									currentValue={processesStatus?.reportTypographySingleReceiptCreate.counter.processedCount}
									totalValue={processesStatus?.reportTypographySingleReceiptCreate.counter.totalCount}
                  					status={processesStatus.reportTypographySingleReceiptCreate.status}
                				/>
								<Box>reportTypographySingleReceiptCreate</Box>
							</Box>
						}
					</Box>
				<Divider />
				<Box className={styles.actionWrapper}>
					<Box className={styles.actionWrapperBut}>
						<Button variant="outlined" onClick={handleInitRedis}>
							Init Redis
						</Button>
						<Button variant="outlined" onClick={handlePushNotification}>
							Push notification
						</Button>
					</Box>
					<Button variant="outlined" color="error" onClick={handleAuditResetStatus}>
						Reset Status
					</Button>
				</Box>
				<Divider />
				<SettingsForm />
			</Box>
			<Box className={styles.actions}>
					<Button variant="outlined" className={styles.actions_button} onClick={handleOpenInstruction}>
						Переглянути інструкцію
					</Button>
					<Button
						form="data"
						type="submit"
						variant="outlined"
						color="primary"
						className={styles.actions_button}>
						Запустити
					</Button>
			</Box>
			{isModalInstructionOpen && (
				<ConfirmationModal open={isModalInstructionOpen} title="Інструкція керування даними">
					<InstructionComponent />
					<Box style={{ display: 'flex' }}>
						<Button style={{ borderRadius: '40px' }} variant="outlined" onClick={handleInfoClose}>
							Зрозуміло
						</Button>
					</Box>
				</ConfirmationModal>
			)}
		</Box>
	);
};
