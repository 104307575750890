export enum Rights {
    FULL_ACCESS = 'admin:full_access',

    USERS_READ = 'users:read',
    USERS_MANAGE = 'users:manage',

    ROLES_READ = 'roles:read',
    ROLES_MANAGE = 'roles:manage',

    RIGHTS_READ = 'rights:read',
    RIGHTS_MANAGE = 'rights:manage',

    CLIENTS_READ = 'clients:read',
    CLIENTS_MANAGE = 'clients:manage',

    SERVICES_READ = 'services:read',
    SERVICES_MANAGE = 'services:manage',

    PROVIDERS_READ = 'providers:read',
    PROVIDERS_MANAGE = 'providers:manage',

    CATEGORIES_READ = 'categories:read',
    CATEGORIES_MANAGE = 'categories:manage',

    APARTMENTS_READ = 'apartments:read',
    APARTMENTS_MANAGE = 'apartments:manage',

    STREETS_READ = 'streets:read',
    STREETS_MANAGE = 'streets:manage',

    STREET_TYPES_READ = 'street_types:read',
    STREET_TYPES_MANAGE = 'street_types:manage',

    SPECIFY_DATA_READ = 'specify_data:read',
    SPECIFY_DATA_MANAGE = 'specify_data:manage',

    SINGLE_RECEIPTS_READ = 'single_receipts:read',
    SINGLE_RECEIPTS_MANAGE = 'single_receipts:manage',

    OTHER_RECEIPTS_READ = 'other_receipts:read',
    OTHER_RECEIPTS_MANAGE = 'other_receipts:manage',

    REPORTS_READ = 'reports:read',
    REPORTS_MANAGE = 'reports:manage',

    DATA_SETTINGS_READ = 'data_settings:read',
    DATA_SETTINGS_MANAGE = 'data_settings:manage',

    MUNICIPAL_ENTERPRISE_KVBO = 'municipal_enterprise:kvbo',
    MUNICIPAL_ENTERPRISE_KVD = 'municipal_enterprise:kvd',
    MUNICIPAL_ENTERPRISE_KTS = 'municipal_enterprise:kts',
    MUNICIPAL_ENTERPRISE_HCS = 'municipal_enterprise:hcs',
  }
