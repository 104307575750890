import { useAppSelector } from "store/hooks";
import { getUserProfileSelector } from "store/user"
import { Rights } from "utils/enums/rights.enum";

export type CanIFn = ((permission: string) => boolean);

export const usePermissions = (): CanIFn => {
    const userProfile = useAppSelector(getUserProfileSelector);
    return (permission: string): boolean => {
        return userProfile?.rights.includes(Rights.FULL_ACCESS)
            || userProfile?.rights.includes(permission)
            || false;
    };

}
