import { IDetailsItem } from 'types';
import {
	SERVICE_DETAILS_FEE_KEYS,
	SERVICE_DETAILS_KEYS,
	SERVICE_DETAILS_REG_KEYS
} from 'utils/enums/details-pages-keys.enum';
import { Rights } from 'utils/enums/rights.enum';
import { CanIFn } from 'utils/hooks/use-permissions';

export const SERVICE_DETAILS_TITLES: IDetailsItem<SERVICE_DETAILS_KEYS>[] = [
	{
		title: 'Назва',
		key: SERVICE_DETAILS_KEYS.NAME,
		modalTitle: 'Вкажіть назву сервіса',
		managePermission: Rights.SERVICES_MANAGE,
	},
	{
		title: 'Статус',
		key: SERVICE_DETAILS_KEYS.STATUS,
		modalTitle: 'Оберіть статус сервіса',
		managePermission: Rights.SERVICES_MANAGE,
	},
];
export const SERVICE_DETAILS_REG_TITLES: IDetailsItem<SERVICE_DETAILS_REG_KEYS>[] = [
	{
		title: 'Назва',
		key: SERVICE_DETAILS_REG_KEYS.NAME,
		modalTitle: '',
	},
	{
		title: 'ZKPO',
		key: SERVICE_DETAILS_REG_KEYS.ZKPO,
		modalTitle: '',
	},
	{
		title: 'Назва Банку',
		key: SERVICE_DETAILS_REG_KEYS.BANK_NAME,
		modalTitle: '',
	},
	{
		title: 'Банк МФО',
		key: SERVICE_DETAILS_REG_KEYS.BANK_MFO,
		modalTitle: '',
	},
	{
		title: 'Акаунт',
		key: SERVICE_DETAILS_REG_KEYS.ACCOUNT,
		modalTitle: '',
	},
	{
		title: 'Опис',
		key: SERVICE_DETAILS_REG_KEYS.DESCRIPTION,
		modalTitle: '',
	},
];

export const SERVICE_DETAILS_FEE_TITLES: IDetailsItem<SERVICE_DETAILS_FEE_KEYS>[] = [
	{
		title: 'Мінімальна комісія (грн)',
		key: SERVICE_DETAILS_FEE_KEYS.FEE_MIN,
		modalTitle: 'Вкажіть мінімальну суму комісії (грн)',
		managePermission: Rights.SERVICES_MANAGE,
	},
	{
		title: 'Максимальна комісія (грн)',
		key: SERVICE_DETAILS_FEE_KEYS.FEE_MAX,
		modalTitle: 'Вкажіть максимальну суму комісії (грн)',
		managePermission: Rights.SERVICES_MANAGE,
	},
	{
		title: 'Процент комісії (%)',
		key: SERVICE_DETAILS_FEE_KEYS.FEE_PERCENT,
		modalTitle: 'Вкажіть процент комісії (%)',
		managePermission: Rights.SERVICES_MANAGE,
	},
	{
		title: 'Фіксована сума комісії (грн)',
		key: SERVICE_DETAILS_FEE_KEYS.FIXED_AMOUNT,
		modalTitle: 'Вкажіть фіксальну суму (грн)',
		managePermission: Rights.SERVICES_MANAGE,
	},
];

export const SERVICE_DETAILS_SUM_TITLES: IDetailsItem<SERVICE_DETAILS_FEE_KEYS>[]  = [
	{
		title: 'Мінімальна сума (грн)',
		key: SERVICE_DETAILS_FEE_KEYS.SUM_FROM,
		modalTitle: 'Вкажіть мінімальну суму (грн)',
		managePermission: Rights.SERVICES_MANAGE,
	},
	{
		title: 'Максимальна сума (грн)',
		key: SERVICE_DETAILS_FEE_KEYS.SUM_TO,
		modalTitle: 'Вкажіть максимальну суму (грн)',
		managePermission: Rights.SERVICES_MANAGE,
	}
]

// TODO for this function it would be nice to have some caching like useMemo, or useCallback
export const getLockedFields = (items: IDetailsItem<any>[], canI: CanIFn) => (
		items
			.filter((item) => item.managePermission && !canI(item.managePermission as Rights))
			.map((item) => item.key)
	);
