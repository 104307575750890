// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_datePicker__y8dBq button {
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/CustomDatePicker/styles.module.scss"],"names":[],"mappings":"AACC;EACC,YAAA;AAAF","sourcesContent":[".datePicker {\n\tbutton {\n\t\tpadding: 8px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePicker": `styles_datePicker__y8dBq`
};
export default ___CSS_LOADER_EXPORT___;
