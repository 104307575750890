import { createSlice } from '@reduxjs/toolkit';
import { IStreetsReducer } from 'types';
import { getStreetsTypes } from './actions';

const initialState: IStreetsReducer = {
	isLoading: true,
	data: null,
};

const streetsTypesSlice = createSlice({
	name: 'streetsTypesSlice',
	initialState,
	reducers: {
		clearStreetTypesData: (state) => {
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getStreetsTypes.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getStreetsTypes.fulfilled, (state, { payload }) => {
			state.data = payload;
			state.isLoading = false;
		});
		builder.addCase(getStreetsTypes.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { clearStreetTypesData } = streetsTypesSlice.actions;
export const streetsTypesReducer = streetsTypesSlice.reducer;
