// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardWrapper_card__Yk-br {
  background-color: var(--palette-background-default);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/CardWrapper/index.module.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":[".card {\n    background-color: var(--palette-background-default);\n    border-radius: 8px;\n    padding: 16px;\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `CardWrapper_card__Yk-br`
};
export default ___CSS_LOADER_EXPORT___;
