export const convertCoinsToUAH = (amount: string | number): number => parseFloat((+amount / 100).toFixed(2));

export const fixedAmount = (amount: number | string | null): string | null => {
	return (amount !== null && amount) ? Number(amount).toFixed(2) : null
}

export const convertAndSumCoinsToUAH = <T extends string | number>(amount: T, fee: T): number =>
	parseFloat(((+amount+(+fee)) / 100).toFixed(2));

export const getLocaleAmountString = (amount: string | number, separator = ' ', fractionDigits = 2): string =>
	(+amount)
		.toLocaleString('uk-UA', { minimumFractionDigits: fractionDigits })
		.replace(',', '.')
		.replace(' ', separator);