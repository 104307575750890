import { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import { CustomModal } from 'components/elements';
import { useModal } from 'utils/hooks';
import s from './index.module.scss';

interface CellWithModalProps {
	formattedValue: string;
	columnName: string;
	className?: string;
	modalClassName?: string;
}

export const CellWithModal: FC<PropsWithChildren<CellWithModalProps>> = 
	({ formattedValue, columnName, className, modalClassName, children }) => {
		const [isOpen, handleOpen, handleClose] = useModal();

		return (
			<>
				<Box className={classNames(s.cell, className)} onClick={handleOpen}>
					{formattedValue}
				</Box>
				<CustomModal
					wrapperClassName={classNames(s.modal, modalClassName)}
					title={columnName}
					open={isOpen}
					onClose={handleClose}
				>
					{children || <Typography fontSize="14px">{formattedValue}</Typography>}
				</CustomModal>
			</>
		);
	}
;
