import { PAYMENT_STATUS } from '../enums';

const statusMap = {
	PAID: 'Сплачено',
	REJECTED: 'Відхилено',
	PENDING: 'В очікуванні',
	CONFIRMED: 'Підтверджено',
	NEEDS_CONFIRMATION: 'Потребує підтвердження',
	RETURNED: 'Повернуто',
	EXPIRED: 'Закінчився термін',
};

export const getStatusTranslation = (status: PAYMENT_STATUS) => {
	return statusMap[status] || status;
};
