import { IDetailsItem } from 'types';
import { USER_DETAILS_KEYS } from 'utils/enums/details-pages-keys.enum';

export const DETAILS_TITLES: IDetailsItem<USER_DETAILS_KEYS>[] = [
	{
		title: 'Імʼя',
		key: USER_DETAILS_KEYS.FIRST_NAME,
		modalTitle: 'Вкажіть ваше імʼя',
	},
	{
		title: 'Прізвище',
		key: USER_DETAILS_KEYS.LAST_NAME,
		modalTitle: 'Вкажіть ваше прізвище',
	},
	{
		title: 'Роль',
		key: USER_DETAILS_KEYS.TYPE,
		modalTitle: 'Вкажіть вашу роль',
	},
	{
		title: 'Email',
		key: USER_DETAILS_KEYS.EMAIL,
		modalTitle: 'Вкажіть ваш email',
	},
	{
		title: 'Статус',
		key: USER_DETAILS_KEYS.STATUS,
		modalTitle: 'Вкажіть  ваш статус',
	},
	{
		title: 'Телефон',
		key: USER_DETAILS_KEYS.PHONE,
		modalTitle: 'Вкажіть ваш номер',
	},
	{
		title: 'Ролі',
		key: USER_DETAILS_KEYS.ROLES,
		modalTitle: 'Призначте ролі',
		isRolesOrRights: true
	},
	{
		title: 'Дата реєстрації',
        key: USER_DETAILS_KEYS.CREATED_AT,
        modalTitle: '',
		isDate: true,
	},
	{
		title: 'Дата останьої активності',
		key: USER_DETAILS_KEYS.LOGGED_AT,
		modalTitle: '',
		isDate: true,
	}
];
