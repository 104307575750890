import { FC } from 'react';
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import s from './index.module.scss';

interface CustomModalProps extends Omit<DialogProps, 'onClose'> {
	onClose: () => void;
	wrapperClassName?: string;
}

export const CustomModal: FC<CustomModalProps> = ({ onClose, title, children, className = '', wrapperClassName = '', ...props }) => {
	return (
		<Dialog className={wrapperClassName} onClose={onClose} {...props}>
			<DialogTitle className={s.title} textAlign="center">
				{title}
				<IconButton onClick={onClose} className={s.close}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box className={classNames(s.content, className)}>{children}</Box>
			</DialogContent>
		</Dialog>
	);
};
