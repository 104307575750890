import { createSlice } from '@reduxjs/toolkit';
import { IRoleReducer } from 'types';
import { getRoles } from "./actions";

const initialState: IRoleReducer = {
    roleList: null,
    isLoading: true,
};

const rolesSlice = createSlice({
    name: 'rightSlice',
    initialState,
    reducers: {
        clearRoleData: (state) => {
            state.roleList = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoles.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRoles.fulfilled, (state, { payload }) => {
            state.roleList = payload;
            state.isLoading = false;
        });
        builder.addCase(getRoles.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const rolesReducer = rolesSlice.reducer;
