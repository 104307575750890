import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IStreetsDetails, IStreetsRequest, IStreetsResponse } from 'types';
import { API_URL } from 'utils/enums';

class StreetsTypesService {
	static getStreetsTypes = async (reqBody: IStreetsRequest): Promise<AxiosResponse<IStreetsResponse>> =>
		API.post(API_URL.STREETS_TYPES_GET, reqBody);

	static updateStreetType = async (reqBody: IStreetsDetails): Promise<AxiosResponse<IStreetsResponse>> =>
		API.patch(API_URL.STREETS_TYPES_UPDATE, reqBody);
}

export { StreetsTypesService };
