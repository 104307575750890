import { IStreetsDetails } from 'types';
import { STREETS_HEADER_TITLES } from 'utils/constants/streets';
import { STREETS_FIELDS_NAME } from 'utils/enums';

export const COLUMNS_CONFIG = [
	{ field: STREETS_FIELDS_NAME.ID, headerName: STREETS_HEADER_TITLES.ID, sortable: true, flex: 1.5 },
	{ field: STREETS_FIELDS_NAME.NAME, headerName: STREETS_HEADER_TITLES.STREET, sortable: true, flex: 1 },
	{
		field: STREETS_FIELDS_NAME.NAME_STREET_TYPE,
		headerName: STREETS_HEADER_TITLES.TYPE_STREET,
		sortable: true,
		flex: 1,
		valueGetter: (_: unknown, row: IStreetsDetails) => row.streetType?.name,
	},
	{
		field: STREETS_FIELDS_NAME.NAME_CITY,
		headerName: STREETS_HEADER_TITLES.CITY,
		sortable: true,
		flex: 1,
		valueGetter: (_: unknown, row: IStreetsDetails) => row.city?.name,
	},
	{ field: STREETS_FIELDS_NAME.CREATE_AT, headerName: STREETS_HEADER_TITLES.CREATED_AT, sortable: true, flex: 1 },
	{ field: STREETS_FIELDS_NAME.UPDATE_AT, headerName: STREETS_HEADER_TITLES.UPDATED_AT, sortable: true, flex: 1 },
];
