import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { IGetRoleDetailsRequest, IGetRoleDetailsResponse, IUpdateRoleDetailsRequest, IUpdateRoleDetailsResponse } from "types/roles-and-rights.interface";
import { getErrorResponse } from "utils/helpers";
import { RolesService } from "utils/services/roles.service";

export const getRoleDetails = createAsyncThunk(
	'roleSlice/getRole',
	async (reqBody: IGetRoleDetailsRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IGetRoleDetailsResponse> = await RolesService.getRole(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateRoleDetails = createAsyncThunk(
	'roleSlice/updateRoleDetails',
	async (reqBody: IUpdateRoleDetailsRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IUpdateRoleDetailsResponse> = await RolesService.updateRoles(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	})
