// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomCell_cell__iF1iB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.CustomCell_cell__iF1iB > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/CustomCell/index.module.scss","webpack://./src/styles/abstracts/_mixins.scss"],"names":[],"mappings":"AAEA;ECDC,aAAA;EACA,sBDCgB;ECAhB,uBAAA;EDCA,YAAA;EACG,WAAA;EACA,gBAAA;AACJ;AACC;EACC,mBAAA;EACA,uBAAA;EACA,gBAAA;EACM,WAAA;AACR","sourcesContent":["@import 'styles/abstracts/mixins';\n\n.cell {\n\t@include d-flex(column, center);\n\theight: 100%;\n    width: 100%;\n    overflow: hidden;\n\n\t& > span {\n\t\twhite-space: nowrap;\n\t\ttext-overflow: ellipsis;\n\t\toverflow: hidden;\n        width: 100%;\n\t}\n}\n","@mixin d-flex($direction: null, $justifyContent: null, $alignItems: null) {\n\tdisplay: flex;\n\tflex-direction: $direction;\n\tjustify-content: $justifyContent;\n\talign-items: $alignItems;\n}\n\n@mixin square($size, $round: false) {\n\twidth: $size !important;\n\theight: $size !important;\n\n\t@if $round {\n\t\tborder-radius: 50%;\n\t}\n}\n\n@mixin absolute-center {\n\tposition: absolute;\n\ttop: 50%;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `CustomCell_cell__iF1iB`
};
export default ___CSS_LOADER_EXPORT___;
