import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IStreetsDetails, IStreetsRequest, IStreetsResponse } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { StreetsTypesService } from 'utils/services';

export const getStreetsTypes = createAsyncThunk(
	'streetsTypesSlice/getStreetsTypes',
	async (reqBody: IStreetsRequest, { rejectWithValue }) => {
		try {
			const streetsTypesResponse: AxiosResponse<IStreetsResponse> = await StreetsTypesService.getStreetsTypes(reqBody);
			return streetsTypesResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateStreetType = createAsyncThunk(
	'streetsTypesSlice/updateStreetType',
	async (reqBody: IStreetsDetails, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IStreetsResponse> = await StreetsTypesService.updateStreetType(reqBody);

			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
