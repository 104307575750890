import { API } from 'api';
import { AxiosResponse } from 'axios';
import {
	GetUserActivityListDto,
	IUserActivities,
} from 'types';
import { API_URL } from 'utils/enums';

class UserActivityService {
	static getUserActivityList = async (reqBody: GetUserActivityListDto): Promise<AxiosResponse<IUserActivities>> =>
		API.post(API_URL.BANK_ISSUES_LIST, reqBody);
}

export { UserActivityService };
