import { FC } from 'react';
import { Box, Divider, Typography } from '@mui/material';

export const InstructionComponent: FC = () => {
	return (
		<Box mb={2} textAlign="justify">
			<Box display="flex" flexDirection="column" gap={1} mb={1}>
				<Typography variant="h5" mt={2}>
					І ЕТАП
				</Typography>
				<Typography variant="body2">
					- Запустити парсери для всіх 4х провайдерів. Після парсу в utl БД заповнюються таблиці ApartmentAccount,
					ProviderData, ProviderToApartmentAccount, Service, UndefinedProviderAccount
				</Typography>
				<Typography variant="body2">
					Після закінчення парсу перевірити таблицю UndefinedProviderAccount на наявність записів, якщо таблиця не пуста
					- заповнити таблиці на сторінках "Вулиці" та "Типи вулиць" данними відповідно до providerTypeId.
					(Відповідність типів провайдерів до їх id дивитись таблицю ProviderType){' '}
				</Typography>

				<Typography variant="body2">
					Після заповнення таблиць виконати запуск "Необроблені дані" для повторного мапу таблиці
					UndefinedProviderAccount
				</Typography>
			</Box>
			<Box display="flex" flexDirection="column" gap={1} mb={1}>
				<Typography variant="h5" mt={2}>
					ІІ ЕТАП
				</Typography>
				<Typography variant="body2">
					Тільки після того як ми обробили всі записи в таблиці UndefinedProviderAccount ми можемо почати відправляти
					данні на bill-ms. "Запустити генерацію ЄК на Біл"
				</Typography>
			</Box>
			<Box display="flex" flexDirection="column" gap={1} mb={1}>
				<Typography variant="h5" mt={2}>
					ІІІ ЕТАП
				</Typography>
				<Typography variant="body2">
					Щоб згенерувати данні для пдф необхідно після завершення генерації ЄК використати пункт "Запустити генерацію
					даних для pdf ЄК"
				</Typography>
			</Box>
			<Divider />
			<Box display="flex" flexDirection="column" gap={1} mb={1}>
				<Typography variant="h5" mt={2}>
					У деяких випадках потрібно виконати маніпуляції в Swager для вирішення проблем:
				</Typography>
				<Typography variant="h6">
					* Якщо по якісь причині адреса providerAccount яка попала в UndefinedProviderAccount не співпадає з адресами в
					таблицях "Вулиці" та "Типи вулиць" але ми точно знаємо для якого apartmentAccount він відноситься ми можемо
					промапити за допомогою ендпоінта вказавши providerAccountId та apartmentAccountId{' '}
					<a
						href="https://admin.kube-dev.hostpay.tech/api#/UtilityUndefinedProviderData/UtilityUndefinedProviderDataController_joinUpaToApartmentAccount"
						target="blank"
					>
						за цим посиланням
					</a>
				</Typography>
				<Typography variant="h6">
					* Якщо до обробки всіх UndefinedProviderAccount данні на bill-ms вже відправлялись в поточному місяці, щоб
					запобігти дублюванню даних в bill db, потрібно очистити таблиці serviceProviderData та singleReceipt, після
					чого повторно виконати "Запустити генерацію ЄК на Біл"
				</Typography>
			</Box>
		</Box>
	);
};
