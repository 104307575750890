import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IDataSettingsRequest } from 'types';
import { API_URL, STORAGE_KEY } from 'utils/enums';
import { StorageService } from './storage.service';

class DataSettingsService {
	static initRedis = async (): Promise<AxiosResponse<string>> => {
		return API.post(
			API_URL.INIT_REDIS,
			{},
			{
				params: {
					sessionId: StorageService.get(STORAGE_KEY.SESSION_ID),
				},
			}
		);
	};

	static etlFilesParsing = async (fieldName: string, reqBody: IDataSettingsRequest): Promise<AxiosResponse<string>> => {
		return API.post(`${API_URL.ETL_FILES_PARSING}/${fieldName}`, reqBody, {
			params: {
				sessionId: StorageService.get(STORAGE_KEY.SESSION_ID),
			},
			headers: {
				'Content-Type': 'multipart/form-data',
				redirect: 'follow',
			},
		});
	};

	static undefinedProviderDataParsing = async (): Promise<AxiosResponse<string>> => {
		return API.post(API_URL.UNDEFINED_PROVIDER_DATA);
	};

	static generateDataOnBill = async (): Promise<AxiosResponse<string>> =>
		API.post(API_URL.GENERATE_SINGLE_RECEIPT_ON_BILL, {
			deviceFP: '6037e65eefbdasdfadsfasdf3',
		});

	static dataGenerateSingleReceipt = async (): Promise<AxiosResponse<string>> =>
		API.post(API_URL.GENERATE_REPORT_FOR_PDF, {
			deviceFP: '6037e65eefbdasdfadsfasdf3',
			returnSession: 'YES',
		});

	static pushNotification = async (): Promise<AxiosResponse<string>> =>
		API.post(API_URL.PUSH_NOTIFICATION_ALL, {
			deviceFP: '6037e65eefbdasdfadsfasdf3',
		});

	static getSingleReceiptStatus = async (): Promise<AxiosResponse<boolean>> => API.post(API_URL.SINGLE_RECEIPT_STATUS);

	static uploadSingleReceiptStatus = async (status: boolean): Promise<AxiosResponse> => {
		return API.post(API_URL.UPLOAD_SINGLE_RECEIPT_STATUS, { status });
	};

	static stopETLFilesParsing = async (): Promise<AxiosResponse<string>> => {
		return API.post(API_URL.STOP_ETL_FILES_PARSING);
	};

	static stopETLUndefinedDataParsing = async (): Promise<AxiosResponse<string>> => {
		return API.post(API_URL.STOP_UNDEFINED_PROVIDER_DATA_PARSING);
	};

	static stopGenerateSingleReceiptOnBill = async (): Promise<AxiosResponse<string>> => {
		return API.post(API_URL.STOP_GENERATE_SINGLE_RECEIPT_ON_BILL);
	};

	static stopGeneratePDFSingleReceipt = async (): Promise<AxiosResponse<string>> => {
		return API.post(API_URL.STOP_GENERATE_REPORT_FOR_PDF);
	};

	static auditStatusesReset = async (): Promise<AxiosResponse<any>> => {
    return API.post(API_URL.AUDIT_STATUSES_RESET);
  };

}

export { DataSettingsService };
