import { createSlice } from '@reduxjs/toolkit';
import { ICategoriesReducer } from 'types';
import { getCategories, updateCategoriesSN } from './actions';

const initialState: ICategoriesReducer = {
	isLoading: true,
	data: null,
	updateRow: null,
};

const categoriesSlice = createSlice({
	name: 'categoriesSlice',
	initialState,
	reducers: {
		clearCategoriesData: (state) => {
			state.data = null;
			state.updateRow = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCategories.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getCategories.fulfilled, (state, { payload }) => {
			state.data = payload;
			state.isLoading = false;
		});
		builder.addCase(getCategories.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(updateCategoriesSN.pending, (state) => {
      state.isLoading = true;
    });
		builder.addCase(updateCategoriesSN.fulfilled, (state, { payload }) => {
			state.updateRow === payload ? state.updateRow = null : state.updateRow = payload
      state.isLoading = false;
    });
		builder.addCase(updateCategoriesSN.rejected, (state) => {
      state.isLoading = false;
    });
	},
});

export const { clearCategoriesData } = categoriesSlice.actions;
export const categoriesReducer = categoriesSlice.reducer;
