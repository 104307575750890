import { RootState } from 'store';
import { IApartments, IGetUserDetailsResponse, IUserCard, IUsersResponse } from 'types';

export const getUsersListSelector = (store: RootState): IUsersResponse | null => store.users.allUsers || null;

export const getUserDetailsSelector = (store: RootState): IGetUserDetailsResponse | null =>
	store.users.userDetails || null;
export const getUserDetailsApartmentSelector = (store: RootState): IApartments[] | null =>
	store.users.userDetailsApartment || null;

export const getUserCardListSelector = (store: RootState): IUserCard[] => store.users.userCardList;

export const getUsersIsLoadingSelector = (store: RootState): boolean => store.users.isLoading;
