export enum CHECK_RESPONSE {
	YES = 'YES',
	NO = 'NO',
}

export enum SORT_DIR {
	ASC = 'asc',
	DSC = 'desc',
}

export enum INPUT_TYPE {
	AUTOCOMPLETE,
	TEXT,
}

export enum FILE_FORMAT {
	CSV = '.csv',
	DBF = '.dbf',
	TXT = '.txt',
}

export enum PAYMENT_STATUS_FILTER {
	PAID = 'PAID',
	UNPAID = 'UNPAID',
	ALL = 'ALL',
}

export enum LOCALE {
	UK = 'UK',
}
