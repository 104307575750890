import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DataGrid, GridPaginationModel, GridSortDirection, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { ROUTES } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearStreetsData, getStreets, getStreetsIsLoadingSelector, getStreetsSelector } from 'store/streets';
import { IQuery, IStreetsDetails } from 'types';
import { PAGINATION_LIMIT } from 'utils/constants';
import { DATE_FORMAT, SORT_DIR, STREETS_FIELDS_NAME } from 'utils/enums';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { formatDateView } from 'utils/helpers/date';
import { getTableSetting } from 'utils/helpers/table-setting';
import { usePermissions } from 'utils/hooks/use-permissions';
import { COLUMNS_CONFIG } from './config';

import s from './index.module.scss';
import { Rights } from 'utils/enums/rights.enum';
import { UpdateStreetAnchor } from 'components/modules/UpdateStreetAnchor';
import { clearStreetTypesData, getStreetsTypes } from 'store/streets-types';

export const StreetsPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const checkRights = usePermissions();
	const streets = useAppSelector(getStreetsSelector);
	const isLoading = useAppSelector(getStreetsIsLoadingSelector);
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const requestBody = useMemo(
		() => ({
			offset: (query.page || 0) * (query.pageSize || 0),
			limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			orderBy: [
				{
					field: query.field || STREETS_FIELDS_NAME.NAME,
					direction: query.sort || SORT_DIR.ASC,
				},
			],
			searchBy: {
				name: query.searchQuery,
			},
			deviceFP: '6037e65eefbdasdfadsfasdf3',
		}),
		[query]
	);

	useEffect(() => {
		dispatch(getStreets(requestBody));
	}, [requestBody, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearStreetsData());
			dispatch(clearStreetTypesData());
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			getStreetsTypes({
				offset: 0,
				limit: PAGINATION_LIMIT.BIG,
				deviceFP: '6037e65eefbdasdfadsfasdf3',
			})
		);
	}, [dispatch]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.streets, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.streets));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	const modifiedStreets = useMemo(
		() =>
			streets?.results.map((street) => ({
				...street,
				createdAt: formatDateView(street.createdAt, DATE_FORMAT.DATE_FULL),
				updatedAt: formatDateView(street.updatedAt, DATE_FORMAT.DATE_FULL),
			})),
		[streets]
	);

	const streetColumns: GridColDef<IStreetsDetails>[] = useMemo(() => {
		return checkRights(Rights.STREETS_MANAGE)
			? [
					...COLUMNS_CONFIG,
					{
						field: 'edit',
						headerName: ' ',
						sortable: false,
						align: 'center',
						width: 60,
						renderCell: (params) => <UpdateStreetAnchor streetRow={params.row} />,
					},
			  ]
			: COLUMNS_CONFIG;
	}, [checkRights]);

	return (
		<Box className={s.tableWrapper}>
			<DataGrid
				onColumnResize={handleColumnResize}
				apiRef={apiRef}
				disableColumnFilter
				disableRowSelectionOnClick
				rows={modifiedStreets || []}
				columns={streetColumns}
				rowCount={streets?.total || 0}
				loading={isLoading}
				initialState={{
					pagination: {
						paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
					},
					sorting: {
						sortModel: [{ field: query.field || STREETS_FIELDS_NAME.CREATE_AT, sort: query.sort as GridSortDirection }],
					},
				}}
				slotProps={{
					pagination: {
						labelRowsPerPage: 'Показувати :',
						page: query.page || 0,
						rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
					},
				}}
				paginationMode="server"
				sortingMode="server"
				onSortModelChange={handleSortField}
				onPaginationModelChange={handleChangePage}
				className={s.table}
				pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
			/>
		</Box>
	);
};
