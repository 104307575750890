import { SORT_DIR } from "../enums";
import { PAGINATION_LIMIT } from "./pagination";

export const APARTMENT_REQUEST = {
	offset: 0,
	limit: PAGINATION_LIMIT.BIG,
	order: {
		direction: SORT_DIR.DSC,
		field: 'createdAt',
	},
};

export const CARD_REQUEST = {
		userId: '',
		clientId: 1,
		cardType: 'OWN',
}
